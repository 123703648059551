import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title';

import './Announcements.css';

//20240430

function Announcements({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddAnnouncement />, modaltype: 'small', modaltitle: 'Add Announcement' }));
    }

    const editButton = (announcement) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditAnnouncement announcement={announcement} />, modaltype: 'small', modaltitle: 'Edit Announcement' }));
    }

    const removeButton = (announcement) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveAnnouncement announcement={announcement} />, modaltype: 'small', modaltitle: 'Remove Announcement' }));
    }

    const viewButton = (announcement) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewAnnouncement announcement={announcement} />, modaltype: 'small', modaltitle: 'View Announcement' }));
    }

    function AddAnnouncement() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [text, setText] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const addAnnouncement = (ts, text) => {
            console.log('Trying to add announcement');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Announcements',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!text) {
                setResult('No text');
                return
            }

            const trimmedText = text.trim();

            const isAnnouncementDuplicate = project.announcements.some(announcement => announcement.text === trimmedText);
            if (isAnnouncementDuplicate) {
                setResult('Announcement with the same text exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                text: trimmedText
            }

            axios.post(configData.CONTROLLERURL + configData.ADDANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ANNOUNCEMENT',
                            announcement: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className="modal_mody">
                <div className="modal_printable">
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Description"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAnnouncement(timestamp, text)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleCloseModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAnnouncement({ announcement }) {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [text, setText] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(announcement.id)
            setText(announcement.text);
            setSelecteddate(new Date(announcement.ts * 1000));
        }, [announcement]);

        const updateAnnouncement = (id, ts, text) => {
            console.log('Trying to update announcement');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Announcements',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!text) {
                setResult('No text');
                return
            }

            const trimmedText = text.trim();

            const isAnnouncementDuplicate = project.announcements.some(announcement => announcement.text === trimmedText && announcement.id !== id);
            if (isAnnouncementDuplicate) {
                setResult('Announcement with the same text exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                text: trimmedText
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ANNOUNCEMENT',
                            announcement: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className="modal_printable">
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateAnnouncement(id, timestamp, text)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(announcement)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAnnouncement({ announcement }) {
        const [id, setId] = useState(announcement.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeAnnouncement = (id) => {
            console.log('Trying to remove announcement');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Announcements',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ANNOUNCEMENT',
                            announcementid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeAnnouncement(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewAnnouncement = ({ announcement }) => {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [text, setText] = useState('');

        const printRef = useRef();

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(announcement.id)
            setText(announcement.text);
            setSelecteddate(new Date(announcement.ts * 1000));
        }, [announcement]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(timestamp).format('D MMMM YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_description'>{text}</div>
                        </div>
                    </div>
                </div>
                <div className="modal_buttons">
                    <div className="modal_buttoncontainer">
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className="modal_buttoncontainer">
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='announcements'>
            <Title text='Announcements' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Announcement</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Description</th></tr></thead>
                <tbody>
                    {
                        project.announcements.map((announcement, index) => (
                            <tr key={'announcement' + index}>
                                <td><div className='table_button' onClick={() => editButton(announcement)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(announcement)}>View</div></td>
                                <td><div>{moment.unix(announcement.ts).format('D/MMM/YYYY')}</div></td>
                                <td><div>{announcement.text}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Announcements;