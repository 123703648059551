import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { isRTL } from './Utils/textparse';
import Select from './Components/select.js';
import Title from './Title';
import { jsPDF } from "jspdf";
import { registerArabicFont } from "./Utils/registerarabicfont.js";

export function AddSupplierInspection({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [selecteddate, setSelecteddate] = useState(new Date());
    const [timestamp, setTimstamp] = useState(0);

    const [suppliers, setSuppliers] = useState([]);
    const [supplierid, setSupplierid] = useState('');

    const [assets, setAssets] = useState([]);
    const [assetid, setAssetid] = useState('');

    const [refno, setRefno] = useState('');
    const [description, setDescription] = useState('');

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
        setTimstamp(unixTimestamp);
    }, [selecteddate]);

    useEffect(() => {
        setSuppliers(project.suppliers);
        setAssets(project.assets);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addInspection = (timestamp, supplierid, assetid, refno, description) => {
        console.log('Trying to add inspection');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Inspections',
            'add',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        if (!supplierid || !refno || !description) {
            setResult('Missing info')
            return
        }

        setLoading(true);

        const trimmedrefno = refno.trim();
        const trimmeddescription = description.trim();

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            ts: timestamp,
            supplierid: supplierid,
            assetid: assetid,
            refno: trimmedrefno,
            description: trimmeddescription
        }

        axios.post(configData.CONTROLLERURL + configData.ADDSUPPLIERINSPECTION, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Add inspection data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    data.id = res.data.id;
                    dispatch({
                        type: 'ADD_INSPECTION',
                        inspection: data
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='modal_body'>
            <div className='modal_printable'>
                <div className='modal_rowsection'>
                    <div className='modal_label'>Date</div>
                </div>
                <div className='modal_rowsection'>
                    <DatePicker
                        className='modal_datepicker'
                        selected={selecteddate}
                        onChange={(date) => setSelecteddate(date)}
                    />
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Supplier</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={suppliers} itemid={supplierid} setItemid={setSupplierid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Asset</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={assets} itemid={assetid} setItemid={setAssetid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Ref No</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Ref No"
                            value={refno}
                            onChange={(e) => setRefno(e.target.value)}
                        />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Description</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <textarea
                            className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                            type="text"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className='modal_actions'>
                {result && <div className='modal_result'>{result}</div>}
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => addInspection(timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function EditSupplierInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();
    const [id, setId] = useState('');

    const [selecteddate, setSelecteddate] = useState(new Date());
    const [timestamp, setTimstamp] = useState(0);

    const [suppliers, setSuppliers] = useState([]);
    const [supplierid, setSupplierid] = useState('');

    const [assets, setAssets] = useState([]);
    const [assetid, setAssetid] = useState('');

    const [refno, setRefno] = useState('');
    const [description, setDescription] = useState(inspection.description);

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setId(inspection.id)
        setSelecteddate(new Date(inspection.ts * 1000));
        setSuppliers(project.suppliers);
        setSupplierid(inspection.supplierid);
        setAssets(project.assets);
        setAssetid(inspection.assetid)
        setRefno(inspection.refno);
        setDescription(inspection.description);
    }, []);

    useEffect(() => {
        const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
        setTimstamp(unixTimestamp);
    }, [selecteddate]);

    const removeSupplierButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveSupplierInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'small', modaltitle: 'Remove Supplier Inspection' }));
    }

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const updateInspection = (id, timestamp, supplierid, assetid, refno, description) => {
        console.log('Trying to update supplier inspection');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Inspections',
            'update',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        if (!supplierid || !refno || !description) {
            setResult('Missing info')
            return
        }

        setLoading(true);

        const trimmedrefno = refno.trim();
        const trimmeddescription = description.trim();

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: id,
            ts: timestamp,
            supplierid: supplierid,
            assetid: assetid,
            refno: trimmedrefno,
            description: trimmeddescription
        }

        axios.post(configData.CONTROLLERURL + configData.UPDATESUPPLIERINSPECTION, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Update supplier inspection data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'UPDATE_INSPECTION',
                        inspection: data
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='modal_body'>
            <div className='modal_printable'>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Date</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <DatePicker
                            className='modal_datepicker'
                            selected={selecteddate}
                            onChange={(date) => setSelecteddate(date)}
                        />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Suppliers</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={suppliers} itemid={supplierid} setItemid={setSupplierid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Asset</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={assets} itemid={assetid} setItemid={setAssetid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Ref No</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Ref No"
                            value={refno}
                            onChange={(e) => setRefno(e.target.value)}
                        />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Description</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <textarea
                            className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                            type="text"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className='modal_actions'>
                {result && <div className='modal_result'>{result}</div>}
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => updateInspection(id, timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => removeSupplierButton(inspection)}>Remove</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function RemoveSupplierInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();
    const [id, setId] = useState('');

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setId(inspection.id)
    }, [inspection]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const removeInspection = (id) => {
        console.log('Trying to remove inspection');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Inspections',
            'remove',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: id
        }

        axios.post(configData.CONTROLLERURL + configData.REMOVESUPPLIERINSPECTION, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Remove inspection data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'REMOVE_INSPECTION',
                        inspectionid: id
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='modal_body'>
            <div className='modal_actions'>
                <div className='modal_result'>{result && result}</div>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => removeInspection(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ViewSupplierInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();
    const [id, setId] = useState('');
    const [ts, setTs] = useState('');
    const [supplierid, setSupplierid] = useState('');
    const [refno, setRefno] = useState('');
    const [description, setDescription] = useState(inspection.description);

    const printRef = useRef();

    useEffect(() => {
        setId(inspection.id);
        setTs(inspection.ts);
        setSupplierid(inspection.supplierid);
        setRefno(inspection.refno);
        setDescription(inspection.description);
    }, [inspection]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    return (
        <div className='modal_body'>
            <div className='modal_printable' ref={printRef}>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Project Name</div>
                    </div>
                    <div className='modal_rowsection'>{project.name}</div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Date</div>
                    </div>
                    <div className='modal_rowsection'>{moment.unix(inspection.ts).format('D MMMM YYYY')}</div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>ID</div>
                    </div>
                    <div className='modal_rowsection'>{id}</div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Supplier</div>
                    </div>
                    <div className='modal_rowsection'>{project.suppliers.find(supplier => supplier.id == supplierid)?.name}</div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Reference Number</div>
                    </div>
                    <div className='modal_rowsection'>{refno}</div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Description</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_description'>{description}</div>
                    </div>
                </div>
            </div>
            <div className='modal_actions'>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ViewSupplierInspections({ state, setState, inspections }) {
    const [{ project }, dispatch] = useStateValue();
    const [supplierid, setSupplierid] = useState('');
    const [assetid, setAssetid] = useState('');
    const [filteredInspections, setFilteredInspections] = useState([]);

    const printRef = useRef();

    useEffect(() => {
        const updatedFilteredInspections = supplierid === 0 && assetid === 0
            ? inspections
            : inspections.filter((inspection) => {
                return (supplierid === '' || inspection.supplierid === supplierid) &&
                    (assetid === '' || inspection.assetid === assetid);
            });

        updatedFilteredInspections.sort((a, b) => b.ts - a.ts);

        setFilteredInspections(updatedFilteredInspections);
    }, [supplierid, assetid]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const saveInspectionsPDF = async () => {
        const content = printRef.current;

        const currentDate = moment().format('DD_MM_YYYY');

        const options = {
            margin: 10,
            filename: 'inspections_' + project.name + '_' + currentDate + '.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html3pdf().from(content).set(options).save();
    };

    return (
        <div className='modal_body'>
            <div className='modal_printable' ref={printRef}>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Supplier</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={project.suppliers} itemid={supplierid} setItemid={setSupplierid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Asset</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={project.assets} itemid={assetid} setItemid={setAssetid} />
                    </div>
                </div>
                <div className='modal_row'>
                    <table className='modal_table'>
                        <thead><tr><th>Date</th><th>Ref</th><th>Supplier</th><th>Asset</th><th>Description</th></tr></thead>
                        <tbody>
                            {
                                filteredInspections.map((inspection, index) => {
                                    if (inspection.type == 'supplier') {
                                        const date = moment.unix(inspection.ts).format('D/MMM/YYYY');
                                        const refno = inspection.refno;
                                        const supplierName = project.suppliers.find(supplier => supplier.id == inspection.supplierid)?.name || 'Supplier not found';
                                        const assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;
                                        const description = inspection.description;

                                        return (
                                            <tr key={'inspection' + index}>
                                                <td><div>{date}</div></td>
                                                <td><div>{refno}</div></td>
                                                <td><div>{supplierName}</div></td>
                                                <td><div>{assetName}</div></td>
                                                <td><div className='inspections_description'>{description}</div></td>
                                            </tr>
                                        )
                                    }
                                    if (inspection.type == 'local') {
                                        const date = moment.unix(inspection.ts).format('D/MMM/YYYY');
                                        const id = inspection.id;
                                        const supplierName = 'Local';
                                        const assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;
                                        const description = inspection.description;

                                        return (
                                            <tr key={'inspection' + index}>
                                                <td><div>{date}</div></td>
                                                <td><div>{id}</div></td>
                                                <td><div>{supplierName}</div></td>
                                                <td><div>{assetName}</div></td>
                                                <td><div className='inspections_description'>{description}</div></td>
                                            </tr>
                                        )
                                    }

                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='modal_actions'>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={saveInspectionsPDF}>Save as PDF</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}