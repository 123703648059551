import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { isRTL } from './Utils/textparse';
import Select from './Components/select.js';
import Title from './Title';
import { jsPDF } from "jspdf";
import { registerArabicFont } from "./Utils/registerarabicfont.js";

import { AddLocalInspection, EditLocalInspection, ViewLocalInspection } from './InspectionsLocal.js';
import { AddVisitInspection, } from './InspectionsVisit.js';
import { AddSupplierInspection, EditSupplierInspection, ViewSupplierInspection, ViewSupplierInspections } from './InspectionsSupplier.js';

import './Inspections.css';

function Inspections({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredinspections, setFilteredinspections] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const sortedAndFilteredInspections = [...project.inspections]
            .filter(inspection =>
                !search || (inspection.description && inspection.description.toLowerCase().includes(search.toLowerCase()))
            )
            .sort((a, b) => b.ts - a.ts);

        setFilteredinspections(sortedAndFilteredInspections);
    }, [project, search]);

    const addInspectionsTypeButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <InspectionsType />, modaltype: 'small', modaltitle: 'Add Inspection' }));
    }

    const addLocalButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddLocalInspection state={state} setState={setState} />, modaltype: 'large', modaltitle: 'Add Local Inspection' }));
    }

    const addSupplierButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddSupplierInspection state={state} setState={setState} />, modaltype: 'large', modaltitle: 'Add Supplier Inspection' }));
    }

    const editLocalButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditLocalInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'large', modaltitle: 'Edit Local Inspection' }));
    }

    const editSupplierButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditSupplierInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'large', modaltitle: 'Edit Supplier Inspection' }));
    }

    const viewLocalButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewLocalInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'large', modaltitle: 'View Local Inspection' }));
    }

    const viewSupplierButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewSupplierInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'large', modaltitle: 'View Supplier Inspection' }));
    }

    const viewSupplierInspectionsButton = (insp) => {
        console.log(insp)
        let filteredInspections = insp.filter(ins => ins.type == 'supplier')
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewSupplierInspections state={state} setState={setState} inspections={filteredInspections} />, modaltype: 'large', modaltitle: 'View Inspections' }));
    }

    useEffect(() => {
        if (project.assets.length == 0) {
            console.log('Trying to get assets');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get assets data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_ASSETS',
                        assets: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Assets found in state')
        }
    }, []);

    useEffect(() => {
        if (project.structure.length == 0) {
            console.log('Trying to get structure');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETSTRUCTURE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get structure data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_STRUCTURE',
                        structure: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Structure found in state')
        }
    }, []);


    function InspectionsType() {
        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={addLocalButton}>Local Inspection</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={addLocalButton}>Visit Inspection</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={addSupplierButton}>Suppliers Inspection</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='inspections'>
            <Title text='Inspections' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addInspectionsTypeButton}>Add Inspection</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewSupplierInspectionsButton(project.inspections)}>View Supplier Inspections</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Asset</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredinspections.map((inspection, index) => {
                            if (inspection.type == 'local')
                                return (
                                    <tr key={'inspection' + index}>
                                        <td><div className='table_button' onClick={() => editLocalButton(inspection)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewLocalButton(inspection)}>View</div></td>
                                        <td><div>{moment.unix(inspection.ts).format('D/MMM/YYYY')}</div></td>
                                        <td><div>{inspection.type}</div></td>
                                        <td><div>{project.assets.find(asset => asset.id == inspection.assetid)?.name}</div></td>
                                        <td><div className='inspections_description'>{inspection.description}</div></td>
                                    </tr>
                                )
                            if (inspection.type == 'supplier')
                                return (
                                    <tr key={'inspection' + index}>
                                        <td><div className='table_button' onClick={() => editSupplierButton(inspection)}>Edit</div></td>
                                        <td><div className='table_button' onClick={() => viewSupplierButton(inspection)}>View</div></td>
                                        <td><div>{moment.unix(inspection.ts).format('D/MMM/YYYY')}</div></td>
                                        <td><div>{inspection.type}</div></td>
                                        <td><div>{project.assets.find(asset => asset.id == inspection.assetid)?.name}</div></td>
                                        <td><div className='inspections_description'>{inspection.description}</div></td>
                                    </tr>
                                )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Inspections;