import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { jsPDF } from "jspdf";
import { registerArabicFont } from "./Utils/registerarabicfont.js";

import { inspectionsremovalprotection, readingsremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';
import { assetTypes, iotTypes } from './Utils/asset.js';
import { ElementPreviewAssetsAsset } from './Utils/structure.js';
import Select from './Components/select.js';

import Title from './Title';

import './Assets.css';

function Assets({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredassets, setFilteredassets] = useState([]);
    const [search, setSearch] = useState('');

    const [structure, setStructure] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => { setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null })) }

    useEffect(() => {
        const filtered = project.assets.filter((asset) =>
            asset.name.toLowerCase().includes(search.toLowerCase()) ||
            asset.id.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredassets(filtered);
    }, [project, search]);

    useEffect(() => {
        if (project.assets.length == 0) {
            console.log('Trying to get assets');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get assets data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_ASSETS',
                        assets: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Assets found in state')
        }
    }, []);

    useEffect(() => {
        if (project.structure.length == 0) {
            console.log('Trying to get structure');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETSTRUCTURE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get structure data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setStructure(res.data)
                    dispatch({
                        type: 'ADD_STRUCTURE',
                        structure: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Structure found in state')
            setStructure(project.structure)
        }
    }, []);

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddAsset />, modaltype: 'large', modaltitle: 'Add Asset' }));
    }

    const editButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditAsset asset={asset} />, modaltype: 'large', modaltitle: 'Edit Asset' }));
    }

    const removeButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveAsset asset={asset} />, modaltype: 'small', modaltitle: 'Remove Asset' }));
    }

    const viewButton = (asset) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewAsset asset={asset} />, modaltype: 'large', modaltitle: 'View Asset' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const fireextinguishersButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <FireExtinguishers />, modaltype: 'large', modaltitle: 'Fire Extinguishers' }));
    }

    const pumpsButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Pumps />, modaltype: 'large', modaltitle: 'Pumps' }));
    }

    const accesscontrolsButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AccessControls />, modaltype: 'large', modaltitle: 'Access Controls' }));
    }

    const tanksButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Tanks />, modaltype: 'large', modaltitle: 'Tanks' }));
    }

    const metersButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Meters />, modaltype: 'large', modaltitle: 'Meters' }));
    }

    const IOTButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <IOT />, modaltype: 'large', modaltitle: 'IOT' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.assets || project.assets.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.assets, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const requiredAttributes = ["id", "type", "name", "px", "py", "pz", "elementid"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.assets.some(asset => asset.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Assets',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                assets: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_ASSETS',
                            assets: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            })

            setResult('Import successful');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderProperties = (properties, propertyvalues, setPropertyvalues) => {
        const handlePropertyChange = (id, value) => {
            setPropertyvalues(prevValues => ({
                ...prevValues,
                [id]: value,
            }));
        };
        const handleDateChange = (id, value) => {
            const unixTimestamp = moment(value).unix();
            setPropertyvalues(prevValues => ({
                ...prevValues,
                [id]: unixTimestamp,
            }));
        };

        return properties.map(property => {
            let inputField;

            if (property.type === 'clientselect') {
                inputField = (
                    <Select
                        data={project.clients}
                        itemid={propertyvalues[property.id]}
                        setItemid={(clientId) => handlePropertyChange(property.id, clientId)}
                    />
                );
            }

            if (property.type === 'select') {
                inputField = (
                    <select
                        value={propertyvalues[property.id] || ''}
                        onChange={e => handlePropertyChange(property.id, e.target.value)}
                        className="modal_select"
                    >
                        <option value="">Select {property.name}</option>
                        {property.options.map(opt => (
                            <option key={opt.id} value={opt.id}>{opt.name}</option>
                        ))}
                    </select>
                );
            }

            if (property.type === 'date') {
                inputField = (
                    <input
                        type="date"
                        value={propertyvalues[property.id] ? moment.unix(propertyvalues[property.id]).format('YYYY-MM-DD') : ''}
                        onChange={e => handleDateChange(property.id, e.target.value)}
                        className="modal_input"
                    />
                );
            }

            if (property.type === 'checkbox') {
                inputField = (
                    <input
                        type="checkbox"
                        checked={!!propertyvalues[property.id]}
                        onChange={e => handlePropertyChange(property.id, e.target.checked)}
                        className="modal_input"
                    />
                );
            }

            if (property.type === 'text') {
                inputField = (
                    <input
                        type="text"
                        value={propertyvalues[property.id] || ''}
                        onChange={e => handlePropertyChange(property.id, e.target.value)}
                        className="modal_input"
                    />
                );
            }

            return (
                <div key={property.id}>
                    <div className="modal_row">
                        <div key={property.id} className="modal_rowsection">
                            <div className="modal_label">{property.name}</div>
                        </div>
                    </div>
                    <div className="modal_row">
                        <div key={property.id} className="modal_rowsection">
                            {inputField}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const renderIOT = (iotTypes, iotconfig, setIotconfig) => {
        const handleIotConfigChange = (key, value) => {
            setIotconfig(prevConfig => ({
                ...prevConfig,
                [key]: value,
            }));
        };

        return iotTypes.map(iot => {
            let inputFields = [];

            inputFields = iot.options.map(option => {
                let inputField;

                if (option.type === "text") {
                    inputField = (
                        <div key={option.id} className="modal_rowsection">
                            <label className="modal_label">{option.name}</label>
                            <input
                                type="text"
                                value={iotconfig[option.id] || ''}
                                onChange={e => handleIotConfigChange(option.id, e.target.value)}
                                className="modal_input"
                            />
                        </div>
                    );
                }

                return inputField;
            });

            return (
                <div key={iot.id}>
                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <label className="modal_label">{iot.name}</label>
                        </div>
                    </div>
                    <div className="modal_row">
                        {inputFields}
                    </div>
                </div>
            );
        });
    };

    function AddAsset() {
        const [types, setTypes] = useState([]);
        const [type, setType] = useState('');
        const [options, setOptions] = useState([]);
        const [option, setOption] = useState('');
        const [propertyvalues, setPropertyvalues] = useState([]);
        const [iotenabled, setIotenabled] = useState(false);
        const [iotconfig, setIotconfig] = useState([]);

        const [name, setName] = useState('');
        const [px, setPx] = useState(0);
        const [py, setPy] = useState(0);
        const [pz, setPz] = useState(0);
        const [rotation, setRotation] = useState(0);
        const [elementid, setElementid] = useState('');

        const [element, setElement] = useState({});
        const [relatableassets, setRelatableassets] = useState([]);

        const [history, setHistory] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const selectedType = assetTypes.find(t => t.id === type);
        const selectedOption = selectedType?.options.find(o => o.id === option);
        const properties = selectedOption?.properties || [];

        useEffect(() => {
            if (assetTypes && assetTypes.length > 0) {
                setTypes(assetTypes);
                setType(assetTypes[0].id);
            }
        }, [assetTypes]);

        useEffect(() => {
            const selectedType = types.find(t => t.id === type);
            setOptions(selectedType?.options || []);
            setOption(selectedType?.options?.[0]?.id);
        }, [type, types]);

        useEffect(() => {
            if (elementid) {
                const relatedAssets = project.assets.filter(asset => asset.elementid == elementid);
                setRelatableassets(relatedAssets)

                const foundElement = structure.find(el => el.id === elementid);
                if (foundElement) {
                    setElement(foundElement);
                }
            }
        }, [structure, elementid]);

        const addAsset = () => {
            console.log('Trying to add asset');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Assets',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!type || !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                option: option,
                properties: propertyvalues,
                iotenabled: iotenabled,
                iotconfig: iotconfig,
                name: trimmedName,
                px: px,
                py: py,
                pz: pz,
                rotation: rotation,
                elementid: elementid,
                history: history
            }

            axios.post(configData.CONTROLLERURL + configData.ADDASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ASSET',
                            asset: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Type</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={types} itemid={type} setItemid={setType} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Option</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={options} itemid={option} setItemid={setOption} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {renderProperties(properties, propertyvalues, setPropertyvalues)}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>IOT (Internet of Things)</div>
                        </div>
                        <div className='modal_rowsection'>
                            <select
                                className="modal_select"
                                value={iotenabled}
                                onChange={(e) => setIotenabled(e.target.value)}
                            >
                                <option value={false}>Disabled</option>
                                <option value={true}>Enabled</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {iotenabled && renderIOT(iotTypes, iotconfig, setIotconfig)}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>

                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Position</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position X"
                                value={px}
                                onChange={(e) => setPx(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Y"
                                value={py}
                                onChange={(e) => setPy(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Z"
                                value={pz}
                                onChange={(e) => setPz(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rotation</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Rotation"
                                value={rotation}
                                onChange={(e) => setRotation(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Element</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={structure} itemid={elementid} setItemid={setElementid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='assets_editassetcanvas'>
                                <ElementPreviewAssetsAsset
                                    element={element}
                                    assets={relatableassets}
                                    asset={{ type: type, option: option, name: name, px: px, py: py, pz: pz, rotation: rotation }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>History</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                value={history}
                                onChange={e => setHistory(e.target.value)}
                                className="modal_textarea"
                                rows="4"
                            />
                        </div>
                    </div>
                </div>
                <div className="modal_actions">
                    {result && <div className='modal_result'>{result}</div>}
                    <div className="modal_buttons">
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addAsset()} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAsset({ asset }) {
        const [id, setId] = useState('');

        const [types, setTypes] = useState([]);
        const [type, setType] = useState('');
        const [options, setOptions] = useState([]);
        const [option, setOption] = useState('');
        const [propertyvalues, setPropertyvalues] = useState([]);
        const [iotenabled, setIotenabled] = useState(false);
        const [iotconfig, setIotconfig] = useState([]);

        const [name, setName] = useState('');
        const [px, setPx] = useState(0);
        const [py, setPy] = useState(0);
        const [pz, setPz] = useState(0);
        const [rotation, setRotation] = useState(0);

        const [elementid, setElementid] = useState('');
        const [element, setElement] = useState({});
        const [relatableassets, setRelatableassets] = useState([]);

        const [history, setHistory] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const selectedType = assetTypes.find(t => t.id === type);
        const selectedOption = selectedType?.options.find(o => o.id === option);
        const properties = selectedOption?.properties || [];

        useEffect(() => {
            if (assetTypes && assetTypes.length > 0) {
                setTypes(assetTypes);
            }
        }, [assetTypes]);

        useEffect(() => {
            const selectedType = types.find(t => t.id === type);
            setOptions(selectedType?.options || []);
        }, [type, types]);

        useEffect(() => {
            setId(asset.id)
            setType(asset.type)
            setOption(asset.option)
            setName(asset.name)
            setPropertyvalues(asset.properties)
            setPx(asset.px)
            setPy(asset.py)
            setPz(asset.pz)
            setRotation(asset.rotation)
            setElementid(asset.elementid)
            setHistory(asset.history)
        }, [asset]);

        useEffect(() => {
            if (elementid) {
                const relatedAssets = project.assets.filter(asset => asset.elementid == elementid && asset.id !== id);
                setRelatableassets(relatedAssets)

                const foundElement = structure.find(el => el.id === elementid);
                if (foundElement) {
                    setElement(foundElement);
                }
            }
        }, [structure, elementid]);

        const updateAsset = () => {
            console.log('Trying to update asset');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Assets',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!type, !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                option: option,
                properties: propertyvalues,
                iotenabled: iotenabled,
                iotconfig: iotconfig,
                name: trimmedName,
                px: px,
                py: py,
                pz: pz,
                rotation: rotation,
                elementid: elementid,
                history: history
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ASSET',
                            asset: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={types} itemid={type} setItemid={setType} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={options} itemid={option} setItemid={setOption} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {renderProperties(properties, propertyvalues, setPropertyvalues)}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>IOT (Internet of Things)</div>
                        </div>
                        <div className='modal_rowsection'>
                            <select
                                className="modal_select"
                                value={iotenabled}
                                onChange={(e) => setIotenabled(e.target.value)}
                            >
                                <option value={false}>Disabled</option>
                                <option value={true}>Enabled</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {iotenabled && renderIOT(iotTypes, iotconfig, setIotconfig)}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Position</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position X"
                                value={px}
                                onChange={(e) => setPx(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Y"
                                value={py}
                                onChange={(e) => setPy(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Z"
                                value={pz}
                                onChange={(e) => setPz(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rotation</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Rotation"
                                value={rotation}
                                onChange={(e) => setRotation(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Element</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={structure} itemid={elementid} setItemid={setElementid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='assets_editassetcanvas'>
                                <ElementPreviewAssetsAsset
                                    element={element}
                                    assets={relatableassets}
                                    asset={{ type: type, option: option, name: name, px: px, py: py, pz: pz, rotation: rotation }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>History</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                value={history}
                                onChange={e => setHistory(e.target.value)}
                                className="modal_textarea"
                                rows="4"
                            />
                        </div>
                    </div>
                </div>
                <div className="modal_actions">
                    {result && <div className='modal_result'>{result}</div>}
                    <div className="modal_buttons">
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateAsset()} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(asset)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAsset({ asset }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(asset.id)
        }, [asset]);

        const removeAsset = (id) => {
            console.log('Trying to remove asset');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Assets',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const inspectionData = inspectionsremovalprotection(project.inspections, id, 'assets');
            if (inspectionData.code) {
                setResult(inspectionData.message);
                return
            }

            const readingsData = readingsremovalprotection(project.readings, id, 'assets');
            if (readingsData.code) {
                setResult(readingsData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ASSET',
                            assetid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className="modal_actions">
                        {result && <div className='modal_result'>{result}</div>}
                        <div className="modal_buttons">
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={() => removeAsset(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            </div>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAsset({ asset }) {
        const [id, setId] = useState('');
        const [type, setType] = useState('');
        const [typelabel, setTypelabel] = useState('');
        const [option, setOption] = useState('');
        const [optionlabel, setOptionlabel] = useState('');
        const [name, setName] = useState('');
        const [px, setPx] = useState(0);
        const [py, setPy] = useState(0);
        const [pz, setPz] = useState(0);
        const [rotation, setRotation] = useState(0);
        const [elementid, setElementid] = useState('');
        const [element, setElement] = useState({});
        const [relatableassets, setRelatableassets] = useState([]);
        const [history, setHistory] = useState('');
        const [remarks, setRemarks] = useState([]);
        const [tasks, setTasks] = useState([]);

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const printRef = useRef();

        useEffect(() => {
            setId(asset.id);
            setName(asset.name);

            setType(asset.type);
            const typeLabel = assetTypes.find(ass => ass.id == asset.type)?.name || '';
            setTypelabel(typeLabel)

            setOption(asset.option)
            const optionLabel = assetTypes
                .find(ass => ass.id === asset.type)
                ?.options?.find(opt => opt.id === asset.option)?.name || '';
            setOptionlabel(optionLabel);

            setPx(asset.px)
            setPy(asset.py)
            setPz(asset.pz)
            setRotation(asset.rotation)
            setElementid(asset.elementid)
            setHistory(asset.history)
        }, [asset]);

        useEffect(() => {
            if (elementid) {
                const relatedAssets = project.assets.filter(asset => asset.elementid == elementid);
                setRelatableassets(relatedAssets)

                const foundElement = structure.find(el => el.id === elementid);
                if (foundElement) {
                    setElement(foundElement);
                }
            }
        }, [structure, elementid]);

        useEffect(() => {
            if (asset.id) {
                const filteredRemarks = [];

                project.inspections.forEach((inspection) => {
                    let date = moment.unix(inspection.ts).format('DD-MMM-YYYY')
                    if (inspection.type === 'supplier' && inspection.assetid === asset.id) {
                        filteredRemarks.push({
                            date: date,
                            ts: inspection.ts,
                            inspectionid: inspection.id,
                            remarkid: '',
                            type: 'Supplier',
                            description: inspection.description,
                            picture: null
                        });
                    }
                    if (inspection.type === 'local') {
                        const relatedRemarks = inspection.remarks.filter(remark => remark.assetid === asset.id);
                        if (relatedRemarks.length > 0) {
                            relatedRemarks.forEach(remark => {
                                filteredRemarks.push({
                                    date: date,
                                    ts: inspection.ts,
                                    inspectionid: inspection.id,
                                    remarkid: remark.id,
                                    type: 'Local',
                                    description: remark.description,
                                    picture: remark.picture
                                });
                            });
                        }
                    }
                });
                setRemarks(filteredRemarks);
            }
        }, [asset.id]);

        useEffect(() => {
            if (asset.id) {
                const filteredTasks = [];
                project.tasks.forEach((task) => {
                    let date = moment.unix(task.ts).format('DD-MMM-YYYY')
                    if (task.assetid === asset.id) {
                        filteredTasks.push({
                            date: date,
                            status: task.status,
                            name: task.name,
                        });
                    }
                });
                setTasks(filteredTasks);
            }
        }, [asset.id]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Type</div>
                        </div>
                        <div className='modal_rowsection'>{typelabel}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Option</div>
                        </div>
                        <div className='modal_rowsection'>{optionlabel}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Position</div>
                        </div>
                        <div className='modal_rowsection'>{px},{py},{pz} {rotation}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Element</div>
                        </div>
                        <div className='modal_rowsection'>{element?.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>History</div>
                        </div>
                        <div className='modal_rowsection'>{history}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Inspections</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Inspection</th>
                                        <th>Remark</th>
                                        <th>Type</th>
                                        <th>Description</th>
                                        <th>Picture</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remarks.map((remark, index) => (
                                        <tr key={'remark' + index}>
                                            <td>{remark.date}</td>
                                            <td>{remark.inspectionid}</td>
                                            <td>{remark.remarkid}</td>
                                            <td>{remark.type}</td>
                                            <td>{remark.description}</td>
                                            <td>{
                                                remark.picture &&
                                                < img
                                                    src={remark.picture}
                                                    alt="Remark Image"
                                                    style={{ width: '100px', height: 'auto' }}
                                                />
                                            }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Tasks</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tasks.map((task, index) => (
                                            <tr key={'task' + index}>
                                                <td>{task.name}</td>
                                                <td>{task.status}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='assets_editassetcanvas'>
                                <ElementPreviewAssetsAsset
                                    element={element}
                                    assets={relatableassets}
                                    asset={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AccessControls() {
        const [accesscontrols, setAccesscontrols] = useState([]);

        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        useEffect(() => {
            let foundAccesscontrols = project.assets.filter(ass => ass.type == 'accesscontrol')
            setAccesscontrols(foundAccesscontrols)
        }, [project.assets]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment().format("D MMMM YYYY")}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Option</th>
                                        <th>Name</th>
                                        <th>Element</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        accesscontrols.map((accesscontrol, index) => {
                                            let assetOption = assetTypes
                                                .find(a => a.id === accesscontrol.type)
                                                ?.options
                                                ?.find(opt => opt.id === accesscontrol.option)
                                                ?.name || accesscontrol.option;
                                            let elementName = structure.find(el => el.id == accesscontrol.elementid)?.name

                                            return (
                                                <tr key={'accesscontrol' + index}>
                                                    <td><div>{accesscontrol.id}</div></td>
                                                    <td><div>{assetOption}</div></td>
                                                    <td><div>{accesscontrol.name}</div></td>
                                                    <td><div>{elementName}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Tanks() {
        const [tanks, setTanks] = useState([]);

        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [cost, setCost] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        useEffect(() => {
            let foundTanks = project.assets.filter(ass => ass.type == 'tank')
            setTanks(foundTanks)
        }, [project.assets]);

        function Tank({ tank }) {
            const latestreading = project.readings.filter(reading => reading.assetid == tank.assetid).reduce((latest, current) => current.ts > latest.ts ? current : latest, { ts: 0 });
            let currentcapacity;
            if (latestreading) {
                currentcapacity = latestreading.amount
            }
            else {
                currentcapacity = 0;
            }
            const fullcapacity = tank.capacity;
            const percentageFilled = (currentcapacity / tank.capacity) * 100;
            const name = tank.name;
            const color = tank.color;

            return (
                <div className='tanks_tank'>
                    <div className="tanks_tanktitle">
                        {name}
                    </div>
                    <div className="tanks_tanksubtitle">
                        (Last refreshed: {moment.unix(latestreading.ts).fromNow()})
                    </div>
                    <div className="tanks_tankcapacity">
                        <div
                            className="tanks_tankcurrent"
                            style={{ height: `${percentageFilled}%`, backgroundColor: `${color}` }}
                        ></div>
                        <div className="tanks_tanktext">
                            {currentcapacity}/{fullcapacity}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment().format("D MMMM YYYY")}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {
                                tanks.map((tank, index) => {
                                    return (
                                        <Tank key={'tank' + index} tank={tank} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Option</th>
                                        <th>Name</th>
                                        <th>Capacity</th>
                                        <th>Element</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tanks.map((tank, index) => {
                                            let assetOption = assetTypes
                                                .find(a => a.id === tank.type)
                                                ?.options
                                                ?.find(opt => opt.id === tank.option)
                                                ?.name || tank.option;
                                            let elementName = structure.find(el => el.id == tank.elementid)?.name

                                            return (
                                                <tr key={'tank' + index}>
                                                    <td><div>{tank.id}</div></td>
                                                    <td><div>{assetOption}</div></td>
                                                    <td><div>{tank.name}</div></td>
                                                    <td><div>{tank?.properties?.capacity}</div></td>
                                                    <td><div>{elementName}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Pumps() {
        const [pumps, setPumps] = useState([]);

        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [cost, setCost] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        useEffect(() => {
            let foundPumps = project.assets.filter(ass => ass.type == 'pump')
            setPumps(foundPumps)
        }, [project.assets]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment().format("D MMMM YYYY")}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Option</th>
                                        <th>Name</th>
                                        <th>Brand</th>
                                        <th>Model</th>
                                        <th>Cost</th>
                                        <th>Element</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pumps.map((pump, index) => {
                                            let assetOption = assetTypes
                                                .find(a => a.id === pump.type)
                                                ?.options
                                                ?.find(opt => opt.id === pump.option)
                                                ?.name || pump.option;
                                            let elementName = structure.find(el => el.id == pump.elementid)?.name

                                            return (
                                                <tr key={'pump' + index}>
                                                    <td><div>{pump.id}</div></td>
                                                    <td><div>{assetOption}</div></td>
                                                    <td><div>{pump.name}</div></td>
                                                    <td><div>{pump?.properties?.brand}</div></td>
                                                    <td><div>{pump?.properties?.model}</div></td>
                                                    <td><div>{pump?.properties?.cost}</div></td>
                                                    <td><div>{elementName}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Meters() {
        const [meters, setMeters] = useState([]);

        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        useEffect(() => {
            let foundMeters = project.assets.filter(ass => ass.type == 'meter')
            setMeters(foundMeters)
        }, [project.assets]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment().format("D MMMM YYYY")}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Option</th>
                                        <th>Name</th>
                                        <th>Client</th>
                                        <th>Element</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        meters.map((meter, index) => {
                                            console.log(meter)
                                            let assetOption = assetTypes
                                                .find(a => a.id === meter.type)
                                                ?.options
                                                ?.find(opt => opt.id === meter.option)
                                                ?.name || meter.option;
                                            let clientName = project.clients.find(cl => cl.id == meter?.properties?.clientid)?.name
                                            let elementName = structure.find(el => el.id == meter.elementid)?.name

                                            return (
                                                <tr key={'meter' + index}>
                                                    <td><div>{meter.id}</div></td>
                                                    <td><div>{assetOption}</div></td>
                                                    <td><div>{meter.name}</div></td>
                                                    <td><div>{clientName}</div></td>
                                                    <td><div>{elementName}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function IOT() {
    }

    function FireExtinguishers() {
        const [fireextinguishers, setFireextinguishers] = useState([]);

        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [weight, setWeight] = useState('');
        const [expiry, setExpiry] = useState('');

        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);

        const [loadingpdf, setLoadingpdf] = useState(false);
        const [resultpdf, setResultpdf] = useState('');

        const printRef = useRef();

        const summaryData = {};
        const currentTime = moment().unix();
        fireextinguishers.forEach((data) => {
            const type = assetTypes
                .find(a => a.id === data.type)
                ?.options
                ?.find(opt => opt.id === data.option)
                ?.name || '';

            const weight = data?.properties?.weight || '';
            const expiry = data?.properties?.expiry || 0;
            const isExpired = expiry && expiry < currentTime ? 'Expired' : 'Active';

            const key = `${type} ${weight} - ${isExpired}`;

            summaryData[key] = (summaryData[key] || 0) + 1;
        });

        useEffect(() => {
            let fireExt = project.assets.filter(ass => ass.type == 'fireextinguisher')
            setFireextinguishers(fireExt)
        }, [project.assets]);

        const editFireExtinguisherButton = (extinguisher) => {
            console.log(extinguisher)
            setId(extinguisher.id)
            setName(extinguisher.name)
            setWeight(extinguisher?.properties?.weight || '')
            setExpiry(moment.unix(extinguisher.properties?.expiry).format('YYYY-MM-DD'));

        }

        const updateFireExtinguisherButton = (extinguisher) => {
            console.log('Trying to update asset');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Assets',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: extinguisher.type,
                option: extinguisher.option,
                properties: { weight: weight, expiry: moment(expiry, 'YYYY-MM-DD').unix() },
                name: trimmedName,
                px: extinguisher.px,
                py: extinguisher.py,
                pz: extinguisher.pz,
                rotation: extinguisher.rotation,
                elementid: extinguisher.elementid,
                history: extinguisher.history
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ASSET',
                            asset: data
                        });
                        setFireextinguishers(prevFireExt =>
                            prevFireExt.map(item =>
                                item.id === id ? { ...item, ...data } : item
                            )
                        );
                        setId('')
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const saveAsPDF = () => {
            setLoadingpdf(true);
            try {
                const projectName = project.name;

                const doc = new jsPDF();
                registerArabicFont(doc);

                const pageWidth = doc.internal.pageSize.getWidth();
                let currentY = 10;

                currentY += 10;
                doc.setFontSize(24);
                const title = 'Fire Extinguishers';
                const titleWidth = doc.getTextWidth(title);
                doc.text(title, (pageWidth - titleWidth) / 2, currentY);

                currentY += 10;
                doc.setFontSize(10);
                doc.text('Project Name: ' + projectName, 10, currentY);

                currentY += 5;
                const formattedFrom = moment().format("D MMMM YYYY");
                doc.text('Date: ' + formattedFrom, 10, currentY);

                currentY += 10;
                const tableColumn = ['Name', 'Weight', 'Expiry', 'Element'];

                const tableData = fireextinguishers
                    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
                    .sort((a, b) => a.option.localeCompare(b.option))
                    .sort((a, b) => {
                        const expiryA = a?.properties?.expiry || '';
                        const expiryB = b?.properties?.expiry || '';
                        return expiryA.localeCompare(expiryB);
                    })
                    .map((data) => {
                        let formattedExpiry = data?.properties?.expiry
                            ? moment.unix(data.properties.expiry).format('DD-MMM-YYYY')
                            : '';
                        let elementName = structure.find(el => el.id == data.elementid)?.name

                        return [
                            { content: data.name, styles: { fontStyle: 'normal' } },
                            { content: data?.properties?.weight || '', styles: { fontStyle: 'normal' } },
                            { content: formattedExpiry, styles: { fontStyle: 'normal' } },
                            { content: elementName, styles: { fontStyle: 'normal' } },
                        ];
                    });

                doc.autoTable({
                    head: [tableColumn],
                    body: tableData,
                    startY: currentY,
                    theme: 'grid',
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: [0, 0, 0],
                        fontStyle: 'bold',
                    },
                    bodyStyles: {
                        fontSize: 10,
                        font: 'notoarabic',
                    },
                    columnStyles: {
                        0: { halign: 'left' },
                        1: { halign: 'center' },
                        2: { halign: 'center' },
                        3: { halign: 'center' },
                    },
                    margin: { top: 10, left: 10, right: 10 },
                });

                const fileName = 'Fire Extinguishers ' + projectName + '.pdf';
                doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
                setResultpdf("Saved Successfully");
            } catch (error) {
                console.error('Error generating PDF:', error);
                setResultpdf("Error Saving PDF");
            } finally {
                setLoadingpdf(false);
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment().format("D MMMM YYYY")}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th>Option</th>
                                        <th>Name</th>
                                        <th>Weight</th>
                                        <th>Expiry</th>
                                        <th>Element</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fireextinguishers
                                            .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
                                            .sort((a, b) => a.option.localeCompare(b.option))
                                            .sort((a, b) => {
                                                const expiryA = a?.properties?.expiry || '';
                                                const expiryB = b?.properties?.expiry || '';
                                                return expiryA.localeCompare(expiryB);
                                            })
                                            .map((extinguisher, index) => {
                                                let assetType = assetTypes.find(a => a.id == extinguisher.type)?.name || extinguisher.type;
                                                let assetOption = assetTypes
                                                    .find(a => a.id === extinguisher.type)
                                                    ?.options
                                                    ?.find(opt => opt.id === extinguisher.option)
                                                    ?.name || extinguisher.option;
                                                let elementName = structure.find(el => el.id == extinguisher.elementid)?.name

                                                if (extinguisher.id == id) {
                                                    return (
                                                        <tr key={'extinguisher' + index}>
                                                            <td><div className='table_button' onClick={() => updateFireExtinguisherButton(extinguisher)}>Update</div></td>
                                                            <td><div>{extinguisher.id}</div></td>
                                                            <td><div>{assetType}</div></td>
                                                            <td><div>{assetOption}</div></td>
                                                            <td>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                        className="modal_input"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        value={weight}
                                                                        onChange={(e) => setWeight(e.target.value)}
                                                                        className="modal_input"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <input
                                                                        type="date"
                                                                        value={expiry}
                                                                        onChange={e => setExpiry(e.target.value)}
                                                                        className="modal_input"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td><div>{elementName}</div></td>
                                                        </tr>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <tr key={'extinguisher' + index}>
                                                            <td><div className='table_button' onClick={() => editFireExtinguisherButton(extinguisher)}>Edit</div></td>
                                                            <td><div>{extinguisher.id}</div></td>
                                                            <td><div>{assetType}</div></td>
                                                            <td><div>{assetOption}</div></td>
                                                            <td><div>{extinguisher.name}</div></td>
                                                            <td><div>{extinguisher?.properties?.weight}</div></td>
                                                            <td><div>{moment.unix(extinguisher?.properties?.expiry).format('DD-MMM-YYYY')}</div></td>
                                                            <td><div>{elementName}</div></td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {
                                Object.entries(summaryData).map(([key, count], idx) => (
                                    <div key={`summary-${idx}`}>
                                        {count} {key}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="modal_action">
                    <div className="modal_buttons">
                        <div className="modal_buttoncontainer">
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={saveAsPDF}>
                                Save as PDF
                            </button>
                        </div>
                        <div className="modal_buttoncontainer">
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='assets'>
            <Title text='Assets' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Asset</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={accesscontrolsButton}>Access Controls</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={tanksButton}>Tanks</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={metersButton}>Meters</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={fireextinguishersButton}>Fire Extinguishers</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={pumpsButton}>Pumps</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={IOTButton}>IOT</button>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Option</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Element</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredassets.map((asset, index) => (
                            <tr key={'asset' + index}>
                                <td><div className='table_button' onClick={() => editButton(asset)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(asset)}>View</div></td>
                                <td><div>{asset.id}</div></td>
                                <td><div>{assetTypes.find(a => a.id == asset.type)?.name || asset.type}</div></td>
                                <td>
                                    <div>
                                        {
                                            assetTypes
                                                .find(a => a.id === asset.type)
                                                ?.options
                                                ?.find(opt => opt.id === asset.option)
                                                ?.name || asset.option
                                        }
                                    </div>
                                </td>
                                <td><div>{asset.name}</div></td>
                                <td><div>{asset.px},{asset.py},{asset.pz} {asset.rotation}</div></td>
                                <td><div>{structure.find(el => el.id == asset.elementid)?.name}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div >
    );
}

export default Assets;