import React, { useEffect, useState } from 'react';
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import moment from 'moment';

let highlightedColor = 'yellow';

export const assetTypes = [
    {
        id: "accesscontrol",
        name: "Access Control",
        options: [
            { id: "slidinggate", name: "Sliding Gate" },
            { id: "videophone", name: "Video Phone" },
            { id: "interphone", name: "Interphone" },
            { id: "doubleswinggate", name: "Double Swing Gate" },
            { id: "rollershuttergate", name: "Roller Shutter Gate" },
            { id: "door", name: "Door" },
            { id: "vehiclebarrier", name: "Vehicle Barrier" },
        ],
    },
    {
        id: "boiler",
        name: "Boiler",
        options: [
            {
                id: "castironboiler",
                name: "Cast Iron Boiler",
                properties: [
                    { id: "clientid", name: "Client", type: "clientselect" },
                ]
            }
        ]
    },
    {
        id: "cleaning",
        name: "Cleaning",
        options: [
            { id: "floorscrubbermachine", name: "Floor Scrubber Machine" },
        ]
    },
    {
        id: "energysystem",
        name: "Energy System",
        options: [
            { id: "solarpanel", name: "Solar Panel" },
            { id: "ups", name: "UPS" },
        ],
    },
    {
        id: "firealarmsystem",
        name: "Fire Alarm System",
        options: [
            { id: "smokedetector", name: "Smoke Detector" },
            { id: "heatdetector", name: "Heat Detector" },
            { id: "firepanel", name: "Fire Panel" },
            { id: "sprinkler", name: "Fire Sprinkler" },
        ],
    },
    {
        id: "fireextinguisher",
        name: "Fire Extinguisher",
        options: [
            {
                id: "portable",
                name: "Portable Fire Extinguisher",
                properties: [
                    { id: "weight", name: "Weight", type: "text" },
                    { id: "expiry", name: "Expiry", type: "date" },
                ]
            },
            {
                id: "ceiling",
                name: "Ceiling Extinguisher",
                properties: [
                    { id: "weight", name: "Weight", type: "text" },
                    { id: "expiry", name: "Expiry", type: "date" },
                ]
            },
            {
                id: "mobile",
                name: "Mobile Extinguisher",
                properties: [
                    { id: "weight", name: "Weight", type: "text" },
                    { id: "expiry", name: "Expiry", type: "date" },
                ]
            },
            { id: "firehosereel", name: "Fire Hose Reel" },
        ]
    },
    {
        id: "filtrationsystem",
        name: "Filtration System",
        options: [
            { id: "ro", name: "Reverse Osmosis (RO)" },
            { id: "softener", name: "Water Softener" }
        ]
    },
    {
        id: "generator",
        name: "Generator",
        options: [
            {
                id: "diesel",
                name: "Diesel",
                properties: [
                    { id: "brand", name: "Brand", type: "text" },
                    { id: "model", name: "Model", type: "text" },
                    { id: "alternator", name: "Alternator", type: "text" },
                    { id: "capacity", name: "Capacity", type: "text" },
                ]
            }
        ]
    },
    {
        id: "hvac",
        name: "HVAC",
        options: [
            {
                id: "split",
                name: "Split Unit",
                properties: [
                    { id: "capacity", name: "Capacity (BTU/Tons/kW)", type: "text" }
                ]
            },
            { id: "central", name: "Central system" }
        ]
    },
    {
        id: "landscape",
        name: "Landscape",
        options: [
            { id: "tree", name: "Tree" },
            { id: "plant", name: "Plant" }
        ]
    },
    {
        id: "lift",
        name: "Lift",
        options: [
            { id: "passenger", name: "Passenger Lift" },
        ]
    },
    {
        id: "lightingsystem",
        name: "Lighting System",
        options: [
            { id: "led", name: "LED Light" },
            { id: "motionsensor", name: "Motion Sensor" },
            { id: "smart", name: "Smart Light" },
        ],
    },
    {
        id: "meter",
        name: "Meter",
        options: [
            {
                id: "electricity",
                name: "Electricity Meter",
                properties: [
                    { id: "clientid", name: "Client", type: "clientselect" },
                    { id: "share", name: "Share", type: "text" },
                    {
                        id: "type",
                        name: "Type",
                        type: "select",
                        options: [{ id: "input", name: 'Input' }, { id: "output", name: 'Output' }]
                    },
                    { id: "distributetoshares", name: "Distribute to shares", type: "checkbox" },
                ]
            },
            {
                id: "water",
                name: "Water Meter",
                properties: [
                    { id: "clientid", name: "Client", type: "clientselect" },
                    { id: "share", name: "Share", type: "text" },
                    {
                        id: "type",
                        name: "Type",
                        type: "select",
                        options: [{ id: "input", name: 'Input' }, { id: "output", name: 'Output' }]
                    },
                    { id: "distributetoshares", name: "Distribute to shares", type: "checkbox" },
                ]
            },
            { id: "gas", name: "Gas Meter" },
            { id: "flow", name: "Flow Meter" },
            { id: "pressure", name: "Pressure Meter" },
        ]
    },
    {
        id: "pump",
        name: "Pump",
        options: [
            {
                id: "pump",
                name: "Pump",
                properties: [
                    { id: "brand", name: "Brand", type: "text" },
                    { id: "model", name: "Model", type: "text" },
                    { id: "cost", name: "Cost", type: "text" },
                ]
            },
            { id: "submersible", name: "Submersible Pump" },
            {
                id: "verticalmultistage",
                name: "Vertical Multistage Pump",
                properties: [
                    { id: "brand", name: "Brand", type: "text" },
                    { id: "model", name: "Model", type: "text" },
                    { id: "cost", name: "Cost", type: "text" },
                ]
            }
        ]
    },
    {
        id: "securitysystem",
        name: "Security System",
        options: [
            {
                id: "cctvcamera",
                name: "CCTV Camera",
                properties: [
                    { id: "resolution", name: "Resolution", type: "text" },
                    { id: "ipaddress", name: "IP Address", type: "text" },
                    { id: "installationdate", name: "Installation Date", type: "date" },
                    { id: "status", name: "Operational Status", type: "select", options: ["Active", "Inactive", "Faulty"] },
                    { id: "recordingtype", name: "Recording Type", type: "select", options: ["Continuous", "Motion Detection", "Scheduled"] },
                    { id: "storagetype", name: "Storage Type", type: "select", options: ["Local", "Cloud", "Network Attached Storage (NAS)"] },
                    { id: "fieldofview", name: "Field of View (FOV)", type: "text" },
                    { id: "power", name: "Power Source", type: "select", options: ["PoE", "Battery", "Wired"] }
                ]
            },
        ],
    },
    {
        id: "tank",
        name: "Tank",
        options: [
            {
                id: "disel",
                name: "Diesel Tank",
                properties: [
                    { id: "capacity", name: "Capacity", type: "text" },
                ]
            },
            {
                id: "water",
                name: "Water Tank",
                properties: [
                    { id: "capacity", name: "Capacity", type: "text" },
                ]
            },
            {
                id: "lpg",
                name: "LPG Tank",
                properties: [
                    { id: "capacity", name: "Capacity", type: "text" },
                ]
            }
        ]
    },
];

export const iotTypes = [
    {
        id: "ble",
        name: "Bluetooth Low Energy",
        options: [
            { id: "uuid", name: "UUID", type: "text" },
            { id: "suuid", name: "SUUID", type: "text" },
            { id: "cuid", name: "CUUID", type: "text" },
        ],
    },
    {
        id: "wifi",
        name: "Wi-Fi",
        options: [
            { id: "ssid", name: "SSID", type: "text" },
            { id: "password", name: "Password", type: "password" },
            { id: "ip", name: "Static IP", type: "text" },
            { id: "mac", name: "MAC Address", type: "text" },
        ],
    },
    {
        id: "lora",
        name: "LoRa",
        options: [
            { id: "frequency", name: "Frequency", type: "number" },
            { id: "spreading_factor", name: "Spreading Factor", type: "number" },
            { id: "bandwidth", name: "Bandwidth", type: "number" },
            { id: "coding_rate", name: "Coding Rate", type: "number" },
        ],
    },
    {
        id: "zigbee",
        name: "Zigbee",
        options: [
            { id: "pan_id", name: "PAN ID", type: "text" },
            { id: "channel", name: "Channel", type: "number" },
            { id: "mac", name: "MAC Address", type: "text" },
        ],
    },
    {
        id: "nfc",
        name: "Near Field Communication (NFC)",
        options: [
            { id: "uid", name: "UID", type: "text" },
            { id: "tag_type", name: "Tag Type", type: "text" },
            { id: "payload", name: "Payload", type: "text" },
        ],
    },
    {
        id: "rfid",
        name: "Radio Frequency Identification (RFID)",
        options: [
            { id: "tag_id", name: "Tag ID", type: "text" },
            { id: "frequency", name: "Frequency", type: "number" },
        ],
    },
    {
        id: "cellular",
        name: "Cellular (2G/3G/4G/5G)",
        options: [
            { id: "apn", name: "APN", type: "text" },
            { id: "imei", name: "IMEI", type: "text" },
            { id: "sim_iccid", name: "SIM ICCID", type: "text" },
        ],
    },
    {
        id: "satellite",
        name: "Satellite Communication",
        options: [
            { id: "satellite_network", name: "Satellite Network", type: "text" },
            { id: "device_id", name: "Device ID", type: "text" },
        ],
    },
    {
        id: "espnow",
        name: "ESP-NOW",
        options: [
            { id: "peer_mac", name: "Peer MAC Address", type: "text" },
            { id: "channel", name: "Channel", type: "number" },
        ],
    },
]

export function AccessControl({ position, rotation, highlighted, option = 'slidinggate' }) {
    let geometries = [];
    let color = highlighted ? highlightedColor : 'gray';

    switch (option) {
        case 'slidinggate':
            geometries = [<boxGeometry args={[2, 1, 0.1]} key="single" />];
            break;
        case 'doubleswinggate':
            geometries = [
                <mesh key="left" position={[-0.5, 0, 0]}>
                    <boxGeometry args={[1, 1, 0.1]} />
                </mesh>,
                <mesh key="right" position={[0.5, 0, 0.05]} rotation={[0, Math.PI / 9, 0]}>
                    <boxGeometry args={[1, 1, 0.1]} />
                </mesh>
            ];
            break;
        case 'rollershuttergate':
            geometries = [
                <mesh key="roller" position={[0, 1.3, 0]} rotation={[0, 0, Math.PI / 2]}>
                    <cylinderGeometry args={[0.2, 0.2, 2, 32]} />
                </mesh>,
                <mesh key="shutter" position={[0, 0, 0]}>
                    <boxGeometry args={[2, 2.5, 0.1]} />
                </mesh>
            ];
            break;
        case 'videophone':
            geometries = [<boxGeometry args={[0.3, 0.5, 0.03]} key="videophone" />];
            break;
        case 'interphone':
            geometries = [<boxGeometry args={[0.3, 0.5, 0.03]} key="interphone" />];
            break;
        case 'door':
            geometries = [<boxGeometry args={[0.9, 2, 0.1]} key="door" />];
            break;
        case 'vehiclebarrier':
            geometries = [
                <mesh key="pole" position={[-0.5, 0.5, 0]}>
                    <boxGeometry args={[0.2, 1, 0.2]} />
                </mesh>,
                <mesh key="arm" position={[1, 0.5, 0]}>
                    <boxGeometry args={[3, 0.1, 0.1]} />
                </mesh>
            ];
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            {geometries.map((geometry, index) => (
                <mesh position={option === 'doubleslidingate' ? [(index === 0 ? -0.5 : 0.5), 0, 0] : [0, 0, 0]} key={index}>
                    {geometry}
                    <meshStandardMaterial color={color} />
                </mesh>
            ))}
        </group>
    );
}

export function Boiler({ position, highlighted }) {
    const mainColor = highlighted ? highlightedColor : 'gray';
    const pipeColor = highlighted ? highlightedColor : 'darkgray';

    const boilerGeometry = <boxGeometry args={[1.2, 0.5, 0.3]} />;
    const pipeGeometry = <cylinderGeometry args={[0.05, 0.05, 0.3, 16]} />;

    return (
        <group position={position}>
            <mesh>
                {boilerGeometry}
                <meshStandardMaterial color={mainColor} />
            </mesh>
            <mesh position={[0.6, 0, 0]}>
                {pipeGeometry}
                <meshStandardMaterial color={pipeColor} />
            </mesh>
            <mesh position={[0, 0.3, 0]}>
                {pipeGeometry}
                <meshStandardMaterial color={pipeColor} />
            </mesh>
        </group>
    );
}

export function Cleaning({ position, option, rotation, highlighted }) {
    let geometries = [];
    let color = highlighted ? highlightedColor : 'gray';

    switch (option) {
        case 'floorscrubbermachine':
            <boxGeometry args={[100, 5, 0.2]} key="bigRectangle" />
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            {geometries.map((geometry, index) => (
                <mesh key={index}>
                    {geometry}
                    <meshStandardMaterial color={color} />
                </mesh>
            ))}
        </group>
    );
}

export function EnergySystem({ position, option, rotation, highlighted }) {
    const color = highlighted ? highlightedColor : 'gray';
    let geometries = [];

    switch (option) {
        case 'solarpanel':
            geometries = [<boxGeometry args={[2, 1, 0.1]} key="single" />];
            break;
        case 'ups':
            geometries = [<boxGeometry args={[0.5, 0.5, 0.2]} key="single" />];
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            {geometries.map((geometry, index) => (
                <mesh key={index} position={[0, 0, 0]} rotation={option === 'solarpanel' ? [Math.PI / 2, 0, 0] : [0, 0, 0]}>
                    {geometry}
                    <meshStandardMaterial color={color} />
                </mesh>
            ))}
        </group>
    );
}

export function FireAlarmSystem({ position, option, rotation, highlighted }) {
    const color = highlighted ? 'red' : '#ff6347';
    let geometry;

    switch (option) {
        case 'smokedetector':
            geometry = (
                <cylinderGeometry args={[0.1, 0.1, 0.1, 16]} />
            );
            break;
        case 'heatdetector':
            geometry = (
                <cylinderGeometry args={[0.1, 0.1, 0.1, 16]} />
            );
            break;
        case 'firepanel':
            geometry = (
                <boxGeometry args={[0.3, 0.5, 0.03]} key="firepanel" />
            );
            break;
        case 'sprinkler':
            geometry = (
                <cylinderGeometry args={[0.1, 0.1, 0.1, 16]} />
            );
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} metalness={0.7} roughness={0.4} />
            </mesh>
        </group>
    );
}

export function FireExtinguisher({ position, highlighted, option = 'portable' }) {
    let color = highlighted ? highlightedColor : 'red';
    let geometries = [];

    switch (option) {
        case 'portable':
            geometries.push(<cylinderGeometry args={[0.075, 0.075, 0.5, 32]} key="portable" />);
            break;
        case 'ceiling':
            geometries.push(<cylinderGeometry args={[0.2, 0.2, 0.1, 32]} key="ceiling" />);
            break;
        case 'mobile':
            geometries.push(<cylinderGeometry args={[0.12, 0.12, 0.8, 32]} key="mobile" />);
            geometries.push(<cylinderGeometry args={[0.1, 0.1, 0.05, 32]} key="wheel1" />);
            geometries.push(<cylinderGeometry args={[0.1, 0.1, 0.05, 32]} key="wheel2" />);
            geometries.push(<boxGeometry args={[0.05, 0.3, 0.05]} key="handle" />);
            break;
        case 'firehosereel':
            geometries.push(<boxGeometry args={[2, 1, 0.3]} key="firehosereel" />);
            break;
        default:
            break;
    }

    return (
        <group position={position}>
            {geometries.map((geometry, index) => {
                let pos = [0, 0, 0];

                if (option === 'mobile') {
                    if (index === 1) pos = [-0.15, -0.4, 0];
                    if (index === 2) pos = [0.15, -0.4, 0];
                    if (index === 3) pos = [0, 0.5, 0];
                }

                return (
                    <mesh position={pos} key={index}>
                        {geometry}
                        <meshStandardMaterial color={color} />
                    </mesh>
                );
            })}
        </group>
    );
}

export function FiltrationSystem({ position, highlighted, option = 'softener' }) {
    let geometry;
    let color = highlighted ? highlightedColor : 'lightblue';

    switch (option) {
        case 'ro':
            geometry = [<boxGeometry args={[4, 3, 1]} key="ro" />];
            break;
        case 'softener':
            geometry = [<boxGeometry args={[4, 3, 1]} key="softner" />];
            break;
        default:
            break;
    }

    return (
        <group position={position}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} />
            </mesh>
        </group>
    );
}

export function Generator({ position, rotation, highlighted }) {
    let highlightColor = 'yellow';

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            <mesh>
                <boxGeometry args={[4, 2, 2]} />
                <meshStandardMaterial color={highlighted ? highlightColor : "#FF5733"} metalness={0.6} roughness={0.3} />
            </mesh>

            <mesh position={[0, 0.5, 0]}>
                <boxGeometry args={[3.8, 0.2, 2.2]} />
                <meshStandardMaterial color={highlighted ? highlightColor : "#FFD700"} metalness={0.4} roughness={0.2} />
            </mesh>

            <mesh position={[0, -1, 0]}>
                <boxGeometry args={[4.2, 0.3, 2.2]} />
                <meshStandardMaterial color={highlighted ? highlightColor : "#1E90FF"} metalness={0.5} roughness={0.4} />
            </mesh>
        </group>
    );
}

export function HVAC({ position, option = 'split' }) {
    let geometry;
    let color = 'lightgray';

    switch (option) {
        case 'split':
            geometry = <boxGeometry args={[1.5, 0.4, 0.3]} />;
            color = 'lightblue';
            break;
        case 'central':
            geometry = <boxGeometry args={[3, 1, 0.5]} />;
            color = 'gray';
            break;
        default:
            break;
    }

    return (
        <group position={position}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} />
            </mesh>
        </group>
    );
}

export function Landscape({ position, rotation = 0, option = 'tree' }) {
    let geometry;
    let color = '#FFD700';

    switch (option) {
        case 'tree':
            geometry = (
                <>
                    <mesh position={[0, 1.5, 0]}>
                        <cylinderGeometry args={[0.3, 0.3, 3, 16]} />
                        <meshStandardMaterial color="saddlebrown" roughness={0.8} metalness={0.2} />
                    </mesh>

                    <mesh position={[0, 3.5, 0]}>
                        <sphereGeometry args={[2, 16, 16]} />
                        <meshStandardMaterial color="forestgreen" roughness={0.5} metalness={0.3} />
                    </mesh>
                </>
            );
            break;
        case 'plant':
            geometry = (
                <>
                    <mesh position={[0, 1, 0]}>
                        <cylinderGeometry args={[0.1, 0.1, 2, 16]} />
                        <meshStandardMaterial color="saddlebrown" roughness={0.8} metalness={0.2} />
                    </mesh>

                    <mesh position={[0, 2, 0]}>
                        <sphereGeometry args={[0.5, 16, 16]} />
                        <meshStandardMaterial color="forestgreen" roughness={0.5} metalness={0.3} />
                    </mesh>
                    <mesh position={[0.5, 2.5, 0]}>
                        <sphereGeometry args={[0.4, 16, 16]} />
                        <meshStandardMaterial color="forestgreen" roughness={0.5} metalness={0.3} />
                    </mesh>
                    <mesh position={[-0.5, 2.5, 0]}>
                        <sphereGeometry args={[0.4, 16, 16]} />
                        <meshStandardMaterial color="forestgreen" roughness={0.5} metalness={0.3} />
                    </mesh>
                </>
            );
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            {geometry}
        </group>
    );
}


export function Lift({ position, rotation = 0, option = 'passenger' }) {
    let geometry;
    let color = '#808080';

    switch (option) {
        case 'passenger':
            geometry = (
                <>
                    <mesh position={[0, 2, 0]}>
                        <boxGeometry args={[2, 4, 2]} />
                        <meshStandardMaterial color="lightgray" roughness={0.6} metalness={0.3} />
                    </mesh>
                    <mesh position={[0, 2, 1.05]}>
                        <boxGeometry args={[2, 4, 0.1]} />
                        <meshStandardMaterial color="steelblue" roughness={0.7} metalness={0.4} />
                    </mesh>
                </>
            );
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            {geometry}
        </group>
    );
}


export function LightingSystem({ position, option, rotation, highlighted }) {
    const color = highlighted ? highlightedColor : '#f0e68c';
    let geometry;

    switch (option) {
        case 'led':
            geometry = (
                <>
                    <cylinderGeometry args={[0.25, 0.25, 0.6, 32]} />
                    <mesh position={[0, -0.4, 0]}>
                        <sphereGeometry args={[0.35, 32, 32]} />
                        <meshStandardMaterial color="lightblue" />
                    </mesh>
                </>
            );
            break;
        case 'motionsensor':
            geometry = (
                <>
                    <cylinderGeometry args={[0.15, 0.15, 0.2, 32]} position={[0, 0.2, 0]} />
                </>
            );
            break;
        case 'smart':
            geometry = (
                <>
                    <cylinderGeometry args={[0.25, 0.25, 0.6, 32]} />
                    <mesh position={[0, -0.4, 0]}>
                        <sphereGeometry args={[0.35, 32, 32]} />
                        <meshStandardMaterial color="lightblue" />
                    </mesh>
                </>
            );
            break;
        default:
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} metalness={0.7} roughness={0.4} />
            </mesh>
        </group>
    );
}

export function Meter({ position, option = 'electricity' }) {
    let geometry;
    let color = 'grey';

    switch (option) {
        case 'electricity':
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
        case 'water':
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
        case 'gas':
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
        case 'flow':
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
        case 'pressure':
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
        default:
            geometry = <boxGeometry args={[0.2, 0.1, 0.07]} />;
            break;
    }

    return (
        <group position={position}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} />
            </mesh>
        </group>
    );
}

export function Pump({ position, option = 'small' }) {
    let bodyGeometry;
    let pipeGeometry = null;
    let valveGeometry = null;
    let baseGeometry = null;
    let color = 'steelblue';
    let pipeColor = 'darkgray';
    let valveColor = 'red';

    switch (option) {
        case 'small':
            bodyGeometry = <cylinderGeometry args={[0.4, 0.4, 1, 64]} />;
            pipeGeometry = <cylinderGeometry args={[0.1, 0.1, 1.5, 32]} />;
            valveGeometry = <sphereGeometry args={[0.15, 32, 32]} />;
            baseGeometry = <boxGeometry args={[0.8, 0.1, 0.8]} />;
            break;

        case 'large':
            bodyGeometry = <cylinderGeometry args={[0.6, 0.6, 2, 64]} />;
            pipeGeometry = <cylinderGeometry args={[0.15, 0.15, 2, 32]} />;
            valveGeometry = <sphereGeometry args={[0.2, 32, 32]} />;
            baseGeometry = <boxGeometry args={[1.2, 0.1, 1.2]} />;
            break;

        case 'submersible':
            bodyGeometry = <boxGeometry args={[1, 0.6, 0.6]} />;
            pipeGeometry = <cylinderGeometry args={[0.1, 0.1, 1.5, 32]} />;
            valveGeometry = <sphereGeometry args={[0.15, 32, 32]} />;
            baseGeometry = <boxGeometry args={[1.2, 0.1, 0.8]} />;
            break;
        case 'verticalmultistage':
            bodyGeometry = <cylinderGeometry args={[0.2, 0.2, 1.5, 64]} />;
            valveGeometry = <cylinderGeometry args={[0.3, 0.3, 0.4, 64]} />;
            baseGeometry = <boxGeometry args={[0.6, 0.1, 0.6]} />;
            break;

        default:
            bodyGeometry = <cylinderGeometry args={[0.4, 0.4, 1, 64]} />;
            pipeGeometry = <cylinderGeometry args={[0.1, 0.1, 1.5, 32]} />;
            valveGeometry = <sphereGeometry args={[0.15, 32, 32]} />;
            baseGeometry = <boxGeometry args={[0.8, 0.1, 0.8]} />;
            break;
    }

    return (
        <group position={position}>
            <mesh position={[0, -0.55, 0]}>
                {baseGeometry}
                <meshStandardMaterial color="gray" metalness={0.3} roughness={0.7} />
            </mesh>

            <mesh position={[0, 0, 0]}>
                {bodyGeometry}
                <meshStandardMaterial color={color} metalness={0.5} roughness={0.5} />
            </mesh>

            {pipeGeometry && (
                <mesh position={[0.5, 0.5, 0]} rotation={[0, 0, Math.PI / 2]}>
                    {pipeGeometry}
                    <meshStandardMaterial color={pipeColor} metalness={0.5} roughness={0.5} />
                </mesh>
            )}

            {valveGeometry && (
                <mesh position={[0.1, 0.8, 0]}>
                    {valveGeometry}
                    <meshStandardMaterial color={valveColor} metalness={0.7} roughness={0.3} />
                </mesh>
            )}
        </group>
    );
}

export function SecuritySystem({ position, rotation, option, highlighted }) {
    let geometry;
    let color = highlighted ? highlightedColor : '#8a8a8a';

    switch (option) {
        case 'cctvcamera':
            geometry = (
                <>
                    <mesh position={[0, 0.2, 0]}>
                        <boxGeometry args={[0.2, 0.15, 0.2]} />
                        <meshStandardMaterial color="darkgray" />
                    </mesh>
                    <mesh position={[0, 0.2, 0.15]} rotation={[-Math.PI / 2, 0, 0]}>
                        <cylinderGeometry args={[0.05, 0.05, 0.1, 32]} />
                        <meshStandardMaterial color="black" />
                    </mesh>
                </>
            );
            break;
        default:
            geometry = <boxGeometry args={[1, 1, 1]} />;
            break;
    }

    return (
        <group position={position} rotation={[0, (rotation * Math.PI) / 180, 0]}>
            <mesh>
                {geometry}
                <meshStandardMaterial color={color} metalness={0.8} roughness={0.3} />
            </mesh>
        </group>
    );
}

export function Tank({ position, option, highlighted }) {
    let geometry;
    let color = highlighted ? highlightedColor : 'blue';

    switch (option) {
        case 'diesel':
            geometry = <boxGeometry args={[1.5, 1, 1]} />;
            break;
        case 'water':
            geometry = <boxGeometry args={[1.5, 1, 1]} />;
            break;
        case 'lpg':
            geometry = <cylinderGeometry args={[0.5, 0.5, 1, 32]} />;
            break;
        default:
            geometry = <boxGeometry args={[1, 1, 1]} />;
            break;
    }

    return (
        <group position={position}>
            <mesh rotation={option.includes('horizontal') ? [0, 0, Math.PI / 2] : [0, 0, 0]}>
                {geometry}
                <meshStandardMaterial color={color} />
            </mesh>
        </group>
    );
}

export function Asset({ asset, inspections, tasks }) {
    const [type, setType] = useState('');
    const [option, setOption] = useState('');
    const [name, setName] = useState('');
    const [px, setPx] = useState(0);
    const [py, setPy] = useState(0);
    const [pz, setPz] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [highlighted, setHighlighted] = useState(false);

    useEffect(() => {
        if (asset) {
            setType(asset.type);
            setOption(asset.option);
            setName(asset.name);
            setPx(asset.px);
            setPy(asset.py);
            setPz(asset.pz);
            setRotation(asset.rotation);
        }
    }, [asset]);

    const handleClick = () => setHighlighted(!highlighted);

    const renderAsset = () => {
        const props = { name, position: [px, py, pz], option, rotation, highlighted };
        switch (type) {
            case 'accesscontrol': return <AccessControl {...props} />;
            case 'boiler': return <Boiler {...props} />;
            case 'energysystem': return <EnergySystem {...props} />;
            case 'firealarmsystem': return <FireAlarmSystem {...props} />;
            case 'fireextinguisher': return <FireExtinguisher {...props} />;
            case 'filtrationsystem': return <FiltrationSystem {...props} />;
            case 'generator': return <Generator {...props} />;
            case 'hvac': return <HVAC {...props} />;
            case 'landscape': return <Landscape {...props} />;
            case 'lift': return <Lift {...props} />;
            case 'lightingsystem': return <LightingSystem {...props} />;
            case 'meter': return <Meter {...props} />;
            case 'pump': return <Pump {...props} />;
            case 'securitysystem': return <SecuritySystem {...props} />;
            case 'tank': return <Tank {...props} />;
            default: return null;
        }
    };

    return (
        <group onClick={handleClick}>
            {renderAsset()}
            {highlighted && <AssetDetails asset={asset} inspections={inspections} tasks={tasks} setHighlighted={setHighlighted} />}
        </group>
    );
}

const AssetDetails = ({ asset, inspections, tasks, setHighlighted }) => {
    const [remarks, setRemarks] = useState([]);
    const [relatedtasks, setRelatedtasks] = useState([]);

    useEffect(() => {
        if (inspections) {
            const filteredRemarks = [];
            inspections.forEach((inspection) => {
                let date = moment.unix(inspection.ts).format('DD-MMM-YYYY')
                if (inspection.type === 'supplier' && inspection.assetid === asset.id) {
                    filteredRemarks.push({
                        date: date,
                        ts: inspection.ts,
                        inspectionid: inspection.id,
                        remarkid: '',
                        type: 'Supplier',
                        description: inspection.description,
                        picture: null
                    });
                }
                if (inspection.type === 'local') {
                    const relatedRemarks = inspection.remarks.filter(remark => remark.assetid === asset.id);
                    if (relatedRemarks.length > 0) {
                        relatedRemarks.forEach(remark => {
                            filteredRemarks.push({
                                date: date,
                                ts: inspection.ts,
                                inspectionid: inspection.id,
                                remarkid: remark.id,
                                type: 'Local',
                                description: remark.description,
                                picture: remark.picture
                            });
                        });
                    }
                }
            });
            console.log(filteredRemarks)
            setRemarks(filteredRemarks);
        }
    }, [asset.id, inspections])

    useEffect(() => {
        if (tasks) {
            const filteredTasks = [];
            tasks.forEach((task) => {
                let date = moment.unix(task.ts).format('DD-MMM-YYYY')
                if (task.assetid === asset.id) {
                    filteredTasks.push({
                        date: date,
                        status: task.status,
                        name: task.name,
                    });
                }
            });
            setRelatedtasks(filteredTasks);
        }
    }, [tasks])

    return (
        <Html distanceFactor={50}>
            <div style={{
                position: 'fixed',
                top: '10px',
                right: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                padding: '10px',
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                zIndex: 9999,
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <button onClick={() => setHighlighted(false)}> Close</button><br />
                <strong>{asset.name}</strong><br />
                ID: {asset.id}<br />
                History: {asset.history}<br />
                Inspections:<br />
                {
                    remarks.length > 0 ? (
                        remarks.map((remark, index) => (
                            <div key={index} style={{ marginBottom: '8px' }}>
                                {remark.date} {remark.description}<br />
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p>No remarks found</p>
                    )
                }
                Tasks:<br />
                {
                    relatedtasks.length > 0 ? (
                        relatedtasks.map((task, index) => (
                            <div key={index} style={{ marginBottom: '8px' }}>
                                {task.ts} {task.name}<br />
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p>No tasks found</p>
                    )
                }
            </div>
        </Html>
    );
};

export function AssetPreview({ asset }) {
    return (
        <div className="asset-3d-view">
            <Canvas camera={{ position: [10, 10, 10], fov: 50 }}>
                <ambientLight intensity={0.5} />
                <directionalLight position={[5, 5, 5]} intensity={1} />
                <pointLight position={[10, 10, 10]} />
                <OrbitControls />

                <Asset asset={asset} />
            </Canvas>
        </div>
    );
}