import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title.js';
import { journalentryremovalprotection, transactionsremovalprotection, emsreadingsremovalprotection, emsmetersremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';

import './EmergencyNumbers.css';

function EmergencyNumbers({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredemergencynumbers, setFilteredemergencynumbers] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = project.emergencynumbers.filter((emergencynumber) =>
            emergencynumber.name.toLowerCase().includes(search.toLowerCase())
        );

        setFilteredemergencynumbers(filtered);
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddEmergencyNumber />, modaltype: 'small', modaltitle: 'Add Emergency Number' }));
    }

    const editButton = (emergencynumber) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditEmergencyNumber emergencynumber={emergencynumber} />, modaltype: 'small', modaltitle: 'Edit Emergency Number' }));
    }

    const viewButton = (emergencynumber) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewEmergencyNumber emergencynumber={emergencynumber} />, modaltype: 'small', modaltitle: 'View Emergency Number' }));
    }

    const removeButton = (emergencynumber) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveEmergencyNumber emergencynumber={emergencynumber} />, modaltype: 'small', modaltitle: 'Remove Emergency Number' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.emergencynumbers || project.emergencynumbers.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.emergencynumbers, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const requiredAttributes = ["id", "name", "numbers"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.emergencynumbers.some(emergencynumber => emergencynumber.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Emergency Numbers',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                emergencynumbers: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTEMERGENCYNUMBERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_EMERGENCYNUMBERS',
                            emergencynumbers: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewNumber({ row, index, handleNameChangeNumber, handleNumberChangeNumber, handleRemoveNumber }) {
        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Name'
                        value={row.name}
                        onChange={(e) => handleNameChangeNumber(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Number'
                        value={row.number}
                        onChange={(e) => handleNumberChangeNumber(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveNumber(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddEmergencyNumber() {
        const [name, setName] = useState('');
        const [numbers, setNumbers] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const handleAddNumber = () => {
            setNumbers([...numbers, { name: '', number: '' }]);
        };

        const handleNameChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].name = e.target.value;
            setNumbers(newNumbers);
        };

        const handleNumberChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].number = e.target.value;
            setNumbers(newNumbers);
        };

        const handleRemoveNumber = (index) => {
            const newNumbers = [...numbers];
            newNumbers.splice(index, 1);
            setNumbers(newNumbers);
        };

        const addEmergencyNumer = (name, numbers) => {
            console.log('Trying to add emergency number');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Emergency Numbers',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name || numbers.length == 0) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.emergencynumbers.some(number => number.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName,
                numbers: numbers,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDEMERGENCYNUMBER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add emergency number data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_EMERGENCYNUMBER',
                            emergencynumber: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Numbers</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        numbers.length ?
                                            numbers.map((row, index) => (
                                                <NewNumber
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleNameChangeNumber={handleNameChangeNumber}
                                                    handleNumberChangeNumber={handleNumberChangeNumber}
                                                    handleRemoveNumber={handleRemoveNumber}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={3}>No numbers</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleAddNumber}>Add Number</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addEmergencyNumer(name, numbers)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditEmergencyNumber({ emergencynumber }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [numbers, setNumbers] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (emergencynumber) {
                setId(emergencynumber.id);
                setName(emergencynumber.name);

                const numbersCopy = emergencynumber.numbers ? emergencynumber.numbers.map(number => ({ ...number })) : [];

                setNumbers(numbersCopy);
            }
        }, [emergencynumber]);

        const handleAddNumber = () => {
            setNumbers([...numbers, { name: '', number: '' }]);
        };

        const handleNameChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].name = e.target.value;
            setNumbers(newNumbers);
        };

        const handleNumberChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].number = e.target.value;
            setNumbers(newNumbers);
        };

        const handleRemoveNumber = (index) => {
            const newNumbers = [...numbers];
            newNumbers.splice(index, 1);
            setNumbers(newNumbers);
        };

        const updateEmergencynumber = (id, name, numbers) => {
            console.log('Trying to update emergency numbers');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Emergency Numbers',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.emergencynumbers.some(number => number.name === trimmedName && number.id !== id);
            if (isNameDuplicate) {
                setResult('Name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName,
                numbers: numbers,
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEEMERGENCYNUMBER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update emergency number data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_EMERGENCYNUMBER',
                            emergencynumber: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Numbers</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Number</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    numbers.length ?
                                        numbers.map((row, index) => (
                                            <NewNumber
                                                key={index}
                                                row={row}
                                                index={index}
                                                handleNameChangeNumber={handleNameChangeNumber}
                                                handleNumberChangeNumber={handleNumberChangeNumber}
                                                handleRemoveNumber={handleRemoveNumber}
                                            />
                                        ))
                                        :
                                        <tr><td colSpan={3}>No numbers</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleAddNumber}>Add Number</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateEmergencynumber(id, name, numbers)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(emergencynumber)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveEmergencyNumber({ emergencynumber }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(emergencynumber.id)
        }, [emergencynumber]);

        const removeEmergencyNumber = (id) => {
            console.log('Trying to remove emergency number');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Emergency Numbers',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEEMERGENCYNUMBER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove emergency number data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_EMERGENCYNUMBER',
                            id: emergencynumber
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_actions'>
                        {result && <div className='modal_result'>{result}</div>}
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={() => removeEmergencyNumber(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            </div>
                            <div className='modal_buttoncontainer'>
                                <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewEmergencyNumber({ emergencynumber }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [numbers, setNumbers] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setId(emergencynumber.id);
            setName(emergencynumber.name);
            setNumbers(emergencynumber.numbers);
        }, [emergencynumber]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Numbers</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    numbers.length > 0 ?
                                        numbers.map((number, index) => {
                                            return (
                                                <tr key={'number' + index}>
                                                    <td>{number.name}</td>
                                                    <td>{number.number}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr><td colSpan={2}>No number</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='emergencynumbers'>
            <Title text='Emergency Numbers' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Emergency Number</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Name</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredemergencynumbers.map((emergencynumber, index) => {
                            return (
                                <tr key={'emergencynumber' + index}>
                                    <td><div className='table_button' onClick={() => editButton(emergencynumber)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(emergencynumber)}>View</div></td>
                                    <td>
                                        <div>{emergencynumber.name}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {emergencynumber.numbers && emergencynumber.numbers.length > 0
                                                ? emergencynumber.numbers[0]?.number
                                                : ''}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default EmergencyNumbers;