import moment from 'moment';

export function getReadings(readings) {
    readings.sort((a, b) => b.ts - a.ts);
    return readings;
}

export function getReading(readings, clients, assets) {
    const result = [];

    return readings;
}

export function getConsumptionReport(readings, clients) {
    readings.sort((a, b) => a.ts - b.ts);

    const result = {
        header: ['Client'],
        rows: []
    };

    readings.map((read) => {
        const date = moment.unix(read.ts).format('D/MMM/YYYY');
        result.header.push(date);
    })

    const clientRows = [];
    clients.map((client) => {
        const clientName = clients.find((clt) => clt.id === client.id)?.name;
        const clientRow = { clientname: clientName, columns: [clientName] };

        readings.map((reading) => {
            const clientReading = reading.rows.find(row => row.clientid === client.id);
            clientRow.columns.push(clientReading ? clientReading.reading : '');
        });

        clientRows.push(clientRow);
    })

    function naturalCompare(a, b) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }
    clientRows.sort((a, b) => naturalCompare(a.clientname, b.clientname));

    result.rows = clientRows;
    return result;
}

export function getConsumptionClientReport(readings, clientid) {
    const result = [];

    readings.forEach(reading => {
        reading.rows.forEach(row => {
            if (row.clientid === clientid) {
                const timestamp = reading.ts;
                const date = moment.unix(timestamp).format('D/MMM/YYYY')
                const value = row.reading || 0;

                result.push({ ts: timestamp, date: date, reading: value });
            }
        });
    });

    return result;
}