import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import Select from './Components/select.js';
import Title from './Title';
import ReactToPrint from 'react-to-print';

import './Contracts.css';

function Contracts({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredcontracts, setFilteredcontracts] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.contracts.filter((contract) =>
            contract.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredcontracts(filtered)
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddContract />, modaltype: 'large', modaltitle: 'Add Contract' }));
    }

    const editButton = (contract) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditContract contract={contract} />, modaltype: 'large', modaltitle: 'Edit Contract' }));
    }

    const removeButton = (contract) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveContract contract={contract} />, modaltype: 'small', modaltitle: 'Remove Contract' }));
    }

    const viewButton = (contract) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewContract contract={contract} />, modaltype: 'small', modaltitle: 'View Contracts' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.contracts || project.contracts.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.contracts, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                const requiredAttributes = ["id", "from", "to", "supplierid", "name", "currencyid", "amount"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    for (const entry of data) {
                        const supplierExists = project.suppliers.some(supplier => supplier.id === entry.supplierid);
                        if (!supplierExists) {
                            setResult("Supplier with id " + entry.supplierid + " not found.");
                            return;
                        }

                        const currencyExists = project.currencies.some(currency => currency.id === entry.currencyid);
                        if (!currencyExists) {
                            setResult("Currency with id " + entry.currencyid + " not found.");
                            return;
                        }
                    }

                    const newEntries = data.filter(entry => {
                        return !project.contracts.some(contract => contract.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult("Data is valid. New entries found: " + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult("Invalid data: Missing required attributes.");
                }
            }

            if (!isJSON) {
                setResult('Invalid format');
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Contracts',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                contracts: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCONTRACTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CONTRACTS',
                            contracts: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewRow({ row, index, handleRowChange, handleRemoveRow }) {
        return (
            <tr>
                <td>
                    <DatePicker
                        className='modal_datepicker'
                        selected={row.date ? new Date(row.date * 1000) : null}
                        onChange={(date) => handleRowChange(date, 'date', index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder="Name"
                        value={row.name}
                        onChange={(e) => handleRowChange(e.target.value, 'name', index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder="0"
                        value={row.payment}
                        onChange={(e) => handleRowChange(e.target.value, 'payment', index)}
                    />
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveRow(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddContract() {
        const [from, setFrom] = useState(new Date());
        const [fromts, setFromts] = useState(0);
        const [to, setTo] = useState(new Date());
        const [tots, setTots] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([{ date: Math.floor(Date.now() / 1000), name: '', amount: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromts(Math.floor(new Date(from).getTime() / 1000));
        }, [from]);

        useEffect(() => {
            setTots(Math.floor(new Date(to).getTime() / 1000));
        }, [to]);

        const handleRowChange = (value, field, index) => {
            const updatedRows = [...rows];
            if (field == 'date') {
                updatedRows[index][field] = Math.floor(new Date(value).getTime() / 1000);
            }
            else {
                updatedRows[index][field] = value;
            }
            setRows(updatedRows);
        };

        const handleRemoveRow = (indexToRemove) => {
            setRows(rows.filter((_, index) => index !== indexToRemove));
        };

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        let currency = project.currencies.find((currency) => currency.id === currencyid)?.symbol || '';

        const handleAddRow = () => {
            const lastRow = rows[rows.length - 1];
            setRows([...rows, { ...lastRow }]);
        };

        const addContract = (fromts, tots, supplierid, name, currencyid, rows, amount) => {
            console.log('Trying to add contract');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Contracts',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!supplierid || !name || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                from: fromts,
                to: tots,
                supplierid: supplierid,
                name: trimmedName,
                currencyid: currencyid,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CONTRACT',
                            contract: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={from}
                                onChange={(date) => setFrom(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={to}
                                onChange={(date) => setTo(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={supplierid} setItemid={setSupplierid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Payment Terms</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead><tr><th>Date</th><th>Name</th><th>Payment</th><th></th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleRowChange={handleRowChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><div>Total</div></td>
                                    <td><div></div></td>
                                    <td><div>{amount} {currency}</div></td>
                                    <td><div></div></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className='modal_button' onClick={handleAddRow}>Add row</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addContract(fromts, tots, supplierid, name, currencyid, rows, amount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditContract({ contract }) {
        const [id, setId] = useState('');

        const [from, setFrom] = useState(new Date());
        const [fromts, setFromts] = useState(0);
        const [to, setTo] = useState(new Date());
        const [tots, setTots] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (contract) {
                const contractCopy = {
                    ...contract,
                    rows: Array.isArray(contract.rows) ? contract.rows.map(row => ({ ...row })) : [],
                };

                setId(contractCopy.id);
                setFrom(new Date(contractCopy.from * 1000));
                setTo(new Date(contractCopy.to * 1000));
                setSupplierid(contractCopy.supplierid);
                setName(contractCopy.name);
                setCurrencyid(contractCopy.currencyid);
                setRows(contractCopy.rows);
            }
        }, [contract]);

        useEffect(() => {
            setFromts(Math.floor(new Date(from).getTime() / 1000));
        }, [from]);

        useEffect(() => {
            setTots(Math.floor(new Date(to).getTime() / 1000));
        }, [to]);

        const handleRowChange = (value, field, index) => {
            const updatedRows = [...rows];
            if (field == 'date') {
                updatedRows[index][field] = Math.floor(new Date(value).getTime() / 1000);
            }
            else {
                updatedRows[index][field] = value;
            }
            setRows(updatedRows);
        };

        const handleRemoveRow = (indexToRemove) => {
            setRows(rows.filter((_, index) => index !== indexToRemove));
        };

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        let currency = project.currencies.find((currency) => currency.id === currencyid)?.symbol || '';

        const handleAddRow = () => {
            const lastRow = rows[rows.length - 1];
            setRows([...rows, { ...lastRow }]);
        };

        const updateContract = (id, fromts, tots, supplierid, name, currencyid, rows, amount) => {
            console.log('Trying to update contract');
            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Contracts',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!supplierid || !name) {
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                from: fromts,
                to: tots,
                supplierid: supplierid,
                name: trimmedName,
                currencyid: currencyid,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CONTRACT',
                            contract: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={from}
                                onChange={(date) => setFrom(date)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={to}
                                onChange={(date) => setTo(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={supplierid} setItemid={setSupplierid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Payment Terms</div>
                        </div>
                    </div>
                    <div className='modal_actions'>
                        <table className='modal_table'>
                            <thead><tr><th>Date</th><th>Name</th><th>Payment</th><th></th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleRowChange={handleRowChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><div>Total</div></td>
                                    <td><div></div></td>
                                    <td><div>{currency} {amount.toLocaleString('en-US')}</div></td>
                                    <td><div></div></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className='modal_button' onClick={handleAddRow}>Add row</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateContract(id, fromts, tots, supplierid, name, currencyid, rows, amount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(contract)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveContract({ contract }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(contract.id);
        }, [contract]);

        const removeContract = (id) => {
            console.log('Trying to remove contract');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Contracts',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CONTRACT',
                            contractid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeContract(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewContract({ contract }) {
        const [id, setId] = useState('');
        const [from, setFrom] = useState('');
        const [to, setTo] = useState('');

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setId(contract.id);
            setFrom(contract.from);
            setTo(contract.to);
            setSupplierid(contract.supplierid);
            setName(contract.name);
            setCurrencyid(contract.currencyid);
            setRows(contract.rows);
        }, [contract]);

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        const currency = project.currencies.find(item => item.id === currencyid, 10)?.symbol || '';
        let supplierName = project.suppliers.find(supplier => supplier.id === supplierid)?.name || 'Supplier not found';

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>From</div>
                        </div>
                        <div className='modal_rowsection'>
                            {moment.unix(from).format('D MMMM YYYY')}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>To</div>
                        </div>
                        <div className='modal_rowsection'>
                            {moment.unix(to).format('D MMMM YYYY')}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>
                            {supplierName}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_description'>{name}</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead><tr><th>Date</th><th>Name</th><th>Payment</th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <tr key={'row' + index}>
                                            <td>{moment.unix(row.date).format('D/MMM/YYYY')}</td>
                                            <td>{row.name}</td>
                                            <td>{currency} {row.payment.toLocaleString('en-US')}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><div>Total</div></td>
                                    <td><div></div></td>
                                    <td><div>{currency} {amount.toLocaleString('en-US')}</div></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='contracts'>
            <Title text='Contracts' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Contract</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Supplier</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredcontracts.map((contract, index) => {
                            let from = moment.unix(contract.from).format('D/MMM/YYYY');
                            let to = moment.unix(contract.to).format('D/MMM/YYYY');
                            let supplierName = project.suppliers.find(supplier => supplier.id === contract.supplierid)?.name || 'Supplier not found';
                            let amount = contract.amount.toLocaleString('en-US', { minimumFractionDigits: Number.isInteger(contract.amount) ? 0 : 2, maximumFractionDigits: 2 });
                            let currency = project.currencies.find((currency) => currency.id === contract.currencyid)?.symbol || '';

                            return (
                                <tr key={'contract' + index}>
                                    <td><div className='table_button' onClick={() => editButton(contract)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(contract)}>View</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{contract.name}</div></td>
                                    <td><div>{from}</div></td>
                                    <td><div>{to}</div></td>
                                    <td><div>{currency} {amount}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Contracts;