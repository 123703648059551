import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "./StateProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactToPrint from "react-to-print";
import StatementAccount from "./StatementAccount";
import html2canvas from 'html2canvas';
import Plot from 'react-plotly.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import configData from './Config';

import { getCashAccounts } from "./Utils/cash.js";
import { getActualBudget, getCashFlowData, getReceipts, getExpenses, getClientBalancesRow, getClientBalancesColumn, getSupplierAgingReport } from "./Utils/reports.js";
import { getSupplierAccounts } from "./Utils/suppliers.js";
import { registerArabicFont } from "./Utils/registerarabicfont.js";

import Title from "./Title";

import "./Reports.css";

function Reports({ state, setState }) {
  const [{ project }, dispatch] = useStateValue();

  const [from, setFrom] = useState(project.startdate ? new Date(project.startdate * 1000) : new Date());
  const [fromts, setFromTs] = useState(0);
  const [to, setTo] = useState(new Date());
  const [tots, setToTs] = useState(0);

  const [option, setOption] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const startOfDay = new Date(from);
    startOfDay.setHours(0, 0, 0, 0);
    const fromTs = Math.floor(startOfDay.getTime() / 1000);
    setFromTs(fromTs);
  }, [from]);

  useEffect(() => {
    const endOfDay = new Date(to);
    endOfDay.setHours(23, 59, 59, 999);
    const toTs = Math.floor(endOfDay.getTime() / 1000);
    setToTs(toTs);
  }, [to]);

  const handleActualvsbudget = () => {
    setOption(<ActualvsBudget />);
  };

  const handleCashFlow = () => {
    setOption(<CashFlow />);
  };

  const handleReceiptsReport = () => {
    setOption(<ReceiptsReport />);
  };

  const handleExpensesReport = () => {
    setOption(<ExpensesReport />);
  };

  const handleClientBalances = () => {
    setOption(<ClientBalances />);
  };

  const handleAgedPayables = () => {
    setOption(<AgedPayables />);
  };

  const handleOverallReport = () => {
    setOption(<OverallReport />);
  };

  const handleAccountStatement = (type, account) => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <StatementAccount state={state} setState={setState} type={type} account={account} />, modaltype: 'large', modaltitle: 'Statement of Account' }));
  };

  function ActualvsBudget() {
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccountid, setSubaccountid] = useState(0);

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const actualbudgetdata = getActualBudget(
      project.incomegroups,
      project.incomeaccounts,
      project.expensegroups,
      project.expenseaccounts,
      project.journalentries,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccountid,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const printRef = useRef();

    const saveAsPDF = () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Actual vs Budget';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + projectName, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const cashAccountName = project.cashaccounts.find(cash => cash.id === cashaccount)?.name || 'All';
        doc.text('Cash Account: ' + cashAccountName, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const subAccountName = project.subaccounts.find(sub => sub.id === subaccountid)?.name || 'All';
        doc.text('Subaccount: ' + subAccountName, 10, currentY);

        currentY += 10;
        const tableColumn = ['Name', 'Actual', 'Budget', 'Percentage'];

        const tableData = actualbudgetdata.map((data) => {
          if (data.type === "section") return [
            { content: data.name, styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
          ];
          if (data.type === "sectiontotal") return [
            { content: data.name, styles: { fontStyle: 'bold' } },
            { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'bold' } },
            { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'bold' } },
            { content: data.percentage, styles: { fontStyle: 'bold' } },
          ];
          if (data.type === "group") return [
            { content: '  ' + data.name, styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
          ];
          if (data.type === "grouptotal") return [
            { content: '    ' + data.name, styles: { fontStyle: 'bold' } },
            { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'bold' } },
            { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'bold' } },
            { content: data.percentage, styles: { fontStyle: 'bold' } },
          ];
          if (data.type == 'account') return [
            { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'normal' } },
            { content: data.percentage, styles: { fontStyle: 'normal' } },
          ];
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableData,
          startY: currentY,
          theme: 'grid',
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          },
          bodyStyles: {
            fontSize: 10,
            font: 'notoarabic',
          },
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
            2: { halign: 'right' },
            3: { halign: 'center' },
          },
          margin: { top: 10, left: 10, right: 10 },
        });

        const fileName = 'Actual vs Budget ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    return (
      <div className="report">
        <div className="reports_row">
          <div className="reports_rowsection">
            Cash Account
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={cashaccount}
              onChange={(e) => setCashaccount(e.target.value)}
            >
              <option value={0}>All</option>
              {project.cashaccounts.map((account, index) => (
                <option key={'account' + index} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
          <div className="reports_rowsection">
            Subaccount
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={subaccountid}
              onChange={(e) => setSubaccountid(e.target.value)}
            >
              <option value={0}>All</option>
              {project.subaccounts.map((subaccount) => (
                <option key={subaccount.id} value={subaccount.id}>
                  {subaccount.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF} disabled={loadingpdf}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Actual vs Budget</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_projectdetails">Cash Account: {project.cashaccounts.find((cash) => cash.id === cashaccount)?.name || 'All'}, Subaccount: {project.subaccounts.find((sub) => sub.id === subaccountid)?.name || 'All'}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actual</th>
                <th>Budget</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {
                actualbudgetdata.map((data, index) => {
                  if (data.type === "section") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablesection">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "group") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablegroup">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "grouptotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "account") {
                    if (data.section === "income") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div
                              className="reports_clickable"
                              onClick={() =>
                                handleAccountStatement(
                                  "incomeaccounts",
                                  {
                                    id: data.accountid,
                                    name: data.name,
                                    from: fromts,
                                    to: tots,
                                    subaccountid: subaccountid,
                                    currencyid: project.basecurrency,
                                  }
                                )
                              }
                            >
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.section === "expense") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div
                              className="reports_clickable"
                              onClick={() =>
                                handleAccountStatement(
                                  "expenseaccounts",
                                  {
                                    id: data.accountid,
                                    name: data.name,
                                    from: fromts,
                                    to: tots,
                                    cashaccount: cashaccount,
                                    subaccountid: subaccountid,
                                    currencyid: project.basecurrency,
                                  }
                                )
                              }
                            >
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                  }
                  if (data.type === "sectiontotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function CashFlow() {
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccountid, setSubaccountid] = useState(0);

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const cashflowdata = getCashFlowData(
      project.cashaccounts,
      project.expenseaccounts,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccountid,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Cash Flow Statement';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + project.name, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        currentY += 5;
        const cashAccountName = project.cashaccounts.find(cash => cash.id === cashaccount)?.name || 'All';
        doc.text('Cash Account: ' + cashAccountName, 10, currentY);

        currentY += 5;
        const subAccountName = project.subaccounts.find(sub => sub.id === subaccountid)?.name || 'All';
        doc.text('Subaccount: ' + subAccountName, 10, currentY);

        currentY += 10;
        const tableColumn = ['Name', 'Amount'];

        const tableData = cashflowdata.map((data) => {
          if (data.type === "section") return [
            { content: data.name, styles: { fontStyle: 'bold' } },
            { content: '', styles: { fontStyle: 'bold' } },
          ];
          if (data.type === "accountinflows") return [
            { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
          ];
          if (data.type === "accountoutflows") return [
            { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
          ];
          if (data.type === "grouptotal") return [
            { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
          ];
          if (data.type === "sectiontotal") return [
            { content: data.name, styles: { fontStyle: 'bold' } },
            { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'bold' } },
          ];
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableData,
          startY: currentY,
          theme: 'grid',
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          },
          bodyStyles: {
            fontSize: 10,
            font: 'notoarabic',
          },
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'right' },
          },
          margin: {
            top: 10,
            left: 10,
            right: 10
          },
        });

        const fileName = 'Cash Flow Statement ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_row">
          <div className="reports_rowsection">
            Cash Account
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={cashaccount}
              onChange={(e) => setCashaccount(e.target.value)}
            >
              <option value={0}>All</option>
              {project.cashaccounts.map((account, index) => (
                <option key={'account' + index} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
          <div className="reports_rowsection">
            Subaccount
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={subaccountid}
              onChange={(e) => setSubaccountid(e.target.value)}
            >
              <option value={0}>All</option>
              {project.subaccounts.map((subaccount) => (
                <option key={subaccount.id} value={subaccount.id}>
                  {subaccount.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Cash Flow Statement</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_projectdetails">Cash Account: {project.cashaccounts.find((cash) => cash.id === cashaccount)?.name || 'All'}, Subaccount: {project.subaccounts.find((sub) => sub.id === subaccountid)?.name || 'All'}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                cashflowdata.map((account, index) => {
                  if (account.type === "section") {
                    return (
                      <tr key={"account" + index}>
                        <td colSpan={2}>
                          <div className="reports_tablesection">{account.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "accountinflows") {
                    return (
                      <tr key={"account" + index} className="reports_tableaccount">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div
                            className="reports_clickable"
                            onClick={() =>
                              handleAccountStatement(
                                "receivables",
                                {
                                  name: 'Inflows / Receipts',
                                  from: fromts,
                                  to: tots,
                                  cashaccount: cashaccount,
                                  subaccountid: subaccountid,
                                  currencyid: project.basecurrency
                                }
                              )
                            }
                          >
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "accountoutflows") {
                    return (
                      <tr key={"account" + index} className="reports_tableaccount">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div
                            className="reports_clickable"
                            onClick={() =>
                              handleAccountStatement(
                                "expenseaccounts",
                                {
                                  id: account.id,
                                  name: account.name,
                                  from: fromts,
                                  to: tots,
                                  cashaccount: cashaccount,
                                  subaccountid: subaccountid,
                                  currencyid: project.basecurrency,
                                }
                              )
                            }
                          >
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "grouptotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "sectiontotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ReceiptsReport() {
    const [mode, setMode] = useState('transactions');
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccountid, setSubaccountid] = useState(0);

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const receiptsdata = getReceipts(
      project.clients,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccountid,
      project.cashaccounts,
      project.subaccounts,
      project.exchangerates,
      project.currencies,
      project.basecurrency,
      mode
    );

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Receivables Report';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + projectName, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const cashAccountName = project.cashaccounts.find(cash => cash.id === cashaccount)?.name || 'All';
        doc.text('Cash Account: ' + cashAccountName, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const subAccountName = project.subaccounts.find(sub => sub.id === subaccountid)?.name || 'All';
        doc.text('Subaccount: ' + subAccountName, 10, currentY);

        currentY += 10;
        const tableColumn = ['Date', 'Client', 'Name', 'Cash Account', 'Subaccount', 'Amount'];

        const tableData = receiptsdata.map((data) => {
          if (data.type == "section")
            return [
              { content: data.name, colSpan: 6, styles: { fontStyle: 'bold' } },
            ];
          if (data.type == "account")
            return [
              { content: data.date, styles: { fontStyle: 'normal' } },
              { content: data.clientname, styles: { fontStyle: 'normal' } },
              { content: data.name, styles: { fontStyle: 'normal' } },
              { content: data.cashaccount, styles: { fontStyle: 'normal' } },
              { content: data.subaccountid, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
            ];
          if (data.type == "total")
            return [
              { content: data.name, colSpan: 5, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'bold' } },
            ];
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableData,
          startY: currentY,
          theme: 'grid',
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          },
          bodyStyles: {
            fontSize: 10,
            font: 'notoarabic',
          },
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'left' },
            2: { halign: 'left' },
            3: { halign: 'left' },
            4: { halign: 'left' },
            5: { halign: 'center' },
          },
          margin: { top: 10, left: 10, right: 10 },
        });

        const fileName = 'Receipts Report ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_row">
          <div className="reports_rowsection">
            Mode
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              <option value='transactions'>Transactions</option>
              <option value='clients'>Clients</option>
            </select>
          </div>
          <div className="reports_rowsection">
            Cash Account
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={cashaccount}
              onChange={(e) => setCashaccount(e.target.value)}
            >
              <option value={0}>All</option>
              {project.cashaccounts.map((account, index) => (
                <option key={'account' + index} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
          <div className="reports_rowsection">
            Subaccount
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={subaccountid}
              onChange={(e) => setSubaccountid(e.target.value)}
            >
              <option value={0}>All</option>
              {project.subaccounts.map((subaccount) => (
                <option key={subaccount.id} value={subaccount.id}>
                  {subaccount.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Receivables Report</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_projectdetails">Cash Account: {project.cashaccounts.find((cash) => cash.id === cashaccount)?.name || 'All'}, Subaccount: {project.subaccounts.find((sub) => sub.id === subaccountid)?.name || 'All'}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Client</th>
                <th>Name</th>
                <th>Cashaccount</th>
                <th>Subaccount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                receiptsdata.map((receipt, index) => {
                  if (receipt.type === "section") {
                    return (
                      <tr key={"receipt" + index} className="reports_tablesection">
                        <td colSpan={6}>
                          <div>{receipt.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (receipt.type === "account") {
                    return (
                      <tr key={"receipt" + index}>
                        <td>
                          <div>{receipt.date}</div>
                        </td>
                        <td>
                          <div>{receipt.clientname}</div>
                        </td>
                        <td>
                          <div>{receipt.name}</div>
                        </td>
                        <td>
                          <div>{receipt.cashaccount}</div>
                        </td>
                        <td>
                          <div>{receipt.subaccount}</div>
                        </td>
                        <td>
                          <div>
                            {receipt.currency + ' ' + receipt.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (receipt.type === "total") {
                    return (
                      <tr key={"receipt" + index} className='reports_tablesectiontotal'>
                        <td colSpan={5}>
                          <div>{receipt.name}</div>
                        </td>
                        <td>
                          <div>
                            {receipt.currency + ' ' + receipt.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ExpensesReport() {
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccountid, setSubaccountid] = useState(0);

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const expensesdata = getExpenses(
      project.suppliers,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccountid,
      project.cashaccounts,
      project.subaccounts,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Expenses Report';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + projectName, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const cashAccountName = project.cashaccounts.find(cash => cash.id === cashaccount)?.name || 'All';
        doc.text('Cash Account: ' + cashAccountName, 10, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const subAccountName = project.subaccounts.find(sub => sub.id === subaccountid)?.name || 'All';
        doc.text('Subaccount: ' + subAccountName, 10, currentY);

        currentY += 10;
        const tableColumn = ['Date', 'Client', 'Name', 'Cash Account', 'Subaccount', 'Amount'];

        const tableData = expensesdata.map((data) => {
          if (data.type == "account")
            return [
              { content: data.date, styles: { fontStyle: 'normal' } },
              { content: data.suppliername, styles: { fontStyle: 'normal' } },
              { content: data.name, styles: { fontStyle: 'normal' } },
              { content: data.cashaccount, styles: { fontStyle: 'normal' } },
              { content: data.subaccountid, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
            ];
          if (data.type == "total")
            return [
              { content: data.name, colSpan: 5, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'bold' } },
            ];
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableData,
          startY: currentY,
          theme: 'grid',
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          },
          bodyStyles: {
            fontSize: 10,
            font: 'notoarabic',
          },
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'left' },
            2: { halign: 'left' },
            3: { halign: 'left' },
            4: { halign: 'left' },
            5: { halign: 'center' },
          },
          margin: { top: 10, left: 10, right: 10 },
        });

        const fileName = 'Expenses Report ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_row">
          <div className="reports_rowsection">
            Cash Account
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={cashaccount}
              onChange={(e) => setCashaccount(e.target.value)}
            >
              <option value={0}>All</option>
              {project.cashaccounts.map((account, index) => (
                <option key={'account' + index} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
          <div className="reports_rowsection">
            Subaccount
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={subaccountid}
              onChange={(e) => setSubaccountid(e.target.value)}
            >
              <option value={0}>All</option>
              {project.subaccounts.map((subaccount) => (
                <option key={subaccount.id} value={subaccount.id}>
                  {subaccount.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Expenses Report</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_projectdetails">Cash Account: {project.cashaccounts.find((cash) => cash.id === cashaccount)?.name || 'All'}, Subaccount: {project.subaccounts.find((sub) => sub.id === subaccountid)?.name || 'All'}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Supplier</th>
                <th>Name</th>
                <th>Cashaccount</th>
                <th>Subaccount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {expensesdata.map((expense, index) => {
                if (expense.type === "account") {
                  return (
                    <tr key={"expense" + index}>
                      <td>
                        <div>{expense.date}</div>
                      </td>
                      <td>
                        <div>{expense.suppliername}</div>
                      </td>
                      <td>
                        <div>{expense.name}</div>
                      </td>
                      <td>
                        <div>{expense.cashaccount}</div>
                      </td>
                      <td>
                        <div>{expense.subaccount}</div>
                      </td>
                      <td>
                        <div>
                          {expense.currency + ' ' + expense.amount}
                        </div>
                      </td>
                    </tr>
                  );
                }
                if (expense.type === "total") {
                  return (
                    <tr key={"expense" + index} className='reports_tablesectiontotal'>
                      <td colSpan={5}>
                        <div>{expense.name}</div>
                      </td>
                      <td>
                        <div>
                          {expense.currency + ' ' + expense.amount}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ClientBalances() {
    const [filteredclientsrow, setFilteredclientsrow] = useState([]);

    const [filteredclientscolumnheader, setFilteredclientscolumnheader] = useState([]);
    const [filteredclientscolumnrows, setFilteredclientscolumnrows] = useState([]);
    const [filteredclientscolumnfooter, setFilteredclientscolumnfooter] = useState([]);

    const [subaccountid, setSubaccountid] = useState(0);
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState("nameasc");

    const [mode, setMode] = useState("column");

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    useEffect(() => {
      const filteredClients = project.clients.filter((client) =>
        client.name.toLowerCase().includes(search.toLowerCase())
      );

      if (mode === "row") {
        let clientsdatarow = getClientBalancesRow(
          filteredClients,
          project.transactions,
          project.journalentries,
          fromts,
          tots,
          project.subaccounts,
          subaccountid,
          project.currencies,
          project.exchangerates,
          order
        );

        setFilteredclientsrow(clientsdatarow);
      }

      if (mode === "column") {
        let clientsdatacolumn = getClientBalancesColumn(
          filteredClients,
          project.transactions,
          project.journalentries,
          fromts,
          tots,
          project.subaccounts,
          project.currencies,
          project.exchangerates,
          project.basecurrency,
          order
        );

        setFilteredclientscolumnheader(clientsdatacolumn.header);
        setFilteredclientscolumnfooter(clientsdatacolumn.footer);
        setFilteredclientscolumnrows(clientsdatacolumn.rows);
      }
    }, [search, subaccountid, order, mode]);

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const copyButtonExcelColumn = () => {
      const header = filteredclientscolumnheader;
      const rows = filteredclientscolumnrows.map(row => row.columns);

      const tsvData = [header, ...rows].map(row => row.join("\t")).join("\n");

      navigator.clipboard.writeText(tsvData)
        .then(() => {
          alert('Data copied to clipboard successfully!');
        })
        .catch(err => {
          console.error('Failed to copy data to clipboard: ', err);
        });
    };

    const copyButtonExcelRow = () => {
      const header = ["Name", "Subaccount", "Balance"];
      const rows = filteredclientsrow.map(client => [
        client.clientname,
        client.subaccountname,
        `${client.currencysymbol} ${client.balance.toLocaleString('en-US', {
          minimumFractionDigits: Number.isInteger(client.balance) ? 0 : 2,
          maximumFractionDigits: 2,
        })}`
      ]);

      const tsvData = [header, ...rows].map(row => row.join("\t")).join("\n");

      navigator.clipboard.writeText(tsvData)
        .then(() => {
          alert('Data copied to clipboard successfully!');
        })
        .catch(err => {
          console.error('Failed to copy data to clipboard: ', err);
        });
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Client Balances';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + projectName, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        if (mode == "row") {
          currentY += 5;
          doc.setFontSize(10);
          const subAccountName = project.subaccounts.find(sub => sub.id === subaccountid)?.name || 'All';
          doc.text('Subaccount: ' + subAccountName, 10, currentY);

          currentY += 10;
          const tableColumn = ['Name', 'Subaccount', 'Amount'];

          const tableData = filteredclientsrow.map((data) => {
            return [
              { content: data.clientname, styles: { fontStyle: 'normal' } },
              { content: data.subaccountname, styles: { fontStyle: 'normal' } },
              { content: data.currencysymbol + ' ' + data.balance, styles: { fontStyle: 'normal' } },
            ];
          });

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            columnStyles: {
              0: { halign: 'left' },
              1: { halign: 'left' },
              2: { halign: 'center' },
            },
            margin: { top: 10, left: 10, right: 10 },
          });

          const fileName = 'Client Balances Row ' + projectName + '.pdf';
          doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
          setResultpdf("Saved Successfully");
        }
        if (mode == "column") {
          currentY += 10;
          const tableColumn = [];

          filteredclientscolumnheader.forEach(header => {
            tableColumn.push(header);
          });
          const tableData = filteredclientscolumnrows.map((data) => {
            return data.columns.map((column) => {
              return { content: column, styles: { fontStyle: 'normal' } };
            });
          });

          const footerRow = filteredclientscolumnfooter.map((data) => {
            return { content: data, styles: { fontStyle: 'bold' } };
          });
          tableData.push(footerRow);

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            margin: { top: 10, left: 10, right: 10 },
          });

          const fileName = 'Client Balances ' + projectName + '.pdf';
          doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
          setResultpdf("Saved Successfully");
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    const shareAsScreenshot = async () => {
      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const canvas = await html2canvas(content, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: content.scrollWidth,
          windowHeight: content.scrollHeight,
        });

        const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', 1));

        if (navigator.share) {
          const file = new File([blob], `clientbalances_${projectName}_${currentDate}.jpeg`, { type: 'image/jpeg' });

          await navigator.share({
            title: "Client Balances",
            text: "Check out this screenshot!",
            files: [file],
          });
        } else {
          console.error("Web Share API is not supported in this browser.");
        }
      } catch (error) {
        console.error("Error sharing screenshot:", error);
      }
    };

    return (
      <div className="report">
        <div className="reports_row">
          <div className="reports_rowsection">
            <input
              className="reports_input"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="reports_rowsection">
            Mode
          </div>
          <div className="reports_rowsection">
            <select
              className="reports_select"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              <option value="column">Column mode</option>
              <option value="row">Row mode</option>
            </select>
          </div>
          {
            mode == 'row' &&
            <>
              <div className="reports_rowsection">
                Subaccount
              </div>
              <div className="reports_rowsection">
                <select
                  className="reports_select"
                  value={subaccountid}
                  onChange={(e) => setSubaccountid(e.target.value)}
                >
                  <option value={0}>All</option>
                  {project.subaccounts.map((subaccount) => (
                    <option key={subaccount.id} value={subaccount.id}>
                      {subaccount.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          }
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          {
            mode == 'row' &&
            <button className="reports_button" onClick={copyButtonExcelRow}>
              Copy to Excel
            </button>
          }
          {
            mode == 'column' &&
            <button className="reports_button" onClick={copyButtonExcelColumn}>
              Copy to Excel
            </button>
          }
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
          <button className="reports_button" onClick={shareAsScreenshot}>
            Share as Screenshot
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Client Balances</div>
              <div className="reports_projectname">{project.name}</div>
              {mode == "row" && <div className="reports_projectdetails">Subaccount: {project.subaccounts.find((sub) => sub.id === subaccountid)?.name || 'All'}</div>}
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          {
            mode == "row" && (
              <table className="reports_table">
                <thead>
                  <tr>
                    <th>Name
                      <div className="table_sortbutton" onClick={() => setOrder(order === "nameasc" ? "namedesc" : "nameasc")}>
                        {order === "nameasc" ? " ↓" : " ↑"}
                      </div>
                    </th>
                    <th>Subaccount</th>
                    <th>
                      Balance
                      <div className="table_sortbutton" onClick={() => setOrder(order === "balanceasc" ? "balancedesc" : "balanceasc")}>
                        {order === "balanceasc" ? " ↓" : " ↑"}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredclientsrow.map((client, index) => {
                    let clientBalanceLoc = client.balance.toLocaleString("en-US");
                    return (
                      <tr key={"client" + index}>
                        <td>
                          <div>{client.clientname}</div>
                        </td>
                        <td>
                          <div>{client.subaccountname}</div>
                        </td>
                        <td>
                          <div>
                            {client.currencysymbol + ' ' + clientBalanceLoc}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
          }
          {
            mode == "column" && (
              <table className="reports_table">
                <thead>
                  <tr>
                    {filteredclientscolumnheader.map((headeritem, index) => {
                      return <th key={"headeritem" + index}>
                        {headeritem}
                        {
                          index === 0 &&
                          <div className="table_sortbutton" onClick={() => setOrder(order === "nameasc" ? "namedesc" : "nameasc")}>
                            {order === "nameasc" ? " ↓" : " ↑"}
                          </div>
                        }
                        {
                          index === filteredclientscolumnheader.length - 1 &&
                          <div className="table_sortbutton" onClick={() => setOrder(order === "balanceasc" ? "balancedesc" : "balanceasc")}>
                            {order === "balanceasc" ? " ↓" : " ↑"}
                          </div>
                        }
                      </th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredclientscolumnrows.map((row, index) => {
                      return (
                        <tr key={"row" + index}>
                          {row?.columns.map((column, index) => (
                            <td key={"column" + index}>
                              <div>{column}</div>
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    {filteredclientscolumnfooter.map((footerItem, index) => (
                      <td key={"footer" + index}>
                        <strong>{footerItem}</strong>
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </table>
            )
          }
        </div>
      </div>
    );
  }

  function AgedPayables() {
    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const printRef = useRef();

    let suppliersagingdata = getSupplierAgingReport(
      project.suppliers,
      project.transactions,
      project.purchaseinvoices,
      project.journalentries,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      try {
        const projectName = project.name;

        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Aged Payables';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(10);
        doc.text('Project Name: ' + projectName, 10, currentY);

        currentY += 5;
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        doc.text('From: ' + formattedFrom, 10, currentY);
        currentY += 5;
        doc.text('To: ' + formattedTo, 10, currentY);

        currentY += 10;
        const tableColumn = ['Supplier', '30 Days', '60 Days', '90 Days', 'Over 90 Days'];

        const tableData = suppliersagingdata.map((data) => {
          return [
            { content: data.name, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.aging?.thirty, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.aging?.sixty, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.aging?.ninety, styles: { fontStyle: 'normal' } },
            { content: data.currency + ' ' + data.aging?.overninety, styles: { fontStyle: 'normal' } },
          ];
        });

        doc.autoTable({
          head: [tableColumn],
          body: tableData,
          startY: currentY,
          theme: 'grid',
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
          },
          bodyStyles: {
            fontSize: 10,
            font: 'notoarabic',
          },
          columnStyles: {
            0: { halign: 'left' },
            1: { halign: 'left' },
            2: { halign: 'left' },
            3: { halign: 'left' },
          },
          margin: { top: 10, left: 10, right: 10 },
        });

        const fileName = 'Aged Payables ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Aged Payables</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className='reports_table'>
            <thead>
              <tr>
                <th>Supplier</th>
                <th>30 Days</th>
                <th>60 Days</th>
                <th>90 Days</th>
                <th>Over 90 Days</th>
              </tr>
            </thead>
            <tbody>
              {
                suppliersagingdata.map((supplier, index) => (
                  <tr key={'supplier' + index}>
                    <td>
                      <div
                        className='reports_clickable'
                        onClick={() => handleAccountStatement(
                          'suppliers',
                          {
                            id: supplier.id,
                            name: supplier.name,
                            from: Math.floor(new Date(new Date(fromts * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                            to: Math.floor(new Date(tots * 1000).setHours(23, 59, 59, 999) / 1000),
                            cashaccount: 0,
                            subaccountid: 0,
                            currencyid: project.basecurrency,
                          })
                        }
                      >{supplier.name}</div>
                    </td>
                    <td>{supplier.currency + ' ' + supplier.aging.thirty}</td>
                    <td>{supplier.currency + ' ' + supplier.aging.sixty}</td>
                    <td>{supplier.currency + ' ' + supplier.aging.ninety}</td>
                    <td>{supplier.currency + ' ' + supplier.aging.overninety}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function OverallReport() {
    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const initializeVisibility = (project) => ({
      cashaccounts: { visible: true, name: "Cash Accounts", disabled: false },
      cashflowstatement: { visible: true, name: "Cash Flow Statement", disabled: false },
      actualvsbudget: { visible: true, name: "Actual vs Budget", disabled: false },
      clientbalances: { visible: true, name: "Client Balances", disabled: false },
      collections: { visible: true, name: "Collections", disabled: false },
      contracts: { visible: project.contracts?.length > 0, name: "Contracts", disabled: project.contracts?.length === 0 },
      payables: { visible: true, name: "Payables", disabled: false },
      inspections: { visible: project.inspections?.length > 0, name: "Inspections", disabled: project.inspections?.length === 0 },
      tasks: { visible: project.tasks?.length > 0, name: "Tasks", disabled: project.tasks?.length === 0 },
    });

    const [visibility, setVisibility] = useState(() => initializeVisibility(project));

    const printRef = useRef();

    const cashaccountsdata = getCashAccounts(
      project.cashaccounts,
      project.transactions,
      project.interaccounttransfers,
      project.currencies,
      project.exchangerates
    );
    const actualbudgetdata = getActualBudget(
      project.incomegroups,
      project.incomeaccounts,
      project.expensegroups,
      project.expenseaccounts,
      project.journalentries,
      project.transactions,
      fromts,
      tots,
      0,
      0,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const cashflowdata = getCashFlowData(
      project.cashaccounts,
      project.expenseaccounts,
      project.transactions,
      fromts,
      tots,
      0,
      0,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const [filteredclientscolumnheader, setFilteredclientscolumnheader] = useState([]);
    const [filteredclientscolumnrows, setFilteredclientscolumnrows] = useState([]);
    const [filteredclientscolumnfooter, setFilteredclientscolumnfooter] = useState([]);
    useEffect(() => {
      const clientsdatacolumn = getClientBalancesColumn(
        project.clients,
        project.transactions,
        project.journalentries,
        fromts,
        tots,
        project.subaccounts,
        project.currencies,
        project.exchangerates,
        project.basecurrency,
        'nameasc'
      );

      setFilteredclientscolumnheader(clientsdatacolumn.header);
      setFilteredclientscolumnrows(clientsdatacolumn.rows);
      setFilteredclientscolumnfooter(clientsdatacolumn.footer);
    }, []);

    const [collections, setCollections] = useState([]);
    useEffect(() => {
      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid
      };

      axios.post(configData.CONTROLLERURL + configData.GETCOLLECTIONS, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        if (res.data instanceof Array) {
          setCollections(res.data);
        }
      }).catch((err) => {
      }).finally(() => {
      });
    }, []);

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      try {
        const doc = new jsPDF();
        registerArabicFont(doc);

        const pageWidth = doc.internal.pageSize.getWidth();
        let currentY = 10;

        currentY += 10;
        doc.setFontSize(24);
        const title = 'Report';
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, currentY);

        currentY += 10;
        doc.setFontSize(14);
        const projectName = project.name;
        const projectnameWidth = doc.getTextWidth(projectName);
        doc.text(projectName, (pageWidth - projectnameWidth) / 2, currentY);

        currentY += 5;
        doc.setFontSize(10);
        const formattedFrom = moment.unix(fromts).format("D/MMM/YYYY");
        const formattedTo = moment.unix(tots).format("D/MMM/YYYY");
        const daterangeWidth = doc.getTextWidth(formattedFrom + ' to ' + formattedTo);
        doc.text(formattedFrom + ' to ' + formattedTo, (pageWidth - daterangeWidth) / 2, currentY);

        if (visibility.cashaccounts.visible) {
          currentY += 10;
          doc.setFontSize(20);
          const chapter = 'Cash Accounts';
          const chapterWidth = doc.getTextWidth(chapter);
          doc.text(chapter, (pageWidth - chapterWidth) / 2, currentY);

          currentY += 10;
          const tableColumn = ['Name', 'Balance'];

          const tableData = cashaccountsdata.map((data) => {
            return [
              { content: data.name, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.balance.toLocaleString("en-US"), styles: { fontStyle: 'normal' } }
            ];
          });

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            columnStyles: {
              0: { halign: 'left' },
              1: { halign: 'left' },
            },
            margin: { top: 10, left: 10, right: 10 },
          });
          currentY = doc.lastAutoTable.finalY + 10;
        }

        if (visibility.actualvsbudget.visible) {
          currentY += 10;
          doc.setFontSize(20);
          const chapter = 'Actual vs Budget';
          const chapterWidth = doc.getTextWidth(chapter);
          doc.text(chapter, (pageWidth - chapterWidth) / 2, currentY);

          currentY += 10;
          const tableColumn = ['Name', 'Actual', 'Budget', 'Percentage'];

          const tableData = actualbudgetdata.map((data) => {
            if (data.type === "section") return [
              { content: data.name, styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
            ];
            if (data.type === "sectiontotal") return [
              { content: data.name, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'bold' } },
              { content: data.percentage, styles: { fontStyle: 'bold' } },
            ];
            if (data.type === "group") return [
              { content: '  ' + data.name, styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
            ];
            if (data.type === "grouptotal") return [
              { content: '    ' + data.name, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'bold' } },
              { content: data.percentage, styles: { fontStyle: 'bold' } },
            ];
            if (data.type == 'account') return [
              { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.actual, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.budget, styles: { fontStyle: 'normal' } },
              { content: data.percentage, styles: { fontStyle: 'normal' } },
            ];
          });

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            columnStyles: {
              0: { halign: 'left' },
              1: { halign: 'right' },
              2: { halign: 'right' },
              3: { halign: 'center' },
            },
            margin: { top: 10, left: 10, right: 10 },
          });
          currentY = doc.lastAutoTable.finalY + 10;
        }

        if (visibility.cashflowstatement.visible) {
          currentY += 10;
          doc.setFontSize(20);
          const chapter = 'Cash Flow Statement';
          const chapterWidth = doc.getTextWidth(chapter);
          doc.text(chapter, (pageWidth - chapterWidth) / 2, currentY);

          currentY += 10;
          const tableColumn = ['Name', 'Amount'];

          const tableData = cashflowdata.map((data) => {
            if (data.type === "section") return [
              { content: data.name, styles: { fontStyle: 'bold' } },
              { content: '', styles: { fontStyle: 'bold' } },
            ];
            if (data.type === "accountinflows") return [
              { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
            ];
            if (data.type === "accountoutflows") return [
              { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
            ];
            if (data.type === "grouptotal") return [
              { content: '    ' + data.name, styles: { fontStyle: 'normal' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'normal' } },
            ];
            if (data.type === "sectiontotal") return [
              { content: data.name, styles: { fontStyle: 'bold' } },
              { content: data.currency + ' ' + data.amount, styles: { fontStyle: 'bold' } },
            ];
          });

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            columnStyles: {
              0: { halign: 'left' },
              1: { halign: 'right' },
            },
            margin: {
              top: 10,
              left: 10,
              right: 10
            },
          });
          currentY = doc.lastAutoTable.finalY + 10;
        }

        if (visibility.clientbalances.visible) {
          currentY += 10;
          doc.setFontSize(20);
          const chapter = 'Client Balances';
          const chapterWidth = doc.getTextWidth(chapter);
          doc.text(chapter, (pageWidth - chapterWidth) / 2, currentY);

          currentY += 10;
          const tableColumn = [];

          filteredclientscolumnheader.forEach(header => {
            tableColumn.push(header);
          });
          const tableData = filteredclientscolumnrows.map((data) => {
            return data.columns.map((column) => {
              return { content: column, styles: { fontStyle: 'normal' } };
            });
          });

          const footerRow = filteredclientscolumnfooter.map((data) => {
            return { content: data, styles: { fontStyle: 'bold' } };
          });
          tableData.push(footerRow);

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            margin: { top: 10, left: 10, right: 10 },
          });
          currentY = doc.lastAutoTable.finalY + 10;
        }

        if (visibility.collections.visible) {
          currentY += 10;
          doc.setFontSize(20);
          const chapter = 'Client Balances';
          const chapterWidth = doc.getTextWidth(chapter);
          doc.text(chapter, (pageWidth - chapterWidth) / 2, currentY);

          currentY += 10;
          const tableColumn = ['Created', 'Due', 'Completed', '', 'Amount', 'Status'];

          const tableData = collections.map((data) => {
            const isDue = moment().unix() < moment.unix(data.atts).endOf('day').unix();
            if (data.type == 'receipt') {
              const clientName = project.clients.find((client) => client.id === data.clientid)?.name;
              const currencyName = project.currencies.find((currency) => currency.id === data.currencyid)?.name;
              return [
                { content: moment.unix(data.ts).format('dddd, D MMM YYYY hh:mm:ss A'), styles: { fontStyle: 'normal' } },
                { content: moment.unix(data.atts).format('dddd'), styles: { fontStyle: 'normal' } },
                { content: data.completedts && moment.unix(data.completedts).format('dddd, D MMM YYYY hh:mm:ss A'), styles: { fontStyle: 'normal' } },
                { content: clientName, styles: { fontStyle: 'normal' } },
                { content: data.amount + ' ' + currencyName, styles: { fontStyle: 'normal' } },
                { content: data.status === 'pending' ? (isDue ? 'pending' : 'not due') : (data.status === 'received' ? 'received' : ''), styles: { fontStyle: 'bold' } }
              ];
            }
            if (data.type == 'payment') {
              const supplierName = project.suppliers.find((supplier) => supplier.id === data.supplierid)?.name;
              const currencyName = project.currencies.find((currency) => currency.id === data.currencyid)?.name;
              return [
                { content: moment.unix(data.ts).format('dddd, D MMM YYYY hh:mm:ss A'), styles: { fontStyle: 'normal' } },
                { content: moment.unix(data.atts).format('dddd'), styles: { fontStyle: 'normal' } },
                { content: data.completedts && moment.unix(data.completedts).format('dddd, D MMM YYYY hh:mm:ss A'), styles: { fontStyle: 'normal' } },
                { content: supplierName, styles: { fontStyle: 'normal' } },
                { content: data.amount + ' ' + currencyName, styles: { fontStyle: 'normal' } },
                { content: data.status === 'pending' ? (isDue ? 'pending' : 'not due') : (data.status === 'received' ? 'received' : ''), styles: { fontStyle: 'bold' } }
              ];
            }
          })

          doc.autoTable({
            head: [tableColumn],
            body: tableData,
            startY: currentY,
            theme: 'grid',
            headStyles: {
              fillColor: [200, 200, 200],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 10,
              font: 'notoarabic',
            },
            columnStyles: {
              0: { halign: 'left' },
              1: { halign: 'left' },
              1: { halign: 'left' },
              1: { halign: 'left' },
              1: { halign: 'left' },
            },
            margin: {
              top: 10,
              left: 10,
              right: 10
            },
          });
          currentY = doc.lastAutoTable.finalY + 10;
        }

        const fileName = 'Overall Report ' + projectName + '.pdf';
        doc.save(fileName.replace(/\s+/g, '_').toLowerCase());
        setResultpdf("Saved Successfully");
      } catch (error) {
        console.error('Error generating PDF:', error);
        setResultpdf("Error Saving PDF");
      } finally {
        setLoadingpdf(false);
      }
    };

    function OverallReportCashAccounts() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Cash Accounts</div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {cashaccountsdata.map((account, index) => {
                return (
                  <tr key={"account" + index} className="reports_tableaccount" >
                    <td>
                      <div>{account.name}</div>
                    </td>
                    <td>
                      <div>
                        {account.currency + ' ' + account.balance.toLocaleString("en-US")}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportCashFlowStatement() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Cash Flow Statement</div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                cashflowdata.map((account, index) => {
                  if (account.type === "section") {
                    return (
                      <tr key={"account" + index}>
                        <td colSpan={2}>
                          <div className="reports_tablesection">{account.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "accountinflows") {
                    return (
                      <tr key={"account" + index} className="reports_tableaccount">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "accountoutflows") {
                    return (
                      <tr key={"account" + index} className="reports_tableaccount">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "grouptotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "sectiontotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency + ' ' + account.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportActualVsBudget() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Actual vs Budget</div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actual</th>
                <th>Budget</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {
                actualbudgetdata.map((data, index) => {
                  if (data.type === "section") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablesection">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "group") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablegroup">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "grouptotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "account") {
                    if (data.section === "income") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.section === "expense") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                  }
                  if (data.type === "sectiontotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportClientBalances() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Client Balances</div>
          <table className="reports_table">
            <thead>
              <tr>
                {
                  filteredclientscolumnheader.map((headeritem, index) => {
                    return <th key={"headeritem" + index}>
                      {headeritem}
                    </th>;
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                filteredclientscolumnrows.map((row, index) => {
                  return (
                    <tr key={"row" + index}>
                      {row?.columns.map((column, index) => (
                        <td key={"column" + index}>
                          <div>{column}</div>
                        </td>
                      ))}
                    </tr>
                  );
                })
              }
            </tbody>
            <tfoot>
              <tr>
                {filteredclientscolumnfooter.map((footerItem, index) => (
                  <td key={"footer" + index}>
                    <strong>{footerItem}</strong>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      )
    }

    function OverallReportCollections() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Collections</div>
          <table className='reports_table'>
            <thead>
              <tr>
                <th>Created</th>
                <th>Due</th>
                <th>Completed</th>
                <th>Client</th>
                <th>Note</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                collections.map((collection, index) => {
                  const isDue = moment().unix() < moment.unix(collection.atts).endOf('day').unix();
                  if (collection.type == 'receipt') {
                    const clientName = project.clients.find((client) => client.id === collection.clientid)?.name;
                    const currencyName = project.currencies.find((currency) => currency.id === collection.currencyid)?.name;
                    return (
                      <tr key={'collection' + index} className='collections_receipt'>
                        <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                        <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                        <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                        <td><div>{clientName}</div></td>
                        <td><div>{collection.note}</div></td>
                        <td><div>{collection.amount} {currencyName}</div></td>
                        {collection.status === 'pending' && isDue && <td><div className='collections_statuspending'>pending</div></td>}
                        {collection.status === 'pending' && !isDue && <td><div className='collections_statusnotdue'>not due</div></td>}
                        {collection.status === 'received' && <td><div className='collections_statusreceived'>received</div></td>}
                      </tr>
                    );
                  }
                  if (collection.type == 'payment') {
                    const supplierName = project.suppliers.find((supplier) => supplier.id === collection.supplierid)?.name;
                    const currencyName = project.currencies.find((currency) => currency.id === collection.currencyid)?.name;
                    return (
                      <tr key={'collection' + index} className='collections_payment'>
                        <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                        <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                        <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                        <td><div>{supplierName}</div></td>
                        <td><div>{collection.note}</div></td>
                        <td><div>{collection.amount} {currencyName}</div></td>
                        {collection.status === 'pending' && isDue && <td><div className='collections_statuspending'>pending</div></td>}
                        {collection.status === 'pending' && !isDue && <td><div className='collections_statusnotdue'>not due</div></td>}
                        {collection.status === 'received' && <td><div className='collections_statusreceived'>received</div></td>}
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportsContracts() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Contracts</div>
          <table className='reports_table'>
            <thead>
              <tr>
                <th>Supplier</th>
                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                project.contracts.map((contract, index) => {
                  let from = moment.unix(contract.from).format('D/MMM/YYYY');
                  let to = moment.unix(contract.to).format('D/MMM/YYYY');
                  let supplierName = project.suppliers.find(supplier => supplier.id === contract.supplierid)?.name || 'Supplier not found';
                  let amount = contract.amount;
                  let currency = project.currencies.find((currency) => currency.id === contract.currencyid)?.symbol || '';

                  return (
                    <tr key={'contract' + index}>
                      <td><div>{supplierName}</div></td>
                      <td><div>{contract.name}</div></td>
                      <td><div>{from}</div></td>
                      <td><div>{to}</div></td>
                      <td><div>{currency} {amount.toLocaleString('en-US')}</div></td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportsPayables() {
      const suppliersdata = getSupplierAccounts(project.suppliers, project.transactions, project.purchaseinvoices, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Payables</div>
          <table className='reports_table'>
            <thead><tr><th>Name</th><th>Balance</th></tr></thead>
            <tbody>
              {
                suppliersdata.map((supplier, index) => {

                  return (
                    <tr key={'supplier' + index}>
                      <td><div>{supplier.name}</div></td>
                      <td>
                        <div>{supplier.currency} {supplier.balance.toLocaleString('en-US')}</div>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportInspections() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Inspections</div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Supplier</th>
                <th>Asset</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {
                project.inspections
                  .sort((a, b) => b.ts - a.ts)
                  .map((inspection, index) => {
                    let inspectionType
                    if (inspection.type == 'supplier') inspectionType = 'Supplier';
                    if (inspection.type == 'local') inspectionType = 'Local';
                    let supplierName = project.suppliers.find(
                      (supplier) => supplier.id == inspection.supplierid
                    )?.name;
                    let assetName = project.assets.find(
                      (asset) => asset.id == inspection.assetid
                    )?.name;

                    return (
                      <tr key={"inspection" + index}>
                        <td>
                          <div>
                            {moment.unix(inspection.ts).format("D/MMM/YYYY")}
                          </div>
                        </td>
                        <td>
                          <div>{inspectionType}</div>
                        </td>
                        <td>
                          <div>{supplierName}</div>
                        </td>
                        <td>
                          <div>{assetName}</div>
                        </td>
                        <td>
                          <div className="inspections_description">
                            {inspection.description}
                          </div>
                        </td>
                      </tr>
                    );
                  })
              }
            </tbody>
          </table>
        </div>
      )
    }

    function OverallReportTasks() {
      return (
        <div className="reports_section">
          <div className="reports_sectiontitle">Tasks</div>
          <table className='reports_table'>
            <thead>
              <tr>
                <th>Task</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                project.tasks
                  .sort((a, b) => {
                    const order = { "completed": 0, "pending": 1, "open": 2 };
                    return order[a.status] - order[b.status];
                  })
                  .map((task, index) => {
                    return (
                      <tr key={'task' + index}>
                        <td><div>{task.name}</div></td>
                        <td><div>{task.status}</div></td>
                      </tr>
                    );
                  })
              }
            </tbody>
          </table>
        </div>
      )
    }

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (<button className="reports_button">Print</button>)}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_buttonscontainer">
          <ul>
            {Object.entries(visibility).map(([key, { visible, name, disabled }]) => (
              <li key={key}>
                <label>
                  <input
                    type="checkbox"
                    checked={visible}
                    disabled={disabled}
                    onChange={() =>
                      !disabled &&
                      setVisibility((prevState) => ({
                        ...prevState,
                        [key]: { ...prevState[key], visible: !prevState[key].visible },
                      }))
                    }
                  />
                  {name}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className='reports_note'>
          Please make sure to complete the data entry before sending the report. By entering all contracts data, reconciling the cash accounts, making sure all inspections have been entered, and finalizing the budget chart with their corresponding estimated budget. Make sure to also enter all completed tasks.
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_title">Report</div>
          <div className="reports_projectname">{project.projectdetails}</div>
          <div className="reports_dateframe">{moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}</div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Table of Contents</div>

            <ul>
              {Object.entries(visibility)
                .filter(([_, value]) => value.visible)
                .map(([key, value]) => (
                  <li key={key}>{value.name}</li>
                ))}
            </ul>
          </div>

          {visibility.cashaccounts.visible && <OverallReportCashAccounts />}
          {visibility.cashflowstatement.visible && <OverallReportCashFlowStatement />}
          {visibility.actualvsbudget.visible && <OverallReportActualVsBudget />}
          {visibility.clientbalances.visible && <OverallReportClientBalances />}
          {visibility.collections.visible && <OverallReportCollections />}
          {visibility.contracts.visible && <OverallReportsContracts />}
          {visibility.payables.visible && <OverallReportsPayables />}
          {visibility.inspections.visible && < OverallReportInspections />}
          {visibility.tasks.visible && <OverallReportTasks />}
        </div>
      </div>
    );
  }

  return (
    <div className="reports">
      <Title text="reports" />

      <div className="reports_row">
        <div className="reports_rowsection">From</div>
        <div className="reports_rowsection">
          <DatePicker
            className="reports_datepicker"
            selected={from}
            onChange={(date) => setFrom(date)}
          />
        </div>
        <div className="reports_rowsection">To</div>
        <div className="reports_rowsection">
          <DatePicker
            className="reports_datepicker"
            selected={to}
            onChange={(date) => setTo(date)}
          />
        </div>
      </div>
      <div className="reports_buttonscontainer">
        <button className="reports_button" onClick={handleActualvsbudget}>
          Actual vs Budget
        </button>
        <button className="reports_button" onClick={handleCashFlow}>
          Cash Flow Statement
        </button>
        <button className="reports_button" onClick={handleReceiptsReport}>
          Receipts Report
        </button>
        <button className="reports_button" onClick={handleExpensesReport}>
          Expenses Report
        </button>
        <button className="reports_button" onClick={handleClientBalances}>
          Client Balances
        </button>
        <button className="reports_button" onClick={handleAgedPayables}>
          Aged Payables
        </button>
        <button className="reports_button" onClick={handleOverallReport}>
          Overall Report
        </button>
      </div>

      {option}

    </div>
  );
}

export default Reports;
