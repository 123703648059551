import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Box, Text, OrbitControls, TransformControls } from '@react-three/drei';
import { Asset } from './asset.js';

export const elementTypes = [
    { id: "room", name: "Room" },
    { id: "floor", name: "Floor" },
    { id: "groundfloor", name: "Ground Floor" },
    { id: "lift", name: "Lift" },
    { id: "block", name: "Block" },
    { id: "plot", name: "Plot" },
    { id: "landscape", name: "Landscape" },
    { id: "parking", name: "Parking" },
    { id: "flat", name: "Flat" },
    { id: "stairs", name: "Stairs" },
    { id: "basement", name: "Basement" },
    { id: "lobby", name: "Lobby" },
    { id: "roof", name: "Roof" }
];

const styleConfig = {
    room: {
        color: "#A9A9A9",
        opacity: 0.3,
        wireframe: false,
        reflectivity: 0.2
    },
    floor: {
        color: "#ADD8E6",
        opacity: 0.1,
        wireframe: false
    },
    groundfloor: {
        color: "#ADD8E6",
        opacity: 0.1,
        wireframe: false
    },
    lobby: {
        color: "#ADD8E6",
        opacity: 0.15,
        wireframe: true
    },
    flat: {
        color: "#ADD8E6",
        opacity: 0.2,
        wireframe: true
    },
    block: {
        color: "#FFFFFF",
        opacity: 0.05,
        wireframe: true
    },
    lift: {
        color: "#4CAF50",
        opacity: 0.5,
        wireframe: true
    },
    stairs: {
        color: "#B0BEC5",
        opacity: 0.2,
        wireframe: true
    },
    plot: {
        color: "#90EE90",
        opacity: 0.1,
        wireframe: false
    },
    landscape: {
        color: "#228B22",
        opacity: 0.3,
        wireframe: true
    },
    parking: {
        color: "#808080",
        opacity: 0.3,
        wireframe: true
    },
    basement: {
        color: "#D3D3D3",
        opacity: 0.1,
        wireframe: true
    },
    roof: {
        color: "#D3D3D3",
        opacity: 0.05,
        wireframe: true
    }
};

export function Element({ element, wireframe }) {
    const position = [
        parseFloat(element.px) || 0,
        parseFloat(element.py) || 0,
        parseFloat(element.pz) || 0
    ];

    const size = [
        parseFloat(element.sx) || 0,
        parseFloat(element.sy) || 0,
        parseFloat(element.sz) || 0
    ];

    const style = styleConfig[element.type] ?? {
        color: "steelblue",
        opacity: 0.1,
        wireframe: true
    };

    return (
        <>
            <mesh>
                <Box position={position} args={size}>
                    <meshStandardMaterial
                        attach="material"
                        color={style.color}
                        opacity={style.opacity}
                        transparent={style.opacity < 1}
                        wireframe={wireframe || style.wireframe}
                        shininess={style.shininess ?? 0}
                        roughness={style.roughness ?? 1}
                        reflectivity={style.reflectivity ?? 0}
                    />
                </Box>
            </mesh>

            <Text
                position={[
                    position[0],
                    position[1] + size[1] / 2 + 0.5,
                    position[2]
                ]}
                fontSize={1.2}
                color="white"
                anchorX="center"
                anchorY="middle"
            >
                {element.name}
            </Text>
        </>
    );
}

export function ElementPreview({ element }) {
    return (
        <Canvas camera={{ position: [10, 10, 10], fov: 50 }}>
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} intensity={1} />
            <pointLight position={[10, 10, 10]} />
            <OrbitControls />

            <Element element={element} />
        </Canvas>
    );
}

export function ElementPreviewAssetsAsset({ element, assets = [], asset }) {
    const [controlsEnabled, setControlsEnabled] = useState(true);

    return (
        <Canvas camera={{ position: [10, 10, 10], fov: 50 }}>
            <ambientLight intensity={1.8} />
            <directionalLight position={[10, 15, 10]} intensity={3} color="#FFFFFF" />
            <pointLight position={[15, 15, 15]} intensity={5} color="#FFFFAA" />
            <OrbitControls enabled={controlsEnabled} />

            <Element
                element={{
                    id: element.id,
                    type: element.type,
                    name: element.name,
                    px: 0,
                    py: 0,
                    pz: 0,
                    sx: element.sx,
                    sy: element.sy,
                    sz: element.sz,
                    rotation: 0
                }}
                wireframe={true}
            />

            {
                assets.map((asset, assetIndex) => {
                    return (
                        <Asset key={'asset' + assetIndex} asset={{
                            type: asset.type,
                            option: asset.option,
                            name: asset.name,
                            px: 0 + (parseFloat(asset.px) || 0),
                            py: 0 + (parseFloat(asset.py) || 0),
                            pz: 0 + (parseFloat(asset.pz) || 0),
                            rotation: asset.rotation
                        }} />
                    );
                })
            }

            {
                asset && (
                    <TransformControls
                        onMouseDown={() => setControlsEnabled(false)}
                        onMouseUp={() => setControlsEnabled(true)}
                    >
                        <Asset asset={{
                            type: asset.type,
                            option: asset.option,
                            name: asset.name,
                            px: 0 + (parseFloat(asset.px) || 0),
                            py: 0 + (parseFloat(asset.py) || 0),
                            pz: 0 + (parseFloat(asset.pz) || 0),
                            rotation: asset.rotation
                        }} />
                    </TransformControls>
                )
            }
        </Canvas>
    );
}