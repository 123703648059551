import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import { Asset } from './Utils/asset.js';
import { elementTypes, Element, ElementPreview } from './Utils/structure.js';
import { getPermission } from './Utils/permissions.js';
import { assetsremovalprotection } from './Utils/removeprotection.js';
import Select from './Components/select.js';

import axios from 'axios';
import configData from './Config';

import Title from './Title.js';

import './Structure.css';

function Structure({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [structure, setStructure] = useState([]);

    const [assets, setAssets] = useState(true);
    const [invisible, setInvisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const canvasRef = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (project.structure.length == 0) {
            console.log('Trying to get structure');

            setLoading(true)
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETSTRUCTURE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get structure data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setStructure(res.data)
                    dispatch({
                        type: 'ADD_STRUCTURE',
                        structure: res.data
                    });
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            console.log('Structure found in state')
            setStructure(project.structure)
        }
    }, []);

    useEffect(() => {
        if (project.assets.length == 0) {
            console.log('Trying to get assets');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get assets data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_ASSETS',
                        assets: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Assets found in state')
        }
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addElementsButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddElement />, modaltype: 'large', modaltitle: 'Add Element' }));
    }

    const editElementButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditElement />, modaltype: 'large', modaltitle: 'Edit Element' }));
    }

    const removeElementButton = (element) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveElement element={element} />, modaltype: 'small', modaltitle: 'Remove Element' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const screenshot = () => { // not working
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const screenshotUrl = canvas.toDataURL('image/png');

            const link = document.createElement('a');
            link.href = screenshotUrl;
            link.download = '3d_structure.png';
            link.click();
        }
    };

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (structure.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(structure, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);
        const [importType, setImporttype] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                setImporttype('json');
                const requiredAttributes = ["id", "name", "px", "py", "pz", "sx", "sy", "sz", "type"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = project.structure.length
                        ? data.filter(entry => project.structure.some(el => el.id === entry.id))
                        : data;

                    console.log(newEntries);

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true)
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                structure: newEntries,
                type: importType
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTSTRUCTURE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setStructure(newEntries)
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddElement() {
        const [structurepreview, setStructurePreview] = useState([]);

        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [px, setPx] = useState(0);
        const [py, setPy] = useState(0);
        const [pz, setPz] = useState(0);
        const [sx, setSx] = useState(10);
        const [sy, setSy] = useState(10);
        const [sz, setSz] = useState(10);
        const [rotation, setRotation] = useState(0);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            const newElement = {
                type,
                name: name.trim(),
                px,
                py,
                pz,
                sx,
                sy,
                sz,
                rotation,
            };

            const updatedPreview = [...structure, newElement];
            setStructurePreview(updatedPreview);
        }, [type, name, px, py, pz, sx, sy, sz, rotation, structure]);

        const handleSubmit = () => {
            console.log('Trying to add element');

            setResult(null);

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Structure',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
                type: type,
                name: trimmedName,
                px: px,
                py: py,
                pz: pz,
                sx: sx,
                sy: sy,
                sz: sz,
                rotation: rotation,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDELEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add element data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        data.id = res.data.id;
                        setStructure([...structure, data])
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={elementTypes} itemid={type} setItemid={setType} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Element Name"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Position</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position X"
                                value={px}
                                onChange={(e) => setPx(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Y"
                                value={py}
                                onChange={(e) => setPy(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Position Z"
                                value={pz}
                                onChange={(e) => setPz(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Size</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Size X"
                                value={sx}
                                onChange={(e) => setSx(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Size Y"
                                value={sy}
                                onChange={(e) => setSy(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Size Z"
                                value={sz}
                                onChange={(e) => setSz(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Rotation</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Rotation"
                                value={rotation}
                                onChange={(e) => setRotation(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <ElementPreview element={{ type: type, name: name, px: px, py: py, pz: pz, sx: sx, sy: sy, sz: sz, rotation: rotation }} />
                        </div>
                        <div className='modal_rowsection'>
                            <Canvas camera={{ position: [20, 20, 20], fov: 50 }}>
                                <ambientLight intensity={0.5} />
                                <FirstPersonControls />
                                <OrbitControls enableZoom={true} enablePan={true} />
                                {
                                    structurepreview.map((element, index) => {
                                        return (
                                            <React.Fragment key={'structure' + index}>
                                                <Element element={element} />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </Canvas>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button
                                className="modal_button"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Add'}
                            </button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function EditElement() {
        const [structurepreview, setStructurePreview] = useState(structure);

        const [id, setId] = useState('');
        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [px, setPx] = useState(0);
        const [py, setPy] = useState(0);
        const [pz, setPz] = useState(0);
        const [sx, setSx] = useState(0);
        const [sy, setSy] = useState(0);
        const [sz, setSz] = useState(0);
        const [rotation, setRotation] = useState(0);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            setStructurePreview((prevStructure) => {
                return prevStructure.map((element) =>
                    element.id === id
                        ? {
                            ...element,
                            type,
                            name: name.trim(),
                            px,
                            py,
                            pz,
                            sx,
                            sy,
                            sz,
                            rotation,
                        }
                        : element
                );
            });
        }, [id, type, name, px, py, pz, sx, sy, sz, rotation]);

        const editElement = (element) => {
            setId(element.id);
            setType(element.type || '');
            setName(element.name || '');
            setPx(element.px ?? 0);
            setPy(element.py ?? 0);
            setPz(element.pz ?? 0);
            setSx(element.sx ?? 0);
            setSy(element.sy ?? 0);
            setSz(element.sz ?? 0);
            setRotation(element.rotation ?? 0);
        };

        const updateElement = () => {
            console.log('Trying to update element');

            setResult(null);

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Structure',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
                id: id,
                type: type,
                name: trimmedName,
                px: px,
                py: py,
                pz: pz,
                sx: sx,
                sy: sy,
                sz: sz,
                rotation: rotation,
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEELEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update element data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setStructure(
                            structure.map(el =>
                                el.id == data.id ? data : el
                            )
                        )
                        setId('');
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='structure_viewpanel'>
                        <table className="structure_viewtable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {structure.map((element, index) => {
                                    return (
                                        <React.Fragment key={'element' + index}>
                                            <tr>
                                                <td>
                                                    <button className='modal_button' onClick={() => editElement(element)}>Edit</button>
                                                </td>
                                                <td><div>{element.id}</div></td>
                                                <td><div>{element.name}</div></td>
                                                <td><div>{element.type}</div></td>
                                            </tr>

                                            {id === element.id && (
                                                <>
                                                    <tr className="edit_row">
                                                        <td>Id</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="text"
                                                                value={id || ''}
                                                                onChange={(e) => setId(e.target.value)}
                                                                placeholder="Id"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Name</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="text"
                                                                value={name || ''}
                                                                onChange={(e) => setName(e.target.value)}
                                                                placeholder="Name"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Type</td>
                                                        <td colSpan="9">
                                                            <select className="modal_select" value={type} onChange={(e) => setType(e.target.value)}>
                                                                <option value="">Select Type</option>
                                                                {elementTypes.map((type) => (
                                                                    <option key={type.id} value={type.id}>
                                                                        {type.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Position X</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={px || 0}
                                                                onChange={(e) => setPx(e.target.value)}
                                                                placeholder="Position X"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Position Y</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={py || 0}
                                                                onChange={(e) => setPy(e.target.value)}
                                                                placeholder="Position Y"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Position Z</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={pz || 0}
                                                                onChange={(e) => setPz(e.target.value)}
                                                                placeholder="Position Z"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Size X</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={sx || 1}
                                                                onChange={(e) => setSx(e.target.value)}
                                                                placeholder="Size X"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Size Y</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={sy || 1}
                                                                onChange={(e) => setSy(e.target.value)}
                                                                placeholder="Size Y"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Size Z</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={sz || 1}
                                                                onChange={(e) => setSz(e.target.value)}
                                                                placeholder="Size Z"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td>Rotation</td>
                                                        <td colSpan="9">
                                                            <input
                                                                className="modal_input"
                                                                type="number"
                                                                value={rotation || 0}
                                                                onChange={(e) => setRotation(e.target.value)}
                                                                placeholder="Rotation"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="edit_row">
                                                        <td></td>
                                                        <td colSpan="9">
                                                            {result && <div className='modal_result'>{result}</div>}
                                                            <button className='modal_button' onClick={() => updateElement(element.id)}>Update</button>
                                                            <button className='modal_button' onClick={() => removeElementButton(element)}>Remove</button>
                                                            <button className='modal_button' onClick={() => setId(null)}>Cancel</button>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='structure_viewmodel'>
                        <Canvas camera={{ position: [20, 20, 20], fov: 50 }}>
                            <ambientLight intensity={0.5} />
                            <FirstPersonControls />
                            <OrbitControls enableZoom={true} enablePan={true} />
                            {
                                structurepreview.map((element, index) => {
                                    const relatedAssets = project.assets.filter(asset => asset.elementid == element.id);

                                    return (
                                        <React.Fragment key={'structure' + index}>
                                            <Element
                                                element={element}
                                                wireframe={invisible}
                                            />
                                            {
                                                assets &&
                                                relatedAssets.map((asset, assetIndex) => {
                                                    const assetPosition = [
                                                        (parseFloat(element.px) || 0) + (parseFloat(asset.px) || 0),
                                                        (parseFloat(element.py) || 0) + (parseFloat(asset.py) || 0),
                                                        (parseFloat(element.pz) || 0) + (parseFloat(asset.pz) || 0),
                                                    ];

                                                    return (
                                                        <Asset key={'asset' + assetIndex} asset={asset} position={assetPosition} />
                                                    );
                                                })
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                        </Canvas>
                    </div>
                </div>
            </div>
        );
    }

    const FirstPersonControls = () => {
        const [movement, setMovement] = useState({ forward: false, backward: false });
        const [speed, setSpeed] = useState(0.1);

        const camera = useThree((state) => state.camera);
        const { forward, backward } = movement;

        useEffect(() => {
            const handleKeyDown = (e) => {
                if (e.key === 'w' || e.key === 'ArrowUp') {
                    setMovement((prev) => ({ ...prev, forward: true }));
                }
                if (e.key === 's' || e.key === 'ArrowDown') {
                    setMovement((prev) => ({ ...prev, backward: true }));
                }
            };

            const handleKeyUp = (e) => {
                if (e.key === 'w' || e.key === 'ArrowUp') {
                    setMovement((prev) => ({ ...prev, forward: false }));
                }
                if (e.key === 's' || e.key === 'ArrowDown') {
                    setMovement((prev) => ({ ...prev, backward: false }));
                }
            };

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            };
        }, []);

        useFrame(() => {
            if (forward) {
                camera.position.z -= speed;
            }
            if (backward) {
                camera.position.z += speed;
            }
        });

        return null;
    };

    function RemoveElement({ element }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(element.id)
        }, [element]);

        const removeElement = () => {
            console.log('Trying to remove element');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Structure',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const assetsData = assetsremovalprotection(project.assets, id, 'structure');
            if (assetsData.code) {
                setResult(assetsData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEELEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove element data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setStructure(
                            structure.filter(el => el.id !== id)
                        )
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeElement()} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='structure'>
            <Title text='Structure' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={() => addElementsButton()}>Add Element</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => editElementButton()}>Edit Element</button>
                </div>
                <div className='buttonsection'>
                    <div className='button'>
                        <label>
                            <input
                                type="checkbox"
                                checked={assets}
                                onChange={() => setAssets(!assets)}
                            />
                            Assets
                        </label>
                    </div>
                </div>
                <div className='button'>
                    <label>
                        <input
                            type="checkbox"
                            checked={invisible}
                            onChange={() => setInvisible(!invisible)}
                        />
                        Invisible
                    </label>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={screenshot}>Screenshot</button>
                </div>
            </div>

            <div className='structure_canvas'>
                <Canvas ref={canvasRef} camera={{ position: [20, 20, 20], fov: 50 }}>
                    <ambientLight intensity={0.5} />
                    <FirstPersonControls />
                    <OrbitControls enableZoom={true} enablePan={true} />
                    {
                        structure.map((element, index) => {
                            const relatedAssets = project.assets.filter(asset => asset.elementid == element.id);

                            return (
                                <React.Fragment key={'structure' + index}>
                                    <Element
                                        element={element}
                                        wireframe={invisible}
                                    />
                                    {
                                        assets &&
                                        relatedAssets.map((asset, assetIndex) => {
                                            return (
                                                <Asset
                                                    key={'asset' + assetIndex}
                                                    asset={{
                                                        id: asset.id,
                                                        type: asset.type,
                                                        option: asset.option,
                                                        name: asset.name,
                                                        px: (parseFloat(element.px) || 0) + (parseFloat(asset.px) || 0),
                                                        py: (parseFloat(element.py) || 0) + (parseFloat(asset.py) || 0),
                                                        pz: (parseFloat(element.pz) || 0) + (parseFloat(asset.pz) || 0),
                                                        rotation: asset.rotation
                                                    }}
                                                    inspections={project.inspections}
                                                    tasks={project.tasks}
                                                />
                                            );
                                        })
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </Canvas>
            </div>
        </div>
    );
}

export default Structure;