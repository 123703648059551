import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import Select from './Components/select.js';
import Title from './Title';
import { numberToWords, parseAmount } from './Utils/textparse.js';
import { getPermission } from './Utils/permissions.js';

import './Transactions.css';

function Transactions({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredtransactions, setFilteredTransactions] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedtransactions, setSelectedtransactions] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(selectedtransactions)
    }, [selectedtransactions]);


    useEffect(() => {
        const filtered = project.transactions.filter((transaction) =>
            transaction.name.toLowerCase().includes(search.toLowerCase()) ||
            (project.clients.find(item => item.id === transaction.from)?.name.toLowerCase().includes(search.toLowerCase())) ||
            transaction.id.toLowerCase().includes(search.toLowerCase()) ||
            transaction.amount.includes(search)
        );
        setFilteredTransactions(filtered);
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addReceiptButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddReceipt />, modaltype: 'large', modaltitle: 'Add Receipt' }));
    }

    const addPaymentButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddPayment />, modaltype: 'large', modaltitle: 'Add Payment' }));
    }

    const editPaymentButton = (transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditPayment transaction={transaction} />, modaltype: 'large', modaltitle: 'Edit Payment' }));
    }

    const editReceiptButton = (transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditReceipt transaction={transaction} />, modaltype: 'large', modaltitle: 'Edit Receipt' }));
    }

    const viewReceiptButton = (transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewReceipt transaction={transaction} />, modaltype: 'large', modaltitle: 'View Receipt' }));
    }

    const viewPaymentButton = (transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewPayment transaction={transaction} />, modaltype: 'large', modaltitle: 'View Payment' }));
    }

    const removeButton = (transactions, transaction) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveTransaction transactions={transactions} transaction={transaction} />, modaltype: 'small', modaltitle: 'Remove Transaction' }));
    }

    const viewTransactions = (transactions) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewTransactions transactions={transactions} />, modaltype: 'large', modaltitle: 'View Transactions' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!selectedtransactions.length) {
                setResult('No data selected for export');
                return;
            }

            const exportedData = JSON.stringify(
                project.transactions.filter(t => selectedtransactions.includes(t.id)),
                null,
                2
            );

            navigator.clipboard.writeText(exportedData)
                .then(() => setResult('Selected data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ' + err));
        }, [selectedtransactions]);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [importType, setImporttype] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                setImporttype('json');
                const requiredAttributes = ["id", "ts", "name", "from", "subaccountid", "to", "beneficiaryid", "ts", "amount", "currencyid"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.transactions.some(trans => trans.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                const requiredAttributes = ["Type", "Date", "Name", "From", "To", "Subaccount", "Supplier", "Amount"];
                let rows = importText.split('\n').map(row => row.split('\t'));
                const headers = rows.shift().map(header => header.trim());

                const missingHeaders = requiredAttributes.filter(attr => !headers.includes(attr));

                if (headers.length === 0 || headers.length !== requiredAttributes.length || missingHeaders.length > 0) {
                    setResult(`Invalid headers detected. Missing headers: ${missingHeaders.join(', ')}`);
                    return;
                }

                if (rows.length === 0) {
                    setResult('No data rows detected.');
                    return;
                }

                data = rows.map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index]?.trim() || '';
                    });
                    return entry;
                });

                const convertedEntries = [];

                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    let convertedEntry = {};

                    const matchingSubaccount = project.subaccounts.find(subaccount => subaccount.name.toLowerCase() === entry['Subaccount'].toLowerCase());
                    if (!matchingSubaccount) {
                        setResult("Subaccount not found for \"" + entry['Subaccount'] + "\" at row " + i);
                        break;
                    }

                    const parsedAmount = parseAmount(entry['Amount']);
                    console.log(entry['Amount'])
                    console.log(parsedAmount)
                    if (!parsedAmount.currency) {
                        console.log("Currency not found in amount:", parsedAmount.amount);
                        break;
                    }
                    const matchingCurrency = project.currencies.find(currency =>
                        currency.name.toLowerCase() === parsedAmount.currency.toLowerCase() ||
                        currency.symbol.toLowerCase() === parsedAmount.currency.toLowerCase()
                    );
                    if (!matchingCurrency) {
                        setResult("Currency not found for \"" + parsedAmount.currency + "\" at row " + i);
                        break;
                    }

                    if (entry['Type'].toLowerCase() === 'payment') {
                        const matchingCashAccount = project.cashaccounts.find(account => account.name.toLowerCase() === entry['From'].toLowerCase());
                        const matchingExpenseAccount = project.expenseaccounts.find(expenseaccount => expenseaccount.name.toLowerCase() === entry['To'].toLowerCase());
                        const matchingSupplier = project.suppliers.find(supplier => supplier.name.toLowerCase() === entry['Supplier'].toLowerCase());

                        if (!matchingCashAccount) {
                            setResult("Cash account not found for \"" + entry['From'] + "\" at row " + i);
                            break;
                        }

                        if (!matchingExpenseAccount) {
                            setResult("Expense account not found for \"" + entry['To'] + "\" at row " + i);
                            break;
                        }

                        if (!matchingSupplier) {
                            setResult("Supplier not found for \"" + entry['Supplier'] + "\" at row " + i);
                            break;
                        }

                        convertedEntry = {
                            type: 'payment',
                            name: entry['Name'],
                            from: matchingCashAccount.id,
                            subaccountid: matchingSubaccount.id,
                            to: matchingExpenseAccount.id,
                            beneficiaryid: matchingSupplier.id,
                            ts: Math.floor(new Date(entry['Date']).getTime() / 1000),
                            amount: parsedAmount.amount,
                            currencyid: matchingCurrency.id
                        };
                    }
                    if (entry['Type'].toLowerCase() === 'receipt') {
                        const matchingClient = project.clients.find(client => client.name.toLowerCase() === entry['From'].toLowerCase());
                        const matchingCashAccount = project.cashaccounts.find(account => account.name.toLowerCase() === entry['To'].toLowerCase());

                        if (!matchingClient) {
                            setResult("Client not found for " + entry['From'] + " at row " + (i + 1));
                            break;
                        }

                        if (!matchingCashAccount) {
                            setResult("Cash account not found for \"" + entry['To'] + "\" at row " + (i + 1));
                            break;
                        }

                        convertedEntry = {
                            type: 'receipt',
                            name: entry['Name'],
                            from: matchingClient.id,
                            subaccountid: matchingSubaccount.id,
                            to: matchingCashAccount.id,
                            beneficiaryid: '',
                            ts: Math.floor(new Date(entry['Date']).getTime() / 1000),
                            amount: parsedAmount.amount,
                            currencyid: matchingCurrency.id
                        };
                    }

                    convertedEntries.push(convertedEntry);
                }

                if (convertedEntries.length === data.length) {
                    setNewEntries(convertedEntries);
                    setResult(`Data is valid. Entries found: ${convertedEntries.length}`);
                }
            }

        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                transactions: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTTRANSACTIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_TRANSACTIONS',
                            transactions: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Import successful');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddReceipt() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addReceipt = (ts, name, from, subaccountid, to, amount, currencyid) => {
            console.log('Trying to add transaction');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name || !selectedclient || !subaccountid || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccountid: subaccountid,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.clients} itemid={selectedclient} setItemid={(clientId) => setSelectedclient(clientId)} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addReceipt(timestamp, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddPayment() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addPayment = (ts, name, from, to, supplier, subaccountid, amount, currencyid) => {
            console.log('Trying to add transaction');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name || !selectedaccount || !selectedexpense || !selectedsupplier || !subaccountid || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccountid: subaccountid,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Expense Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.expenseaccounts} itemid={selectedexpense} setItemid={setSelectedexpense} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={selectedsupplier} setItemid={setSelectedsupplier} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addPayment(timestamp, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReceipt({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setSelectedclient(transaction.from);
            setSelectedSubaccount(transaction.subaccountid);
            setSelectedaccount(transaction.to);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updateReceipt = (id, ts, name, from, subaccountid, to, amount, currencyid) => {
            console.log('Trying to update transaction');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name || !selectedclient || !subaccountid || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccountid: subaccountid,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Client</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.clients} itemid={selectedclient} setItemid={(clientId) => setSelectedclient(clientId)} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateReceipt(id, ts, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton([],transaction)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPayment({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setSelectedaccount(transaction.from)
            setSelectedSubaccount(transaction.subaccountid);
            setSelectedexpense(transaction.to);
            setSelectedsupplier(transaction.beneficiaryid)
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updatePayment = (id, ts, name, from, to, supplier, subaccountid, amount, currencyid) => {
            console.log('Trying to update transaction');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name || !from || !to || !supplier || !subaccountid || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccountid: subaccountid,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Expense Account</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.expenseaccounts} itemid={selectedexpense} setItemid={setSelectedexpense} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Supplier</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.suppliers} itemid={selectedsupplier} setItemid={setSelectedsupplier} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Amount</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updatePayment(id, ts, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(transaction)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewReceipt = ({ transaction }) => {
        const [copy, setCopy] = useState(0);
        const printRef = useRef();

        useEffect(() => {
            setCopy(2)
        }, [transaction]);

        const saveAsPDF = () => {
            let clientName = project.clients.find((client) => client.id === transaction.from)?.name || '';
            const content = printRef.current;

            clientName = clientName.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                filename: 'receipt_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        }

        return (
            <div className='modal_body'>
                <div className='modal_row modal_center'>
                    <div className='modal_rowsection'>
                        <select className='modal_select' value={copy} onChange={(e) => setCopy(e.target.value)}>
                            <option value={2}>2 copy</option>
                            <option value={3}>3 copy</option>
                        </select>
                    </div>
                </div>
                <div className='modal_printable' ref={printRef}>
                    <div className='transactions_receipt'>
                        <div className='modal_row modal_center'>
                            <div className='modal_rowsection'>
                                COPY FOR CLIENT
                            </div>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>RECEIPT</div>
                            </div>
                            <div className='modal_rowsection'>
                                {project.projectdetails}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Receipt ID</b> {transaction.id}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Amount</b> --{project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}--
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>For</b> {transaction.name}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='transactions_signature'></div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_center'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                            </div>
                        </div>
                    </div>

                    <div className='transactions_separator'></div>

                    <div className='transactions_receipt'>
                        <div className='modal_row modal_center'>
                            <div className='modal_rowsection'>
                            </div>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>RECEIPT</div>
                            </div>
                            <div className='modal_rowsection'>
                                {project.projectdetails}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Receipt ID</b> {transaction.id}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>Amount</b> --{project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}--
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <b>For</b> {transaction.name}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='transactions_signature'></div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'></div>
                            <div className='modal_rowsection'>
                                <div className='modal_center'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                            </div>
                        </div>
                    </div>

                    <div className='transactions_separator'></div>

                    {
                        copy == 3 &&
                        <div className='transactions_receipt'>
                            <div className='modal_row modal_center'>
                                <div className='modal_rowsection'>

                                </div>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>RECEIPT</div>
                                </div>
                                <div className='modal_rowsection'>
                                    {project.projectdetails}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Receipt ID</b> {transaction.id}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Amount</b> --{project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}--
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>For</b> {transaction.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='transactions_signature'></div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_center'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={saveAsPDF}>Save as PDF</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    function ViewPayment({ transaction }) {
        const printRef = useRef();

        const saveAsPDF = () => {
            let supplierName = project.suppliers.find((supplier) => supplier.id === transaction.to)?.name || '';
            const content = printRef.current;

            supplierName = supplierName.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                filename: 'receipt_' + supplierName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row modal_center'>
                        <div className='modal_rowsection'>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>PAYMENT</div>
                        </div>
                        <div className='modal_rowsection'>{project.projectdetails}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>Payment ID</b> {transaction.id}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>Beneficiary</b> {project.suppliers.find(item => item.id === transaction.beneficiaryid)?.name || ''}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>Amount</b> --{project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}--
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>Payment to</b> {project.expenseaccounts.find(item => item.id === transaction.to)?.name || ''}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <b>Description</b> {transaction.name}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'></div>
                        <div className='modal_rowsection'>
                            <div className='transactions_signature'></div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'></div>
                        <div className='modal_rowsection modal_center'>
                            <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={saveAsPDF}>Save as PDF</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveTransaction({ transactions, transaction }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeTransaction = (id) => {
            console.log('Trying to remove transaction');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.REMOVETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TRANSACTION',
                            id: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        const removeTransactions = (ids) => {
            console.log('Trying to remove transactions');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Transactions',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ids: ids
            }
            
            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.REMOVETRANSACTIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TRANSACTIONS',
                            ids: ids
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {selectedtransactions.length > 0 && "Remove " + selectedtransactions.length + " transactions ?"}
                            {selectedtransactions.length == 0 && "Please select transactions."}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            {transaction && (<button className="modal_button" onClick={() => removeTransaction(transaction.id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>)}
                            {transactions.length > 0 && (<button className="modal_button" onClick={() => removeTransactions(transactions)} disabled={loading}>{loading ? 'Loading...' : 'Remove Selected'}</button>)}
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewTransactions({ transactions }) {
        const printRef = useRef();

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <table className='modal_table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>ID</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Name</th>
                                <th>Supplier</th>
                                <th>Amount</th>
                                <th>Subaccount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactions.sort((a, b) => b.ts - a.ts).map((transaction, index) => {
                                    if (transaction.type === 'receipt') {
                                        return (
                                            <tr key={'transaction' + index} className='transactions_receipt'>
                                                <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                                <td><div>{transaction.type}</div></td>
                                                <td><div>{transaction.id}</div></td>
                                                <td>
                                                    <div>{project.clients.find(item => item.id === transaction.from)?.name || ''}</div>
                                                </td>
                                                <td>
                                                    <div>{project.cashaccounts.find(item => item.id === transaction.to)?.name || ''}</div>
                                                </td>
                                                <td><div>{transaction.name}</div></td>
                                                <td><div></div></td>
                                                <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                                <td>{project.subaccounts.find(subac => subac.id === transaction.subaccountid)?.name}</td>
                                            </tr>
                                        )
                                    }
                                    if (transaction.type === 'payment') {
                                        return (
                                            <tr key={'transaction' + index} className='transactions_payment'>
                                                <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                                <td><div>{transaction.type}</div></td>
                                                <td><div>{transaction.id}</div></td>
                                                <td>
                                                    <div>{project.cashaccounts.find(item => item.id === transaction.from)?.name || ''}</div>
                                                </td>
                                                <td>
                                                    <div>{project.expenseaccounts.find(account => account.id === transaction.to)?.name || ''}</div>
                                                </td>
                                                <td><div>{transaction.name}</div></td>
                                                <td><div>{project.suppliers.find(supp => supp.id === transaction.beneficiaryid)?.name}</div></td>
                                                <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                                <td>{project.subaccounts.find(subac => subac.id === transaction.subaccountid)?.name}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const toggleSelection = (transactionId) => {
        setSelectedtransactions((prev) =>
            prev.includes(transactionId)
                ? prev.filter((id) => id !== transactionId)
                : [...prev, transactionId]
        );
    };
    const toggleSelectionAll = () => {
        setSelectedtransactions((prev) =>
            prev.length === filteredtransactions.length ? [] : filteredtransactions.map(t => t.id)
        );
    };

    return (
        <div className='transactions'>
            <Title text='Transactions' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addReceiptButton}>Receipt</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={addPaymentButton}>Payment</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewTransactions(project.transactions)}>View Transactions</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={() => removeButton(selectedtransactions, null)}>Remove</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectionAll()}
                                />
                            </th>
                            <th></th>
                            <th></th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>ID</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Name</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredtransactions.sort((a, b) => b.ts - a.ts).map((transaction, index) => {
                                if (transaction.type === 'receipt') {
                                    return (
                                        <tr key={'transaction' + index} className='transactions_receipt'>
                                            <td>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedtransactions.includes(transaction.id)}
                                                        onChange={() => toggleSelection(transaction.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td><div className='table_button' onClick={() => editReceiptButton(transaction)}>Edit</div></td>
                                            <td><div className='table_button' onClick={() => viewReceiptButton(transaction)}>View</div></td>
                                            <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                            <td><div>{transaction.type}</div></td>
                                            <td><div>{transaction.id}</div></td>
                                            <td>
                                                <div>{project.clients.find(item => item.id === transaction.from)?.name || ''}</div>
                                            </td>
                                            <td>
                                                <div>{project.cashaccounts.find(item => item.id === transaction.to)?.name || ''}</div>
                                            </td>
                                            <td><div>{transaction.name}</div></td>
                                            <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                        </tr>
                                    )
                                }
                                if (transaction.type === 'payment') {
                                    return (
                                        <tr key={'transaction' + index} className='transactions_payment'>
                                            <td>
                                                <div>
                                                    <input
                                                        className='table_checkbox'
                                                        type="checkbox"
                                                        checked={selectedtransactions.includes(transaction.id)}
                                                        onChange={() => toggleSelection(transaction.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td><div className='table_button' onClick={() => editPaymentButton(transaction)}>Edit</div></td>
                                            <td><div className='table_button' onClick={() => viewPaymentButton(transaction)}>View</div></td>
                                            <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                            <td><div>{transaction.type}</div></td>
                                            <td><div>{transaction.id}</div></td>
                                            <td>
                                                <div>{project.cashaccounts.find(item => item.id === transaction.from)?.name || ''}</div>
                                            </td>
                                            <td>
                                                <div>{project.expenseaccounts.find(account => account.id === transaction.to)?.name || ''}</div>
                                            </td>
                                            <td><div>{transaction.name}</div></td>
                                            <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Transactions;