import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { taskStatus } from './Utils/tasks.js';
import Select from './Components/select.js';

import { jsPDF } from "jspdf";
import { registerArabicFont } from "./Utils/registerarabicfont.js";

import './Inspections.css';

const compressImage = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 800;
            const maxHeight = 800;

            let { width, height } = img;
            if (width > maxWidth || height > maxHeight) {
                const scale = Math.min(maxWidth / width, maxHeight / height);
                width = width * scale;
                height = height * scale;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
            callback(compressedDataUrl);
        };
    };
    reader.readAsDataURL(file);
};

export function AddLocalInspection({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [selecteddate, setSelecteddate] = useState(new Date());
    const [timestamp, setTimestamp] = useState(0);
    const [remarks, setRemarks] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [welcomescreen, setWelcomescreen] = useState(true);

    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
        setRemarks([{ id: 1, description: '', assetid: '', elementid: '', picture: null, }]);
    }, []);

    useEffect(() => {
        setTimestamp(Math.floor(selecteddate.getTime() / 1000));
    }, [selecteddate]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const handleDescriptionChange = (event) => {
        const updatedRemarks = [...remarks];
        updatedRemarks[currentPage].description = event.target.value;
        setRemarks(updatedRemarks);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            compressImage(file, (compressedImage) => {
                const updatedRemarks = [...remarks];
                updatedRemarks[currentPage].picture = compressedImage;
                setRemarks(updatedRemarks);
            });
        }
    };

    const handleAssetChange = (selectedAssetId) => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (updatedRemarks[currentPage]) {
                updatedRemarks[currentPage].assetid = selectedAssetId;
            }
            return updatedRemarks;
        });
    };

    const handleElementChange = (selectedElementId) => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (updatedRemarks[currentPage]) {
                updatedRemarks[currentPage].elementid = selectedElementId;
            }
            return updatedRemarks;
        });
    };

    const handleNext = () => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (currentPage >= updatedRemarks.length - 1) {
                updatedRemarks.push({ id: updatedRemarks.length + 1, description: '', assetid: '', elementid: '', picture: null });
            }
            setCurrentPage(currentPage + 1);
            return updatedRemarks;
        });
    };

    const handleBack = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSubmit = () => {
        console.log('Submitting remarks:', remarks);
        setLoading(true);

        const formData = new FormData();
        formData.append('projectuserid', project.projectuserid);
        formData.append('projectid', project.projectid);
        formData.append('ts', timestamp);

        if (remarks.length == 0 || (remarks.length === 1 && Object.keys(remarks[0]).length === 0)) {
            setResult('No remarks found')
            return
        }

        const remarksArray = remarks.filter(r => r.description || r.picture).map(r => ({
            id: r.id,
            description: r.description || '',
            assetid: r.assetid,
            elementid: r.elementid,
            picture: r.picture || null,
        }));

        formData.append('remarks', JSON.stringify(remarksArray));

        axios.post(configData.CONTROLLERURL + configData.ADDLOCALINSPECTION, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken,
            },
        }).then((res) => {
            console.log('Received add local inspection data');
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code == 1) {
                    localStorage.removeItem('remarks');
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
            setProgress(100);
        });
    };

    const handleDiscard = () => {
        setRemarks([{ id: 1, description: '', picture: null }]);
        localStorage.removeItem('remarks');
    };

    const currentRemark = remarks[currentPage] || { description: '', picture: null };

    return (
        <div className="modal_body">
            <div className="modal_printable">
                {
                    welcomescreen ?
                        (
                            <div className='inspectionslocal_welcomescreen'>
                                <h2 className='inspectionslocal_welcomescreentitle'>Welcome to Local Inspections</h2>

                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        Assets Loaded: {project.assets.length}
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        Structure Elements Loaded: {project.structure.length}
                                    </div>
                                </div>

                                <div className='modal_actions'>
                                    <div className="modal_buttons">
                                        <div className='modal_buttoncontainer'>
                                            <button className="modal_button" onClick={() => setWelcomescreen(false)} disabled={(project.assets.length == 0 && project.structure.length == 0)}>
                                                {(project.assets.length == 0 && project.structure.length == 0) ? "No elements or assets available" : "START"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <div className='modal_label'>Remark {currentPage + 1}</div>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <textarea
                                            className='modal_textarea'
                                            value={currentRemark.description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Enter your remark here"
                                            style={{ display: 'block', marginBottom: '10px', width: '100%' }}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <input
                                            className='modal_inputfile'
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        {currentRemark.picture && (
                                            <img
                                                className='modal_picture'
                                                src={currentRemark.picture}
                                                alt="remark image"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <div className='modal_label'>Asset (Optional)</div>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <Select data={project.assets} itemid={currentRemark.assetid || ''} setItemid={handleAssetChange} />
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <div className='modal_label'>Element (Optional)</div>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <Select data={project.structure} itemid={currentRemark.elementid || ''} setItemid={handleElementChange} />
                                    </div>
                                </div>
                                <div className='modal_actions'>
                                    <div className="modal_buttons">
                                        <div className='modal_buttoncontainer'>
                                            <button className="modal_button" onClick={handleBack} disabled={currentPage === 0}>Back</button>
                                        </div>
                                        <div className='modal_buttoncontainer'>
                                            <button className="modal_button" onClick={handleNext}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                }
            </div>
            <div className='modal_actions'>
                {result && <div className='modal_result'>{result}</div>}
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleSubmit}>Submit</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleDiscard}>Discard</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function EditLocalInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();

    const [remarks, setRemarks] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
        if (inspection?.remarks?.length) {
            setRemarks(inspection.remarks);
        } else {
            setRemarks([{ id: 1, description: '', picture: null, assetid: '', elementid: '' }]);
        }
    }, [inspection]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const removeLocalButton = (inspection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveLocalInspection state={state} setState={setState} inspection={inspection} />, modaltype: 'small', modaltitle: 'Remove Local Inspection' }));
    }

    const handleDescriptionChange = (event) => {
        const updatedRemarks = [...remarks];
        updatedRemarks[currentPage].description = event.target.value;
        setRemarks(updatedRemarks);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            compressImage(file, (compressedImage) => {
                const updatedRemarks = [...remarks];
                updatedRemarks[currentPage].picture = compressedImage;
                setRemarks(updatedRemarks);
            });
        }
    };

    const handleAssetChange = (selectedAssetId) => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (updatedRemarks[currentPage]) {
                updatedRemarks[currentPage].assetid = selectedAssetId;
            }
            return updatedRemarks;
        });
    };

    const handleElementChange = (selectedElementId) => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (updatedRemarks[currentPage]) {
                updatedRemarks[currentPage].elementid = selectedElementId;
            }
            return updatedRemarks;
        });
    };

    const handleNext = () => {
        setRemarks(prevRemarks => {
            const updatedRemarks = [...prevRemarks];
            if (currentPage >= updatedRemarks.length - 1) {
                updatedRemarks.push({ id: updatedRemarks.length + 1, description: '', assetid: '', elementid: '', picture: null });
            }
            setCurrentPage(currentPage + 1);
            return updatedRemarks;
        });
    };

    const handleBack = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleUpdate = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('projectuserid', project.projectuserid);
        formData.append('projectid', project.projectid);
        formData.append('id', inspection.id);

        const remarksArray = remarks.filter(r => r.description || r.picture).map(r => ({
            id: r.id,
            description: r.description || '',
            picture: r.picture || null,
            assetid: r.assetid,
            elementid: r.elementid
        }));

        formData.append('remarks', JSON.stringify(remarksArray));

        axios.post(configData.CONTROLLERURL + configData.UPDATELOCALINSPECTION, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken,
            },
        }).then((res) => {
            if (res.data?.code === 1) {
                setResult('Inspection updated successfully');
            } else {
                setResult(res.data.data || 'Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    };

    const currentRemark = remarks[currentPage] || { description: '', picture: null };

    return (
        <div className="modal_body">
            <div className="modal_printable">
                <h2>Edit Remark {currentPage + 1}</h2>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <textarea
                            className='modal_textarea'
                            value={currentRemark.description}
                            onChange={handleDescriptionChange}
                            placeholder="Enter your remark here"
                            style={{ display: 'block', marginBottom: '10px', width: '100%' }}
                        ></textarea>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <input
                            className='modal_inputfile'
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'block', marginBottom: '10px' }}
                        />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        {currentRemark.picture && (
                            <img
                                src={currentRemark.picture}
                                alt="remark image"
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        )}
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Asset (Optional)</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={project.assets} itemid={currentRemark.assetid || ''} setItemid={handleAssetChange} />
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <div className='modal_label'>Element (Optional)</div>
                    </div>
                </div>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <Select data={project.structure} itemid={currentRemark.elementid || ''} setItemid={handleElementChange} />
                    </div>
                </div>
            </div>
            <div className='modal_actions'>
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleBack} disabled={currentPage === 0}>Back</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleNext} disabled={currentPage >= remarks.length - 1}>Next</button>
                    </div>
                </div>
            </div>
            <div className='modal_actions'>
                {result && <div className='modal_result'>{result}</div>}
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleUpdate} disabled={loading}>Update</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => removeLocalButton(inspection)}>Remove</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function RemoveLocalInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();
    const [id, setId] = useState('');

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setId(inspection.id)
    }, [inspection]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const removeInspection = () => {
        console.log('Trying to remove inspection');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Inspections',
            'remove',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: id
        }

        axios.post(configData.CONTROLLERURL + configData.REMOVELOCALINSPECTION, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Remove inspection data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'REMOVE_INSPECTION',
                        inspectionid: id
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='modal_body'>
            <div className='modal_actions'>
                {result && <div className='modal_result'>{result}</div>}
                <div className='modal_buttons'>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={() => removeInspection()} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ViewLocalInspection({ state, setState, inspection }) {
    const [{ project }, dispatch] = useStateValue();
    const [id, setId] = useState('');
    const [ts, setTs] = useState('');
    const [remarks, setRemarks] = useState([]);

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const printRef = useRef();

    useEffect(() => {
        setId(inspection.id);
        setTs(inspection.ts);
        setRemarks(inspection.remarks);
    }, [inspection]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const getPicture = (projectuserid, picturefilename) => {
        const filename = picturefilename.split('/').pop();

        const requestData = {
            projectuserid: projectuserid,
            picturefilename: filename
        };

        console.log('Request data:', requestData);

        return axios.post(configData.CONTROLLERURL + configData.GETINSPECTIONPICTURE, requestData, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
            responseType: 'blob'
        }).then(res => {
            console.log('Response data:', res);

            if (res.data) {
                const imageUrl = URL.createObjectURL(res.data);
                return imageUrl;
            } else {
                throw new Error('No image data returned');
            }
        })
            .catch(err => {
                console.error('Error fetching image:', err);
                throw err;
            });
    };

    const saveInspectionsPDF = async () => {
        setLoadingpdf(true);
        try {
            const doc = new jsPDF();
            registerArabicFont(doc);

            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const margin = 10;
            const sectionPadding = 5;
            const columnWidths = [30, 90, 60];
            let currentY = margin;

            doc.text('Local Inspection Report', pageWidth / 2, currentY + 8, { align: 'center' });
            currentY += 20;

            doc.setFillColor(230, 230, 230);
            doc.rect(margin, currentY, pageWidth - 2 * margin, 20, 'F');
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            doc.text(`Project Name: ${project.name}`, margin + 5, currentY + 5);
            doc.text(`Inspection ID: ${id}`, margin + 5, currentY + 12);
            doc.text(`Date: ${moment.unix(ts).format('DD MMMM YYYY')}`, margin + 5, currentY + 19);
            currentY += 25;

            doc.setFillColor(200, 200, 200);
            doc.rect(margin, currentY, pageWidth - 2 * margin, 10, 'F');
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(11);
            doc.text('ID', margin + 5, currentY + 7);
            doc.text('Description', margin + 40, currentY + 7);
            doc.text('Image', margin + 140, currentY + 7);
            currentY += 12;

            for (const remark of remarks) {
                if (currentY + 60 > pageHeight - margin) {
                    doc.addPage();
                    currentY = margin;
                }

                doc.setFontSize(10);
                doc.text(`${remark.id}`, margin + 5, currentY + 5);

                const splitDesc = doc.splitTextToSize(remark.description, columnWidths[1] - 5);
                doc.text(splitDesc, margin + 35, currentY + 5);

                let imageHeight = 40;
                let imageWidth = columnWidths[2] - 5;
                let base64Image;
                try {
                    base64Image = await getPicture(project.projectuserid, remark.picture);
                    const imgProps = doc.getImageProperties(base64Image);
                    const aspectRatio = imgProps.width / imgProps.height;

                    const maxImageWidth = 20;
                    const maxImageHeight = 40;

                    if (aspectRatio > 1) {
                        imageWidth = maxImageWidth;
                        imageHeight = maxImageWidth / aspectRatio;
                    } else {
                        imageHeight = maxImageHeight;
                        imageWidth = maxImageHeight * aspectRatio;
                    }

                    if (currentY + imageHeight > pageHeight - margin) {
                        doc.addPage();
                        currentY = margin;
                    }

                    doc.addImage(base64Image, 'JPEG', margin + 140, currentY, imageWidth, imageHeight);
                } catch (error) {
                    console.log('Image not found:', error);
                    doc.text('No Image', margin + 145, currentY + 5);
                }


                const rowHeight = Math.max(10 + splitDesc.length * 5, imageHeight + 10);
                currentY += rowHeight;
            }

            const fileName = 'localinspection_report_' + project.name.replace(/\s+/g, '_') + '_' + moment().format('YYYY_MM_DD') + '.pdf';
            doc.save(fileName.toLowerCase());
            setResultpdf("Saved Successfully");
        } catch (error) {
            console.error('Error generating PDF:', error);
            setResultpdf("Error Saving PDF");
        } finally {
            setLoadingpdf(false);
        }
    };

    const convertRemarkToTask = (inspectionid, remarkid, status, name, assetid, elementid, setLoading, setResult) => {
        console.log('Trying to add task');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Tasks',
            'add',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        if (!name) {
            setResult('Missing name')
            return
        }

        setLoading(true);

        const trimmedName = name.trim();

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            inspectionid: inspectionid,
            remarkid: remarkid,
            status: status,
            name: trimmedName,
            assetid: assetid,
            elementid: elementid,
        }

        axios.post(configData.CONTROLLERURL + configData.ADDTASK, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Add task data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    data.id = res.data.id;
                    dispatch({
                        type: 'ADD_TASK',
                        task: data
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    }

    function RenderTask({ inspectionid, remarkid, assetid, elementid }) {
        const [newtask, setNewtask] = useState(false);
        const [status, setStatus] = useState("");
        const [name, setName] = useState("");

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        let foundTask = project.tasks.find(task =>
            task.remarkid === remarkid && task.inspectionid === inspectionid
        );

        useEffect(() => {
            if (taskStatus.length > 0) {
                setStatus(taskStatus[0].status);
            }
        }, [taskStatus]);

        if (!foundTask) {
            return (
                <div>
                    <button
                        className='modal_button'
                        onClick={() => setNewtask(true)}
                    >
                        Convert to task
                    </button>
                    {newtask && (
                        <div>
                            <div className="modal_row">
                                <div className="modal_rowsection">
                                    <select
                                        className='modal_select'
                                        value={status}
                                        onChange={e => setStatus(e.target.value)}
                                    >
                                        {
                                            taskStatus.map((col) => (
                                                <option key={col.status} value={col.status}>{col.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal_row">
                                <div className="modal_rowsection">
                                    <input
                                        className='modal_input'
                                        type="text"
                                        placeholder="Task name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='modal_actions'>
                                {result && <div className='modal_result'>{result}</div>}
                                <div className='modal_buttons'>
                                    <div className='modal_buttoncontainer'>
                                        <button
                                            className='modal_button'
                                            onClick={() => convertRemarkToTask(inspectionid, remarkid, status, name, assetid, elementid, setLoading, setResult)}
                                        >
                                            Save Task
                                        </button>
                                    </div>
                                    <div className='modal_buttoncontainer'>
                                        <button
                                            className='modal_button'
                                            onClick={() => setNewtask(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }
        if (foundTask) {
            return (
                <div>{foundTask.status}</div>
            )
        }
    }

    return (
        <div className="modal_body">
            <div className="modal_printable" ref={printRef}>
                <div className="modal_row">
                    <div className="modal_rowsection">
                        <div className="modal_label">ID</div>
                    </div>
                    <div className="modal_rowsection">{id}</div>
                </div>
                <div className="modal_row">
                    <div className="modal_rowsection">
                        <div className="modal_label">Project Name</div>
                    </div>
                    <div className="modal_rowsection">{project.name}</div>
                </div>
                <div className="modal_row">
                    <div className="modal_rowsection">
                        <div className="modal_label">Date</div>
                    </div>
                    <div className="modal_rowsection">{moment.unix(ts).format('DD MMMM YYYY')}</div>
                </div>
                <div className="modal_row">
                    <table className="modal_table">
                        <thead className="tableHeader">
                            <tr>
                                <th className="tableHeaderText">ID</th>
                                <th className="tableHeaderText">Description</th>
                                <th className="tableHeaderText">Asset</th>
                                <th className="tableHeaderText">Element</th>
                                <th className="tableHeaderText">Picture</th>
                                <th className="tableHeaderText">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                remarks.map((remark, index) => {
                                    let assetName = project.assets.find(ass => ass.id == remark.assetid)?.name || '';
                                    let elementName = project.structure.find(el => el.id == remark.elementid)?.name || '';
                                    return (
                                        <tr key={'remark' + index}>
                                            <td>{remark.id}</td>
                                            <td>{remark.description}</td>
                                            <td>{assetName}</td>
                                            <td>{elementName}</td>
                                            <td>
                                                {remark.picture ? (
                                                    <img
                                                        src={remark.picture}
                                                        alt="Remark Image"
                                                        style={{ width: '100px', height: 'auto' }}
                                                    />
                                                ) : (
                                                    'No Image'
                                                )}
                                            </td>
                                            <td>
                                                <RenderTask inspectionid={id} remarkid={remark.id} assetid={remark.assetid} elementid={remark.elementid} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal_actions">
                <div className="modal_buttons">
                    <div className='modal_buttoncontainer'>
                        <ReactToPrint
                            trigger={() => (<button className="modal_button">Print</button>)}
                            content={() => printRef.current}
                        />
                    </div>
                    <div className="modal_buttoncontainer">
                        <button className="modal_button" onClick={saveInspectionsPDF} disabled={loadingpdf}>
                            {loadingpdf ? 'Downloading...' : 'Save as PDF'}
                        </button>
                    </div>
                    <div className='modal_buttoncontainer'>
                        <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}