import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getReadings } from './Utils/energymanagementsystem.js';
import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import Select from './Components/select.js';
import { isValidNumber, hasMoreThanTwoDecimals } from './Utils/textparse.js';
import { assetTypes } from './Utils/asset.js';

import './Readings.css';

function Readings({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const readings = getReadings(
        project.readings,
        project.assets,
        project.clients,
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addReadingButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddReading />, modaltype: 'small', modaltitle: 'Add Reading' }));
    }

    const editButton = (reading) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditReading reading={reading} mode='edit' />, modaltype: 'small', modaltitle: 'Edit Reading' }));
    }

    const viewButton = (reading) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewReading reading={reading} />, modaltype: 'small', modaltitle: 'View Reading' }));
    }

    const cloneButton = (reading) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditReading reading={reading} mode='clone' />, modaltype: 'small', modaltitle: 'Clone Reading' }));
    }

    const openConvertedJournalEntry = (journalentry) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ConvertJournalEntry journalentry={journalentry} />, modaltype: 'large', modaltitle: 'Add Journal Entry' }));
    }

    const removeButton = (reading) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveReading reading={reading} />, modaltype: 'small', modaltitle: 'Remove Reading' }));
    }

    const viewReadings = (readings) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewReadings readings={readings} />, modaltype: 'large', modaltitle: 'Readings' }));
    }

    const viewCalculator = (readings) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Calculator readings={readings} />, modaltype: 'large', modaltitle: 'Calculator' }));
    }

    useEffect(() => {
        if (project.readings.length == 0) {
            console.log('Trying to get readings');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETREADINGS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get readings data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_READINGS',
                        readings: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Readings found in state')
        }
    }, []);

    useEffect(() => {
        if (project.assets.length == 0) {
            console.log('Trying to get assets');

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                archived: project.archived,
            }

            axios.post(configData.CONTROLLERURL + configData.GETASSETS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get assets data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'ADD_ASSETS',
                        assets: res.data
                    });
                }
            }).catch(() => {
            }).finally(() => {
            });
        }
        else {
            console.log('Assets found in state')
        }
    }, []);

    function NewRow({ row, index, handleAssetChange, handleReadingChange, handleRemoveRow }) {
        return (
            <tr>
                <td>
                    <Select data={project.assets} itemid={row.assetid} setItemid={(assetId) => handleAssetChange({ target: { value: assetId } }, index)} />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={row.reading}
                        onChange={(e) => handleReadingChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveRow(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddReading() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');
        const [rows, setRows] = useState([{ assetid: '', reading: 0 }]);

        const [amount, setAmount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddRow = () => {
            setRows([...rows, { assetid: '', reading: 0 }])
        };

        const handleAssetChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].assetid = selectedRow;
            setRows(updatedRows);
        };

        const handleReadingChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].reading = selectedRow;
            setRows(updatedRows);
        };

        const handleRemoveRow = () => {
            if (rows.length > 1) {
                const updatedRows = rows.slice(0, rows.length - 1);
                setRows(updatedRows);
            }
        };

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        const addReading = () => {
            console.log('Trying to add reading');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Readings',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                name: name,
                rows: rows,
                amount: totalAmount,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_READING',
                            reading: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <table className='modal_table'>
                        <thead><tr><th>Asset</th><th>Reading</th><th></th></tr></thead>
                        <tbody>
                            {
                                rows.map((row, index) => (
                                    <NewRow
                                        key={'row' + index}
                                        row={row}
                                        index={index}
                                        handleAssetChange={handleAssetChange}
                                        handleReadingChange={handleReadingChange}
                                        handleRemoveRow={handleRemoveRow}
                                    />
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td>{totalAmount}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='modal_button' onClick={handleAddRow}>Add row</div>
                        </div>
                        <div className='modal_buttoncontainer'>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addReading()} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReading({ reading, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');
        const [rows, setRows] = useState([{ assetid: '', reading: 0 }]);

        const [amount, setAmount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (reading) {
                const readingCopy = {
                    ...reading,
                    rows: Array.isArray(reading.rows) ? reading.rows.map(row => ({ ...row })) : []
                };

                setId(readingCopy.id);
                setSelecteddate(new Date(readingCopy.ts * 1000));
                setName(readingCopy.name)
                setRows(readingCopy.rows);
                setAmount(readingCopy.amount);
            }
        }, [reading]);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddRow = () => {
            setRows([...rows, { assetid: '', reading: 0 }])
        };

        const handleAssetChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].assetid = selectedRow;
            setRows(updatedRows);
        };

        const handleReadingChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].reading = selectedRow;
            setRows(updatedRows);
        };

        const handleRemoveRow = () => {
            if (rows.length > 1) {
                const updatedRows = rows.slice(0, rows.length - 1);
                setRows(updatedRows);
            }
        };

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        const addReading = () => {
            console.log('Trying to add reading');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Readings',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                name: name,
                rows: rows,
                amount: totalAmount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_READING',
                            reading: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const updateReading = () => {
            console.log('Trying to update reading');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Readings',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const areAllReadingsValid = rows.every(row => !isNaN(parseFloat(row.reading)));
            if (!areAllReadingsValid) {
                setResult('Invalid readings. Please enter valid numbers.');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                name: name,
                rows: rows,
                amount: totalAmount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_READING',
                            reading: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead><tr><th>Client</th><th>Reading</th><th></th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleAssetChange={handleAssetChange}
                                            handleReadingChange={handleReadingChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{totalAmount}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='modal_button' onClick={handleAddRow}>Add row</div>
                        </div>
                        <div className='modal_buttoncontainer'></div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            {mode == 'edit' && <button className="modal_button" onClick={() => updateReading()} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                            {mode == 'clone' && <button className="modal_button" onClick={() => addReading()} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>}
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(reading)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function RemoveReading({ reading }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reading.id);
        }, [reading]);

        const removeReading = (id) => {
            console.log('Trying to remove reading');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Readings',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_READING',
                            readingid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeReading(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewReading({ reading }) {
        const [timestamp, setTimestamp] = useState(0);

        const [rows, setRows] = useState([]);

        const [amount, setAmount] = useState(0);

        const printRef = useRef();

        useEffect(() => {
            setTimestamp(reading.ts);
            setRows(reading.rows);
            setAmount(reading.amount);
        }, [reading]);

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(timestamp).format('D MMMM YYYY hh:mm:ss A')}</div>
                    </div>
                    <div className='modal_row'>
                        <table className='modal_table'>
                            <thead><tr><th>Asset</th><th>Reading</th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => {
                                        const assetName = project.assets.find((ass) => ass.id == row.assetid)?.name;
                                        return (
                                            <tr key={'row' + index}>
                                                <td>{assetName}</td>
                                                <td>{row.reading}</td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => cloneButton(reading)}>Clone</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewReadings({ readings }) {
        const [data, setData] = useState([]);
        const [meters, setMeters] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            if (!readings.length) return;

            const timestampsMap = {};
            const assetIds = new Set();

            readings.forEach(({ ts, rows }) => {
                if (!timestampsMap[ts]) {
                    timestampsMap[ts] = { ts };
                }

                rows.forEach(({ assetid, reading }) => {
                    timestampsMap[ts][assetid] = reading || 0;
                    assetIds.add(assetid);
                });
            });

            const structuredData = Object.values(timestampsMap).sort((a, b) => a.ts - b.ts);

            setMeters([...assetIds])
            setData(structuredData);
        }, [readings]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Asset</th>
                                        {
                                            data.map((item, index) => (
                                                <th key={'item' + index}>{moment.unix(item.ts).format('D/MMM/YYYY')}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        meters.map((meter) => (
                                            <tr key={'meter' + meter}>
                                                <td>{project.assets.find(ass => ass.id == meter)?.name}</td>
                                                {
                                                    data.map((item, index) => (
                                                        <td key={'meter-reading' + index}>
                                                            {item[meter] || 0}
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        {
                                            data.map((item, index) => {
                                                const total = meters.reduce((sum, meter) => {
                                                    const reading = parseFloat(item[meter]) || 0;
                                                    return sum + reading;
                                                }, 0).toFixed(2);

                                                return (
                                                    <td key={'total' + index}>
                                                        {total !== 0 ? total : 0}
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function Calculator() {
        const [selectedInvoice, setSelectedInvoice] = useState("");
        const [invoices, setInvoices] = useState([]);
        const [invoicestotal, setTotalInvoices] = useState(0);
        const [tankbeforeamount, setTankbeforeamount] = useState(0);
        const [tankafteramount, setTankafteramount] = useState(0);
        const [tankbeforevalue, setTankbeforevalue] = useState(0);
        const [tankaftervalue, setTankaftervalue] = useState(0);
        const [consumption, setConsumption] = useState(0);

        const [option, setOption] = useState('');
        const [selectedbeforetimestamp, setSelectedbeforetimestamp] = useState('');
        const [selectedaftertimestamp, setSelectedaftertimestamp] = useState('');

        const [data, setData] = useState([]);
        const [meters, setMeters] = useState([]);

        const [totalshares, setTotalshares] = useState(0);
        const [totalbefore, setTotalbefore] = useState(0);
        const [totalafter, setTotalafter] = useState(0);
        const [totalusageinput, setTotalusageinput] = useState(0);
        const [totalusageoutput, setTotalusageoutput] = useState(0);
        const [totalusagetobedistributed, setTotalusagetobedistributed] = useState(0);
        const [totalconsumptioninput, setTotalconsumptioninput] = useState(0);
        const [totalconsumptionoutput, setTotalconsumptionoutput] = useState(0);
        const [totalconsumptiontobedistributed, setTotalconsumptiontobedistributed] = useState(0);
        const [totalconsumptionshared, setTotalconsumptionshared] = useState(0);
        const [total, setTotal] = useState(0);

        const printRef = useRef();

        const currencySymbol = project.currencies.find(curr => curr.id == project.basecurrency)?.symbol

        const purchaseinvoices = project.purchaseinvoices.map(invoice => ({
            id: invoice.id,
            name: invoice.name + ' ' + invoice.amount
        }));

        const addInvoice = () => {
            if (!selectedInvoice) return;

            const invoice = project?.purchaseinvoices.find(inv => inv.id === selectedInvoice);
            if (invoice && !invoices.some(inv => inv.id === invoice.id)) {
                setInvoices([...invoices, invoice]);
            }
        };

        const handleRemoveInvoice = (invoiceId) => {
            setInvoices(invoices.filter(invoice => invoice.id !== invoiceId));
        };

        const convertToJournalEntry = () => {
            let optionname = assetTypes.find(ass => ass.id === 'meter')?.options.find(opt => opt.id === option)?.name || "";
            const fromDate = selectedbeforetimestamp ? moment(parseInt(selectedbeforetimestamp) * 1000).format('DD/MM/YYYY') : '';
            const toDate = selectedaftertimestamp ? moment(parseInt(selectedaftertimestamp) * 1000).format('DD/MM/YYYY') : '';

            const journalEntry = {
                ts: Date.now(),
                subaccount: '',
                currency: project.basecurrency,
                name: optionname + ' from ' + fromDate + ' to ' + toDate,
                rows: [
                    {
                        accounttype: 'incomeaccounts',
                        accountid: '',
                        name: 'Consumption ' + fromDate + ' to ' + toDate,
                        debit: 0,
                        credit: consumption
                    },
                    ...meters.map((meter) => {
                        let clientid = meter?.properties?.clientid;
                        let distribution = meter.total || 0;
                        let meterName = project.assets.find(ass => ass.id == meter.id)?.name;
                        let name = meterName + ' from ' + fromDate + ' to ' + toDate;

                        return {
                            accounttype: 'clients',
                            accountid: clientid,
                            name: name,
                            debit: distribution.toFixed(2),
                            credit: 0
                        };
                    })
                ]
            };
            openConvertedJournalEntry(journalEntry)
        }

        useEffect(() => {
            if (!readings.length) return;

            const timestampsMap = {};

            readings.forEach(({ ts, rows }) => {
                if (!timestampsMap[ts]) {
                    timestampsMap[ts] = { ts };
                }
            });

            const sortedTimestamps = Object.values(timestampsMap).sort((a, b) => a.ts - b.ts);

            if (sortedTimestamps.length > 0) {
                if (sortedTimestamps.length === 1) {
                    setSelectedbeforetimestamp(sortedTimestamps[0].ts);
                    setSelectedaftertimestamp(sortedTimestamps[0].ts);
                }
                else {
                    setSelectedbeforetimestamp(sortedTimestamps[sortedTimestamps.length - 2].ts);
                    setSelectedaftertimestamp(sortedTimestamps[sortedTimestamps.length - 1].ts);
                }
            }

            setData(sortedTimestamps);
            console.log(sortedTimestamps)
        }, [readings]);


        useEffect(() => {

            let foundAssets = [];
            if (option) {
                foundAssets = project.assets.filter(ass => ass.type == 'meter' && ass.option == option)
            }
            else {
                foundAssets = project.assets.filter(ass => ass.type == 'meter')
            }

            let calculated = calculate(consumption, foundAssets, selectedbeforetimestamp, selectedaftertimestamp);
            if (calculated.meters instanceof Array) {
                setMeters(calculated.meters)
            }
        }, [consumption, option, selectedbeforetimestamp, selectedaftertimestamp]);

        function calculate(consumption, assets, selectedbeforetimestamp, selectedaftertimestamp) {
            let beforeReading = project.readings.find(r => r.ts == selectedbeforetimestamp);
            let afterReading = project.readings.find(r => r.ts == selectedaftertimestamp);

            if (!beforeReading || !afterReading) return [];

            let totalshares = 0;
            let totalbefore = 0;
            let totalafter = 0;
            let totalusageinput = 0;
            let totalusageoutput = 0;
            let totalusagetobedistributed = 0;

            let totalconsumptionoutput = 0;
            let totalconsumptiontobedistributed = 0;
            let total = 0;

            let data = assets.map(asset => {
                let assetName = project.assets.find(ass => ass.id == asset.id)?.name || '';
                let clientName = project.clients.find(cl => cl.id == asset?.properties?.clientid)?.name
                let type = asset?.properties?.type;
                let distributetoshares = asset?.properties?.distributetoshares;
                let share = parseFloat(asset?.properties?.share) || 0
                let before = parseFloat(beforeReading.rows.find(row => row.assetid === asset.id)?.reading || 0);
                let after = parseFloat(afterReading.rows.find(row => row.assetid === asset.id)?.reading || 0);
                let usage = after - before;

                totalshares += share;
                totalbefore += before;
                totalafter += after;
                if (type == 'input') totalusageinput += usage
                if (type == 'output') totalusageoutput += usage
                if (distributetoshares) totalusagetobedistributed += usage;

                return {
                    assetid: asset.id,
                    assetname: assetName,
                    clientname: clientName,
                    type: type,
                    distributetoshares: distributetoshares,
                    assetid: asset.id,
                    share: share,
                    before: parseFloat(before),
                    after: parseFloat(after),
                    usage: usage,
                };
            });

            setTotalshares(totalshares);
            setTotalbefore(totalbefore);
            setTotalafter(totalafter);
            setTotalusageinput(totalusageinput);
            setTotalusageoutput(totalusageoutput);
            setTotalusagetobedistributed(totalusagetobedistributed);
            totalconsumptiontobedistributed = consumption * totalusagetobedistributed / totalusageoutput;
            setTotalconsumptiontobedistributed(totalconsumptiontobedistributed)

            let totalconsumptiondistributed = 0;
            let totalconsumptionshared = 0;
            data = data.map(asset => {
                let share = asset.share
                let usage = asset.after - asset.before;

                let consumptionshared = 0;
                let consumptiondistributed = 0;
                let totalconsumption = 0;
                if (asset.type == 'output' && !asset.distributetoshares) {
                    consumptiondistributed = consumption * (usage / totalusageoutput);
                    consumptionshared = totalconsumptiontobedistributed * (share / totalshares);
                    totalconsumption = consumptiondistributed + consumptionshared
                }
                if (asset.type == 'output' && asset.distributetoshares) {
                    consumptiondistributed = consumption * (usage / totalusageoutput);
                    consumptionshared = 0;
                    totalconsumption = consumptiondistributed + consumptionshared
                }

                totalconsumptiondistributed += consumptiondistributed;
                totalconsumptionshared += consumptionshared;
                return {
                    ...asset,
                    consumptiondistributed: consumptiondistributed,
                    consumptionshared: consumptionshared,
                    total: totalconsumption
                };
            });

            setTotalconsumptioninput(totalconsumptioninput)
            setTotalconsumptionoutput(totalconsumptionoutput)
            setTotalconsumptionshared(totalconsumptionshared)
            total = totalconsumptiondistributed + totalconsumptionshared;
            setTotal(total)

            return {
                meters: data,
                totalshares: totalshares,
                totalbefore: totalbefore,
                totalafter: totalafter,
                totalusageinput: totalusageinput,
                totalusageoutput: totalusageoutput,
                totalusagetobedistributed: totalusagetobedistributed,
                totalconsumptionoutput: 0,
                totalconsumptiontobedistributed: totalconsumptiontobedistributed,
                total: total
            };
        }

        const handleUsageChange = (assetId, newUsage) => {
            console.log(assetId)
            setMeters(prevMeters =>
                prevMeters.map(meter =>
                    meter.assetid === assetId
                        ? { ...meter, usage: parseFloat(newUsage) || 0 }
                        : meter
                )
            );
        }

        return (
            <div className="modal_body">
                <div className="modal_printable" ref={printRef}>
                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <div className='modal_label'>Consumption / INPUT</div>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <Select data={purchaseinvoices} itemid={selectedInvoice} setItemid={setSelectedInvoice} />
                        </div>
                        <div className="modal_rowsection">
                            <button
                                className='modal_button'
                                onClick={addInvoice}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoices.map(invoice => (
                                            <tr key={invoice.id}>
                                                <td>{invoice.id}</td>
                                                <td>{moment.unix(invoice.ts).format('DD MMMM YYYY')}</td>
                                                <td>{invoice.name}</td>
                                                <td>{project.currencies.find(curr => curr.id == invoice.currencyid)?.symbol} {invoice.amount}</td>
                                                <div className='modal_removeitembutton' onClick={() => handleRemoveInvoice(invoice.id)}>&minus;</div>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_rowsection">Tank Before</div>
                        <div className="modal_rowsection">
                            <input
                                className="modal_input"
                                type="number"
                                value={tankbeforeamount}
                                onChange={(e) => setTankbeforeamount(Number(e.target.value))}
                            />
                        </div>
                        <div className="modal_rowsection">
                            <input
                                className="modal_input"
                                type="number"
                                value={tankbeforevalue}
                                onChange={(e) => setTankbeforevalue(Number(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_rowsection">Tank After</div>
                        <div className="modal_rowsection">
                            <input
                                className="modal_input"
                                type="number"
                                value={tankafteramount}
                                onChange={(e) => setTankafteramount(Number(e.target.value))}
                            />
                        </div>
                        <div className="modal_rowsection">
                            <input
                                className="modal_input"
                                type="number"
                                value={tankaftervalue}
                                onChange={(e) => setTankaftervalue(Number(e.target.value))}
                            />
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_rowsection">Total Consumption</div>
                        <div className="modal_rowsection">
                            <input
                                className='modal_input'
                                type="number"
                                value={consumption}
                                onChange={(e) => setConsumption(e.target.value)}
                            />
                        </div>
                        <div className="modal_rowsection">Consumption: {currencySymbol} {consumption} Invoices: {invoicestotal} Total cost of 1 usage: </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <div className='modal_label'>Distribution / OUTPUT</div>
                        </div>
                    </div>
                    <div className="modal_row">
                        <div className="modal_rowsection">Meter Option</div>
                        <div className="modal_rowsection">
                            <select className="modal_select" value={option} onChange={(e) => setOption(e.target.value)}>
                                {
                                    assetTypes
                                        .find(ass => ass.id === 'meter')?.options.map((opt, index) => (
                                            <option key={'option' + index} value={opt.id}>
                                                {opt.name}
                                            </option>
                                        )) || null
                                }
                            </select>
                        </div>
                    </div>
                    <div className="modal_row">
                        <div className="modal_rowsection">
                            <table className="modal_table">
                                <thead>
                                    <tr>
                                        <th>Meters</th>
                                        <th>Client</th>
                                        <th>Type</th>
                                        <th>Shares</th>
                                        <th>
                                            <select
                                                className='modal_select'
                                                value={selectedbeforetimestamp}
                                                onChange={(e) => setSelectedbeforetimestamp(e.target.value)}
                                            >
                                                {
                                                    data.map((item, index) => (
                                                        <option key={'item' + index} value={item.ts}>{moment.unix(item.ts).format('D/MMM/YYYY')}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                        <th>
                                            <select
                                                className='modal_select'
                                                value={selectedaftertimestamp}
                                                onChange={(e) => setSelectedaftertimestamp(e.target.value)}
                                            >
                                                {
                                                    data.map((item, index) => (
                                                        <option key={'item' + index} value={item.ts}>{moment.unix(item.ts).format('D/MMM/YYYY')}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                        <th>Usage {((selectedaftertimestamp - selectedbeforetimestamp) / (60 * 60 * 24)).toFixed(0) + ' days'}</th>
                                        <th>Consumption Distributed</th>
                                        <th>Consumption Shared</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        meters.map((meter, index) => {
                                            return (
                                                <tr key={'meter' + index}>
                                                    <td>{meter.assetname}</td>
                                                    <td>{meter.clientname}</td>
                                                    <td>{meter.type}</td>
                                                    <td>{meter.share}</td>
                                                    <td>{meter.before}</td>
                                                    <td>{meter.after}</td>
                                                    <td>
                                                        {
                                                            meter.distributetoshares ?
                                                                <input
                                                                    className='modal_input'
                                                                    type="text"
                                                                    value={meter.usage}
                                                                    onChange={(e) => handleUsageChange((meter.assetid, e.target.value))}
                                                                />
                                                                :
                                                                meter.usage
                                                        }
                                                    </td>
                                                    <td>
                                                        {meter.consumptiondistributed}
                                                    </td>
                                                    <td>{meter.consumptionshared}</td>
                                                    <td>{meter.total}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>Total</td>
                                        <td>{totalshares}</td>
                                        <td>{totalbefore}</td>
                                        <td>{totalafter}</td>
                                        <td>
                                            <div>Input: {totalusageinput}</div>
                                            <div>Output: {totalusageoutput}</div>
                                            <div>To be distributed: {totalusagetobedistributed}</div>
                                        </td>
                                        <td>
                                            <div>{totalconsumptionoutput}</div>
                                            <div>To be distributed: {totalconsumptiontobedistributed}</div>
                                        </td>
                                        <td>{totalconsumptionshared}</td>
                                        <td>{total}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={convertToJournalEntry}>Convert to Journal Entry</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    function ConvertJournalEntry({ journalentry }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [name, setName] = useState('');
        const [rows, setRows] = useState([{ accounttype: 'incomeaccounts', accountid: '', name: '', debit: 0, credit: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            if (journalentry && Array.isArray(journalentry.rows)) {
                const journalCopy = {
                    ...journalentry,
                    rows: journalentry.rows.map(row => ({ ...row }))
                };
                setId(journalCopy.id);
                setSelecteddate(new Date(journalCopy.ts * 1000));
                setTimstamp(journalCopy.ts);
                setCurrencyid(journalCopy.currencyid);
                setSelectedSubaccount(journalCopy.subaccountid);
                setName(journalCopy.name);
                setRows(journalCopy.rows);
            }
        }, [journalentry]);

        const handleTypeChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accounttype = selectedRow;
            setRows(updatedRows);
        };

        const handleAccountChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accountid = selectedRow;
            setRows(updatedRows);
        };

        const handleNameChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].name = selectedRow;
            setRows(updatedRows);
        };

        const handleDebitChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].debit = selectedRow;
            setRows(updatedRows);
        };

        const handleCreditChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].credit = selectedRow;
            setRows(updatedRows);
        };

        const handleAddRow = () => {
            const lastRowAccountType = rows.length > 0 ? rows[rows.length - 1].accounttype : 'incomeaccounts';
            setRows([...rows, { accounttype: lastRowAccountType, accountid: '', name: '', debit: 0, credit: 0 }])
        };

        const handleRemoveRow = (indexToRemove) => {
            if (rows.length > 1) {
                const updatedRows = rows.filter((_, index) => index !== indexToRemove);
                setRows(updatedRows);
            }
        };

        let sumDebit = rows.reduce((sum, item) => sum + (Number(item.debit) || 0), 0);
        let sumCredit = rows.reduce((sum, item) => sum + (Number(item.credit) || 0), 0);
        if (hasMoreThanTwoDecimals(sumDebit)) {
            sumDebit = parseFloat(sumDebit.toFixed(2));
        }

        if (hasMoreThanTwoDecimals(sumCredit)) {
            sumCredit = parseFloat(sumCredit.toFixed(2));
        }
        const totalDifference = (sumDebit - sumCredit).toFixed(2);
        const currencySymbol = project.currencies.find(item => item.id === currencyid)?.symbol;

        const addJournal = (timestamp, subaccountid, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Journal Entries',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!subaccountid || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                subaccountid: subaccountid,
                currencyid: currencyid,
                amount: sumdebit,
                name: trimmedname,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.ADDJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_JOURNALENTRY',
                            journalentry: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        function JournalEntryRow({ row, index, handleTypeChange, handleAccountChange, handleNameChange, handleDebitChange, handleCreditChange, handleRemoveRow }) {
            const renderAccountOptions = () => {
                switch (row.accounttype) {
                    case 'incomeaccounts':
                        return <Select data={project.incomeaccounts} itemid={row.accountid} setItemid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} />;
                    case 'expenseaccounts':
                        return <Select data={project.expenseaccounts} itemid={row.accountid} setItemid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} />;
                    case 'clients':
                        return <Select data={project.clients} itemid={row.accountid} setItemid={(clientId) => handleAccountChange({ target: { value: clientId } }, index)} />;
                    case 'suppliers':
                        return <Select data={project.suppliers} itemid={row.accountid} setItemid={(supplierId) => handleAccountChange({ target: { value: supplierId } }, index)} />;
                    default:
                        return null;
                }
            };

            return (
                <tr>
                    <td>
                        <select
                            className='modal_select'
                            value={row.accounttype}
                            onChange={(e) => handleTypeChange(e, index)}
                        >
                            <option value="incomeaccounts">Income Accounts</option>
                            <option value="expenseaccounts">Expense Accounts</option>
                            <option value="clients">Accounts Receivable</option>
                            <option value="suppliers">Accounts Payable</option>
                        </select>
                    </td>
                    <td>
                        {renderAccountOptions()}
                    </td>
                    <td>
                        <input
                            className='modal_input'
                            type="text"
                            value={row.name}
                            onChange={(e) => handleNameChange(e, index)}
                        />
                    </td>
                    <td>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder={0}
                            value={row.debit}
                            onChange={(e) => handleDebitChange(e, index)}
                        />
                    </td>
                    <td>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder={0}
                            value={row.credit}
                            onChange={(e) => handleCreditChange(e, index)}
                        />
                    </td>
                    <td>
                        <div className='modal_removeitembutton' onClick={() => handleRemoveRow(index)}>&minus;</div>
                    </td>
                </tr>
            );
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead><tr><th>Type</th><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th><th></th></tr></thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <JournalEntryRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleTypeChange={handleTypeChange}
                                            handleAccountChange={handleAccountChange}
                                            handleNameChange={handleNameChange}
                                            handleDebitChange={handleDebitChange}
                                            handleCreditChange={handleCreditChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{totalDifference != 0 && totalDifference}</div></td>
                                        <td><div>{currencySymbol} {sumDebit.toLocaleString('en-US')}</div></td>
                                        <td><div>{currencySymbol} {sumCredit.toLocaleString('en-US')}</div></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className='modal_button' onClick={handleAddRow}>Add row</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addJournal(timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(journalentry)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='readings'>
            <Title text='Readings' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addReadingButton}>Add Reading</button>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewReadings(project.readings)}>Readings</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewCalculator(project.readings)}>Calculator</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        readings.map((read, index) => {
                            return (
                                <tr key={'read' + index}>
                                    <td><div className='table_button' onClick={() => editButton(read)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(read)}>View</div></td>
                                    <td><div>{moment.unix(read.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                    <td><div>{read.name}</div></td>
                                    <td><div>{read.amount}</div></td>
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Readings;